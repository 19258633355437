import { yupResolver } from "@hookform/resolvers/yup";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Collapse, Grid, IconButton, Paper, Stack, Switch, Typography } from "@mui/material";
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { cloneDeep, isEmpty, isNil, isObject, isString, some } from "lodash";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { pontLifeCycleStages } from "../../data/customer/pontLifeCycleStages";
import { pontRelationshipTypes } from "../../data/customer/pontRelationshipTypes";
import { getFormatterLanguageOptions } from "../../data/en/pontLanguages";
import { useContactGetById, useContactSetById } from "../../hooks/Member/contact";
import i18n from "../../i18n";
import { colors } from "../../main/Theme";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { ContactGetByIdResponseDto, ContactSetByIdRequestDto } from "../../types/Member/ContactDetailDto";
import AddressFormV2 from "../Common/Address/AddressFormV2";
import CustomCountryDropDown from "../Common/formComponents/CustomCountryDropDown";
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { Input } from "../Common/formComponents/Input";
import { Label } from "../Common/formComponents/Label";
import { IconDisplayer } from "../Common/Help/IconDisplayer";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import { Loader } from "../Common/UI/Loader";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";
import { TelephoneInput } from "../Common/formComponents/TelephoneInput";
import { cleanValue } from "../../functions/utils/helper";
// https://localhost:5002/companies/52bc31ca-2368-48ed-9b53-191f4467aab3/contacts/43d36eba-8762-4b3c-bdd7-0dcadcf55ca6

type Args =
    {
        userLogged: ApplicationUser
        isCurrentUserEdit?: boolean
    }

export const EditContactV2 = ({ userLogged, isCurrentUserEdit }: Args) => {
    const queryStringParams = useParams();
    const contactId = queryStringParams.id;
    const companyId = queryStringParams.parentId;
    const [snackbarContent, setSnackbarContent] = useState<any>()

    if (!contactId) throw Error("Contact id needed");
    if (!companyId) throw Error("Company id needed");

    const nav = useNavigate();

    const [addressCollapseStatus, setAddressCollapseStatus] = useState(false);

    const addressSchema = yup.object().shape({
        id: yup.string().nullable(),
        entityName: yup.string().nullable(),
        entityKey: yup.string().nullable(),
        languageCode: yup.string().nullable(),
        addressTypeName: yup.string().nullable(),
        line1: yup.string().nullable()
            .when(['id', 'line2', 'line3', 'area', 'zip', 'latitude', 'longitude', 'whatThreeWords'], {
                is: (id: any, line2: any, line3: any, area: any, zip: any, latitude: any, longitude: any, whatThreeWords: any) => !!id || !!line2 || !!line3 || !!area || !!zip || !!latitude || !!longitude || !!whatThreeWords,
                then: schema => schema.required(i18n.t('VALIDATION_ADDRESS_LINE1_REQUIRED').toString()),
                otherwise: schema => schema.nullable(),
            }),
        line2: yup.string().nullable(),
        line3: yup.string().nullable(),
        city: yup.string().nullable()
            .when(['id', 'line1', 'line2', 'line3', 'area', 'zip', 'latitude', 'longitude', 'whatThreeWords'], {
                is: (id: any, line1: any, line2: any, line3: any, area: any, zip: any, latitude: any, longitude: any, whatThreeWords: any) => !!id || !!line1 || !!line2 || !!line3 || !!area || !!zip || !!latitude || !!longitude || !!whatThreeWords,
                then: schema => schema.required(i18n.t('VALIDATION_ADDRESS_CITY_REQUIRED').toString()),
                otherwise: schema => schema.nullable(),
            }),
        area: yup.string().nullable(),
        countryIso3Code: yup.string().nullable(),
        zip: yup.string().nullable(),
        latitude: yup.string().nullable(),
        longitude: yup.string().nullable(),
        whatThreeWords: yup.string().nullable(),
        externalReference: yup.string().nullable(),
        timeZoneId: yup.string().nullable(),
        description: yup.string().nullable(),
        isDefault: yup.boolean(),
        addressTypeLabel: yup.string().nullable(),
    }).nullable().notRequired();



    const schema = yup.object().shape({
        companyId: yup.string().required(),
        id: yup.string().required(),
        firstName: yup.string().required(i18n.t('VALIDATION_FIRSTNAME_REQUIRED').toString()),
        lastName: yup.string().required(i18n.t('VALIDATION_LASTNAME_REQUIRED').toString()),
        email: yup.string().required(),
        companyName: yup.string().required(i18n.t('VALIDATION_COMPANYNAME_REQUIRED').toString()),
        jobTitle: yup.string().nullable(),
        telephone: yup.string().test('is-valid-phone', i18n.t('COMMON_ERROR_PHONEFORMATTING').toString(), (value) => {
            if (!value) return true;
            return isValidPhoneNumber(value)
        }).nullable(),
        lifeCycleStageName: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable(),
        relationshipTypeFlags: yup.number().required(),
        groupName: yup.string().nullable(),
        externalIdentifier: yup.string().nullable(),
        countryIso3Code: yup.string().required(),
        address: addressSchema,
        languageCode: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).required(),
        isBlocked: yup.boolean().required(),
    });

    type FormType = yup.InferType<typeof schema>;
    type AddressType = yup.InferType<typeof addressSchema>;

    const { control, reset, watch, setValue, handleSubmit, formState: { errors } } = useForm<FormType>({
        resolver: yupResolver(schema),
        mode: "onBlur"
    });

    const relationshipType = watch('relationshipTypeFlags');

    const isInRelation = (value: number): boolean => {
        return (relationshipType & value) === value;
    }

    const formattedLanguageOptions = getFormatterLanguageOptions()

    const findSelectedLanguage = (languageCode: string) => {
        return formattedLanguageOptions.find(item => item.value.toLowerCase() === languageCode.toLowerCase())
    }

    const formattedRelationshipTypes = pontRelationshipTypes.map((item) => ({
        value: item.flag,
        label: i18n.t(item.label).toString(),
    }));

    const formattedLifeCycleStages = pontLifeCycleStages.map((item) => ({
        label: i18n.t(item.label).toString(),
        value: item.value,
    }));

    const findSelectedLifeCycleStage = (lifeCycleStageName: string | null | undefined) => {
        if (!lifeCycleStageName) return undefined;
        return formattedLifeCycleStages.find(item => item.value === lifeCycleStageName)
    }

    const { isLoading: gettingContact, data: contactData } = useContactGetById({
        params: {
            companyId,
            contactId
        },
        options: {
            onSuccess: (data: ContactGetByIdResponseDto) => {
                const formatAddress = () => {
                    const _address = cloneDeep(data.address);
                    if (!_address) {
                        return undefined
                    }
                    _address.addressTypeName = "Business"
                    _address.countryIso3Code = data.countryIso3Code;
                    return _address
                }
                reset({
                    companyId: data.companyId,
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email!,
                    companyName: data.companyName!,
                    jobTitle: data.jobTitle,
                    telephone: data.telephone,
                    lifeCycleStageName: findSelectedLifeCycleStage(data.lifeCycleStageName),
                    relationshipTypeFlags: data.relationshipTypeFlags ? data.relationshipTypeFlags : 0,
                    groupName: data.groupName,
                    externalIdentifier: data.externalIdentifier,
                    countryIso3Code: data.countryIso3Code,
                    address: formatAddress() as AddressType,
                    languageCode: findSelectedLanguage(data.languageCode ? data.languageCode : i18n.language),
                    isBlocked: data.isBlocked
                })
            },
            onError: (data) => {
                setSnackbarContent(data.response?.data)
            }
        }
    });

    const { mutate: updateContact, isLoading: updatingContact } = useContactSetById({
        params: {
            companyId,
            contactId
        }
    })

    const handleRelationshipTypeChange = (isChecked: boolean, changeValue: number) => {
        const adjustment = isChecked ? changeValue : -changeValue;
        const newValue = relationshipType + adjustment;
        const clampedValue = Math.max(0, newValue);
        setValue('relationshipTypeFlags', clampedValue);
    };

    const hasTruthyValue = (obj: any) => {
        return some(obj, (value) => {
            return value || value === true || value === false || value === 0;
        });
    }

    

    const onSubmit: SubmitHandler<FormType> = (data) => {
        const clonedData = cloneDeep(data)
        const hasAddress = hasTruthyValue(clonedData.address);

        let _address = clonedData.address as any

        if (hasAddress && _address) {
            _address.addressTypeName = "Business"
            _address.countryIso3Code = clonedData.countryIso3Code;
            if (_address !== null) {
                Object.entries(_address).forEach(([key, value]) => {
                    _address[key] = cleanValue(value);
                });
            }
        } else {
            _address = null
        }


        const _languageCode = clonedData.languageCode.value!
        const _telephone = clonedData?.telephone?.replace(/\s+/g, "")

        const dataToSubmit: ContactSetByIdRequestDto = {
            companyId: cleanValue(clonedData.companyId),
            id: cleanValue(clonedData.id),
            firstName: cleanValue(clonedData.firstName),
            lastName: cleanValue(clonedData.lastName),
            email: cleanValue(clonedData.email),
            companyName: cleanValue(clonedData.companyName),
            jobTitle: cleanValue(clonedData.jobTitle),
            telephone: cleanValue(_telephone),
            lifeCycleStageName: cleanValue(clonedData.lifeCycleStageName?.value),
            countryIso3Code: cleanValue(clonedData.countryIso3Code),
            address: cleanValue(_address),
            languageCode: cleanValue(_languageCode),
            isBlocked: cleanValue(clonedData.isBlocked),
            relationshipTypeFlags: cleanValue(clonedData.relationshipTypeFlags),
            additionalAddresses: cleanValue(contactData?.additionalAddresses),
            groupName: cleanValue(clonedData.groupName),
            externalIdentifier: cleanValue(clonedData.externalIdentifier)
        }

        updateContact(dataToSubmit, {
            onSuccess: () => {
                handleBackLink()
            },
            onError: (data) => {
                setSnackbarContent(data.response?.data)
            }
        });
    }

    const handleBackLink = () => {
        nav(`/companies/${companyId}/contacts/${contactId}/summary`);
    }

    const Header = () => (
        <SectionHead linkCallBack={handleBackLink}
            ctaText={i18n.t('COMMON_SUMMARY_CONTACT-MANAGEMENT')!}
            name={`${contactData?.firstName} (${contactData?.lastName})`}
            title={i18n.t('MEMBER_TITLE_EDITCOMPANYCONTACT')}
            description={i18n.t('COMMON_SUMMARY_COMPANYCONTACTMANAGEMENT')} />
    )

    useEffect(() => {
        if (errors.address) {
            setAddressCollapseStatus(true);
        }
    }, [errors.address])

    if (gettingContact) {
        return (
            <>
                <Header />
                <Loader />
            </>
        )
    }

    return (
        <>
            <Header />
            <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent} severity="error" />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="companyName"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                             message: i18n.t("MEMBER_LABEL_COMPANYNAME").toString(),
                                            }}
                                            placeholder={i18n.t("MEMBER_LABEL_COMPANYNAME").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                            disabled
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_FIRSTNAME").toString(),
                                                mandatory: true
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_LASTNAME").toString(),
                                                mandatory: true
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_EMAIL").toString(),
                                            }}
                                            placeholder={i18n.t("COMMON_PLACEHOLDER_EMAIL").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                            disabled
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <Controller
                                    control={control}
                                    name="telephone"
                                    render={({ field, fieldState }) => (
                                        <TelephoneInput
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_PHONE_SINGULAR").toString(),
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_PHONE_SINGULAR").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></TelephoneInput>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="jobTitle"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMMON_LABEL_JOBTITLE").toString(),
                                            }}
                                            placeholder={i18n.t("COMMON_LABEL_JOBTITLE").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name="languageCode"
                                    render={({ field, fieldState }) => {
                                        return (
                                            <CustomSelect
                                                labelProps={{ message: 'COMMON_LABEL_LANGUAGE', mandatory: true }}
                                                options={formattedLanguageOptions}
                                                placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                }}
                                                validationError={fieldState.error}
                                            />
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="lifeCycleStageName"
                                    render={({ field, fieldState }) => (
                                        <CustomSelect
                                            labelProps={{ message: 'LIFE_CYCLE_STAGE' }}
                                            options={formattedLifeCycleStages}
                                            placeholder={i18n.t('LIFE_CYCLE_STAGE')}
                                            field={field}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="countryIso3Code"
                                    render={({ field, fieldState }) => (
                                        <CustomCountryDropDown
                                            labelProps={{
                                                message: 'COMMON_LABEL_COUNTRY',
                                                mandatory: true
                                            }}
                                            valueChanged={(value) => { field.onChange(value) }}
                                            inputCountry={field.value}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={3} sx={{ bgcolor: colors.grey[600], p: 0.5, mt: 2 }}>
                                    <div className="d-flex align-items-center">
                                        <div className="fa-name flex-grow-1">
                                            <IconDisplayer type='COMMON_TITLE_ADDRESS'></IconDisplayer>
                                            <Typography variant="h6" >
                                                <LR localResource='COMMON_TITLE_ADDRESS'></LR>
                                            </Typography>
                                        </div>
                                        <IconButton className="fa-arrow"
                                            onClick={() => setAddressCollapseStatus(!addressCollapseStatus)}
                                        >
                                            {!addressCollapseStatus && <ArrowForwardIosRoundedIcon />}
                                            {addressCollapseStatus && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                                        </IconButton>
                                    </div>
                                    <Collapse in={addressCollapseStatus} sx={{ px: 0.5 }}>
                                        <Box sx={{ backgroundColor: colors.grey[500], padding: 2, mt: 1, borderRadius: 1 }}>
                                            <AddressFormV2 control={control} formKey="address" />
                                        </Box>
                                    </Collapse>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Label mandatory={false} message='COMMON_LABEL_RELATIONSHIPTOCOMPANY'></Label>
                                <Stack gap={1} mt={1}>
                                    {formattedRelationshipTypes.map((item, index) => (
                                        <Box
                                            component={'label'}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                backgroundColor: colors.grey[100],
                                                padding: '0.3rem 0.2rem 0.3rem 1rem',
                                            }}
                                            key={index}
                                        >
                                            <span>{item.label}</span>
                                            <Switch
                                                color="secondary"
                                                checked={isInRelation(item.value)}
                                                onChange={(e) => handleRelationshipTypeChange(e.target.checked, item.value)}
                                            />
                                        </Box>
                                    ))}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="externalIdentifier"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMPANY_INTERNAL_IDENTIFIER").toString(),
                                            }}
                                            placeholder={i18n.t("COMPANY_INTERNAL_IDENTIFIER").toString()}
                                            {...field}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="groupName"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            labelProps={{
                                                message: i18n.t("COMPANY_INTERNAL_GROUP_NAME").toString(),
                                            }}
                                            placeholder={i18n.t("COMPANY_INTERNAL_GROUP_NAME").toString()}
                                            {...field}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Label message='COMMON_LABEL_BLOCKED'></Label>
                                <Box sx={{ backgroundColor: colors.grey[100], padding: '3px' }}>
                                    <Controller
                                        name="isBlocked"
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                color="secondary"
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                                <CustomButton variant="contained" fullWidth type="submit" loading={updatingContact}>
                                    {i18n.t('COMMON_LABEL_SAVE')}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};