import { useState } from "react";
import { useCheckEmail } from "../../hooks/Login/login";
import Header from "../Navigation/HeaderLogin";
import './Login.css'
import google from './../../images/providers/google.svg'
import ExternalProvider from "./ExternalProvider";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import {isMobile} from 'react-device-detect';
import { LoginLinks } from "./subsections/LoginLinks";

function Login() {
    const checkEmailMutation = useCheckEmail();
    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [formError, setFormError] = useState('');

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            setFormError('');
            checkEmailMutation.mutate(email);

        } catch (err: any) {
            console.error(err);
            setSubmitting(false)
            setFormError(err.toString())
        } 
    }
    
    return (
    <div className='container'>
        <div className="row d-flex justify-content-center">                        
            <div className={isMobile ? "col-12 justify-content-center logincontainer" : "col-4 justify-content-center center-block border border-white logincontainer"}>
            <Header />
            <form onSubmit={onFormSubmit}>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <input
                            type="email"
                            className="form-control fa-form"
                            placeholder={i18n.t("COMMON_LABEL_EMAIL").toString()}
                            value={email}
                            onChange={e => setEmail(e.currentTarget.value)} required
                        ></input>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" type="submit" disabled={submitting}>
                        <LR localResource="LOGIN_BUTTON_CONTINUE"/>    
                        </button>
                    </div>
                </div>


                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-10 d-flex justify-content-center"> 
                    <LR localResource="COMMON_LABEL_OR"/>    
                    </div>
                </div>

                {/* <ExternalProvider image={google} name="Google" link="/bff/login" ></ExternalProvider> */}
                {/* <ExternalProvider image={linkedin} name="LinkedIn" link="/bff/login" ></ExternalProvider> */}
                {Boolean(formError) &&
                    <div className="row d-flex justify-content-center mb-2">
                        <div className="col-10 d-flex justify-content-center">
                            <span className="form-error">{formError}</span>
                        </div>
                    </div>
                }
                <LoginLinks></LoginLinks>
            </form>
        </div>
        </div>
        </div>
    );
}

export default Login;
