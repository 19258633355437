import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Paper } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import { RetentionData } from '../../../types/Report/RetentionData';
import { transformRetentionData } from '../../../hooks/Report/DataTransformation';
import { useGetReports } from '../../../hooks/Report/ReportHook';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';


interface RetentionTrendsChartProps {
    userLogged: ApplicationUser,
    title?: string;
    width?: string | number;
    height?: string | number;
}

export const RetentionTrendsChart: React.FC<RetentionTrendsChartProps> = ({
    userLogged,
    title = 'Contract Retention Trends',
    width = '100%',
    height = 400,
}) => {





    const { getReports, data, isLoading, error } = useGetReports();
    const [chartData, setChartData] = useState<RetentionData | null>(null);

    useEffect(() => {
        const fetchMyReport = async () => {
            await getReports({
                operatorMemberIds: [userLogged.location?.operatorMemberId!],
                locationIds: [userLogged.location?.id!],
                metricGroupName: "Retention",
                productTypeName: "Office",
                fromDate: '2024-07-25T16:05:50.095Z',
                toDate: '2025-06-25T16:05:50.095Z'
            });
        };

        fetchMyReport();
    }, []);


    useEffect(() => {
        if (data) {
            const transformedData = transformRetentionData(data);
            setChartData(transformedData);
        }
    }, [data]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!chartData) return <div>No data available</div>;

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            
          },
          title: {
            display: true,
            text: 'Contract Flow Analysis',
            color: 'white'
          },
        },
        scales: {
          x: {
            
          },
          y: {
            stacked: true,
            beginAtZero: true,
           
          }
        },
        elements: {
          line: {
            tension: 0.4,
            fill: true
          }
        }
      };
    
      const flowChartData = {
        labels: chartData.periods,
        datasets: [
          {
            label: 'New Agreements',
            data: calculateActiveContracts(chartData), // Cumulative active contracts
            backgroundColor: 'rgba(75, 192, 148, 0.3)',
            borderColor: 'rgb(75, 192, 148)',
            fill: true
          },
          {
            label: 'Ending Agreements',
            data: chartData.ending,
            backgroundColor: 'rgba(255, 99, 132, 0.3)',
            borderColor: 'rgb(255, 99, 132)',
            fill: true
          }
        ]
      };
    
      return (
        <Paper sx={{ p: 2, bgcolor: '#1e1e1e' }}className='chart-paper'>
        <h4>{title}</h4>
          <Box sx={{ height: 400 }}>
            <Line options={options} data={flowChartData} />
          </Box>
        </Paper>
      );
    };
    
    // Helper function to calculate cumulative active contracts
    const calculateActiveContracts = (data: RetentionData) => {
      let active = 0;
      return data.periods.map((_, index) => {
        active += data.starting[index] - data.ending[index];
        return active;
      });
    };