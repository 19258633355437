import { LR } from '../Help/LR';

export enum AlertStyle {
    Default,
    Tiny
}

type Args = {
    message: string
    variant?: string
    timeout?: number
    additionalClassName?: string,
    params?: any
    style?: AlertStyle
};

const MessageAlert = ({ message, variant, timeout, additionalClassName, params = undefined, style = AlertStyle.Default }: Args) => {
    if (!Boolean(message)) return null;

    const alertClassName = `alert alert-${variant} ${additionalClassName}`;

    if (style === AlertStyle.Tiny) {
        return (
            <div className={alertClassName} role="alert">
                <LR localResource={message} params={params}></LR>
            </div>
        )
    }

    return (
        <div className="row d-flex justify-content-center mb-2">
            <div className="col-10 d-flex justify-content-center small">
                <div className={alertClassName} role="alert">
                    <LR localResource={message} params={params}></LR>
                </div>
            </div>
        </div>
    )
}

MessageAlert.defaultPros = {
    variant: 'info',
    timeout: 3000,
    additionalClassName: ''
}

export default MessageAlert;