import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { SellableItemAvailabilityRequest } from "../../types/Order/SellableItemAvailabilityRequest";
import { SellableItemAvailability } from "../../types/Order/SellableItemAvailabilityResponse";


export const useGetSellableItemAvailability = () => {
  return useMutation<SellableItemAvailability[], AxiosError, SellableItemAvailabilityRequest>(
    (request) => axios.post(`${HookConfig.locationUrl}/${request.locationId}/sellable-items/${request.sellableItemId}/availability`, request,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
};