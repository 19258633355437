import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

type OrderMemberPayload = {
  basketId: string;
  memberId: string;
  comment: string;
  fullName: string;
};

export const useAddOrderMember = () => {
  return useMutation<{ id: string }, AxiosError, OrderMemberPayload>(
    (payload) =>
      axios.post(`${HookConfig.orderUrl}/baskets/${payload.basketId}/order-members`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};


type DeleteOrderMemberPayload = {
  basketId: string;
  id: string;
};

export const useDeleteOrderMember = () => {
  return useMutation<{ id: string }, AxiosError, DeleteOrderMemberPayload>(
    (payload) =>
      axios.delete(`${HookConfig.orderUrl}/baskets/${payload.basketId}/order-members/${payload.id}`, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};

type UpdateOrderMemberPayload = {
  basketId: string;
  id: string;
  comment: string;
};

export const useUpdateOrderMember = () => {
  return useMutation<{ id: string }, AxiosError, UpdateOrderMemberPayload>(
    (payload) =>
      axios.put(`${HookConfig.orderUrl}/baskets/${payload.basketId}/order-members/${payload.id}`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};