
import { InputBaseProps } from "@mui/material"
import { AsYouType } from "libphonenumber-js"
import { ControllerRenderProps } from "react-hook-form"
import { Input } from "./Input"
import { LabelType } from "./Label"


interface CustomProps extends InputBaseProps {
  labelProps?: LabelType;
  validationError?: {
    message?: string;
  };
  field?: ControllerRenderProps<any, any>;
  loading?: boolean;
}

export const TelephoneInput = ({ field, ...rest }: CustomProps) => {

  const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
    if (!phoneNumber) return phoneNumber;
    return new AsYouType().input(phoneNumber)
  }

  return (
    <Input
      {...field}
      {...rest}
      value={formatPhoneNumber(field?.value)}
    ></Input>
  )
}