import Pagination from '@mui/material/Pagination';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchCustomers } from '../../hooks/Customer/useCustomer';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { CustomerTypeName } from '../../types/Customer/CustomerSearchResponseDto';
import { SearchInput } from '../Common/SearchInput';
import HeaderDisplay from '../Home/HeaderDisplay';
import CustomerTable from './CustomerTable';
import './Customers.css';
import { useDebounceValue } from 'usehooks-ts'
import { Loader } from '../Common/UI/Loader';
import { Box, Grid } from '@mui/material';
import { CustomSelect } from '../Common/formComponents/CustomSelect';


type CustomerFeedProps = {
    userLogged: ApplicationUser;
    headers?: string[];
    handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
    compact?: boolean;
    memberTypeName?: CustomerTypeName;
    searchPlaceHolder?: string;
};

const CustomerFeed = ({ userLogged, compact, headers, handleArrowClick, memberTypeName, searchPlaceHolder }: CustomerFeedProps) => {
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [customerType, setCustomerType] = useState<CustomerTypeName>(memberTypeName || null);

    const [debouncedValue, setDebouncedValue] = useDebounceValue('', 500)
    const disableSelectBox = Boolean(memberTypeName)

    const defaultItemPerPage = 10;
    const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage)

    const [allCustomers, setAllCustomers] = useState<CustomerSearchItemResponseDto[]>([]);
    // Only proceed with fetching customers if the search query length is 3 characters or more
    const shouldFetchCustomers = searchQuery.length >= 3;
    const effectiveSearchQuery = shouldFetchCustomers ? debouncedValue.trim() : '';

    const { data: customersData, isLoading, isError } = useFetchCustomers({
        locationId: userLogged.location?.id!,
        searchRequirement: effectiveSearchQuery,
        pageNumber: 0, // Fetch all data at once
        pageSize: 0, // Pass 0 to fetch all items without pagination from the server
        memberTypeName: customerType
    });

    const handleSearchChange = (query: string) => {
        setSearchQuery(query);
    };

    useEffect(() => {
        if (customersData?.records) {
            setAllCustomers(customersData.records);
            setPage(1); // Reset to the first page after a new search
        }
    }, [customersData]);

    useEffect(() => {
        setDebouncedValue(searchQuery)
    }, [searchQuery])

    const navigate = useNavigate();

    const defaultHeaders = [
        'Account Reference',
        'First Name',
        'Last Name',
        'Company',
        'Relationship',
        'Type',
        'Orders',
        'Spend'
    ];

    const paginatedCustomers = useMemo(() => {
        const start = (page - 1) * itemsPerPage;
        return allCustomers.slice(start, start + itemsPerPage);
    }, [page, allCustomers, itemsPerPage]);

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const defaultHandleArrowClick = (customer: CustomerSearchItemResponseDto) => {
        const memberType = customer.memberTypeName as CustomerTypeName;
        if (memberType) {
            const path = {
                Individual: `/individuals/${customer.memberId}/summary`,
                CompanyContact: `/companies/${customer.companyId}/contacts/${customer.memberId}/summary`,
                Company: `/companies/${customer.memberId}/summary`,
            }
            navigate(path[memberType], { state: { customer, userLogged } });
        }
    };

    const customerTypeOptions = [{
        label: 'All',
        value: null
    }, {
        label: 'Contact',
        value: 'CompanyContact'
    }, {
        label: 'Company',
        value: 'Company'
    }, {
        label: 'Individual',
        value: 'Individual'
    }]

    const itemPerPageList = [{
        label: 5,
        value: 5
    }, {
        label: 10,
        value: 10
    }, {
        label: 20,
        value: 20
    }]

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={searchPlaceHolder || 'SEARCH_BY_CUSTOMER'} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomSelect
                        options={customerTypeOptions}
                        value={customerTypeOptions.find(x => x.value === customerType)}
                        onChange={(e) => setCustomerType((e?.value || null) as CustomerTypeName)}
                        isDisabled={disableSelectBox}
                    ></CustomSelect>
                </Grid>
            </Grid>
            {
                isLoading ? (
                    <Box sx={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </Box>
                ) : (
                    <div className="customer-feed">
                        <HeaderDisplay headers={headers ?? defaultHeaders} />
                        <CustomerTable
                            customers={paginatedCustomers}
                            headers={headers ?? defaultHeaders}
                            onActionClick={handleArrowClick ?? defaultHandleArrowClick}
                            userLogged={userLogged}
                            compact={compact}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Pagination
                                    count={Math.ceil(allCustomers.length / itemsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    color="primary"
                                    className="pagination-bar-order"
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CustomSelect
                                    options={itemPerPageList}
                                    value={itemPerPageList.find(x => x.value === itemsPerPage)}
                                    onChange={(e) => setItemsPerPage(e?.value || defaultItemPerPage)}
                                ></CustomSelect>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </>
    );
};

export default CustomerFeed;
