import { Box, DialogContent, DialogTitle } from "@mui/material";
import { LR } from "../../../Common/Help/LR";
import { CustomDialog } from "../../../Common/UI/CustomDialog";
import '../OrderForm.css';
import { useOrderContext } from "../OrderProvider";
import { OrderLineForm } from "./OrderLineForm";
import { OrderLineProvider } from "./OrderLineProvider";


export const DialogOrderLine = () => {

  const { orderLineOpen, setOrderLineOpen, orderLineEditMode, readOnlyMode } = useOrderContext()

  const handleCancelOrderLine = () => {
    setOrderLineOpen(false);
  }

  let title = orderLineEditMode ? 'COMMON_LABEL_EDITORDERLINE' : 'COMMON_LABEL_CREATEORDERLINE'

  if (readOnlyMode) {
    title = 'COMMON_LABEL_VIEWORDERLINE'
  }


  return (
    <CustomDialog
      open={orderLineOpen}
      onClose={handleCancelOrderLine}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle>
        <LR localResource={title}></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          minHeight: '550px',
        }}>
          <OrderLineProvider>
            <OrderLineForm />
          </OrderLineProvider>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
