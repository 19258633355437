import { useTranslation } from 'react-i18next';
type Args = {
    localResource: string,
    params? : any

};

const LR = ({ localResource, params = undefined }: Args) => {
    const { t } = useTranslation();
    return (
        <>

            {
                Boolean(localResource) && (
                    (params !== undefined && params !== '') ? t(localResource, params) : t(localResource)
                )
            }
        </>
    );
}

export {
    LR
}