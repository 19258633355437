import { useState } from "react";
import { ReportRequestDto } from "../../types/Report/ReportRequestDto";
import { ReportResponseDto } from "../../types/Report/ReportResponseDto";
import axios from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

interface UseGetReportsReturn {
    getReports: (params: ReportRequestDto) => Promise<void>;
    data: ReportResponseDto | null;
    isLoading: boolean;
    error: Error | null;
  }
  
  export const useGetReports = (): UseGetReportsReturn => {
    const [data, setData] = useState<ReportResponseDto | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
  
    const getReports = async (params: ReportRequestDto) => {
      try {
        setIsLoading(true);
        setError(null);
  
        const response = await axios.post<ReportResponseDto>(
          `${HookConfig.operatorMgmtUrl}/reports/key-metrics`,
          params,
          {
            headers: operatorDefaultHeaders(),
            withCredentials: true,
          }
        );
  
        setData(response.data);
  
      } catch (err) {
        const errorMessage = axios.isAxiosError(err)
          ? err.response?.data?.message || err.message
          : 'An error occurred while fetching reports';
        
        setError(new Error(errorMessage));
        setData(null);
  
      } finally {
        setIsLoading(false);
      }
    };
  
    return {
      getReports,
      data,
      isLoading,
      error
    };
  };