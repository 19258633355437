import { Card, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { LR } from "../Common/Help/LR";
import { SearchInput } from "../Common/SearchInput";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import CustomerFeed from "../Customer/CustomerFeed";

type Args =
  {
    userLogged: ApplicationUser;
    open: boolean;
    handleConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
    handleCancel: () => void
    headers?: string[]
  }

export const DialogCompanySelector = ({ userLogged, handleCancel, handleConfirm, open, headers }: Args) => {

  const defaultHeaders = [
    'Type',
    'Company',
    'First Name',
    'Last Name',
    'Relationship',
  ]

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <Card>
        <DialogTitle>
          <LR localResource="COMMON_LABEL_SELECTCOMPANY"></LR>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: 500, width: '100%' }}>
            <CustomerFeed
              userLogged={userLogged}
              compact={true}
              handleArrowClick={handleConfirm}
              headers={headers ?? defaultHeaders}
              memberTypeName={"Company"}
            />
          </div>
        </DialogContent>
      </Card>
    </Dialog>
  );
};
