import axios, { AxiosError } from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { BasketCreateResponse } from "../../types/Order/BasketCreateResponse";
import { BasketRequest } from "../../types/Order/BasketRequest";
import { BasketResponse } from "../../types/Order/BasketResponse";
import { OrderLineRequest } from "../../types/Order/OrderLineRequest";
import { OrderLineResponse } from "../../types/Order/OrderLineResponse";
import { PaymentMethod } from "../../types/Order/paymentMethodDto";

type BasketUpdateIdArg = {
  params: {
    basketId: string,
    orderLineId?: string,
  },
  options?: UseQueryOptions<any, AxiosError>
}

export const useGetBasketById = ({
  basketId,
  options
}: {
  basketId?: string,
  options?: UseQueryOptions<BasketResponse, AxiosError>
}) => useQuery<BasketResponse, AxiosError>(["basket-detail", basketId], () =>
  axios.get(
    `${HookConfig.orderUrl}/baskets/${basketId}`,
    {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    })
    .then(
      (response) => {
        return response.data;
      }
    ), {
  ...options
});


export const usePaymentsMethodByUser = ({
  memberId,
  companyId,
  options
}: {
  memberId: string,
  companyId?: string
  options?: UseQueryOptions<PaymentMethod[], AxiosError>
}) => useQuery<PaymentMethod[], AxiosError>(["payment-detail", memberId, companyId], () =>
  axios.get(
    `${HookConfig.orderUrl}/payment-methods/members/${memberId}${companyId ? `?parentMemberId=${companyId}` : ''}`,
    {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    })
    .then(
      (response) => {
        return response.data;
      }
    ), {
  ...options
});


export const useBasketOrderLineCreate = () => {
  return useMutation<OrderLineResponse, AxiosError, OrderLineRequest>(
    (orderLineData) => axios.post(`${HookConfig.orderUrl}/baskets/${orderLineData.basketId}/order-lines`, orderLineData,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};

export const useBasketOrderLineUpdate = (args: {
  params: {
    orderLineId?: string,
  },
}) => {
  return useMutation<OrderLineResponse, AxiosError, OrderLineRequest>(
    (orderLineData) => axios.put(`${HookConfig.orderUrl}/baskets/${orderLineData.basketId}/order-lines/${args.params.orderLineId}`, orderLineData,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};

export const useBasketOrderLineDelete = () => {
  return useMutation<OrderLineResponse, AxiosError, BasketUpdateIdArg>(
    (args) => axios.delete(`${HookConfig.orderUrl}/baskets/${args.params.basketId}/order-lines/${args.params.orderLineId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};

export const useBasketCreate = () => {
  return useMutation<BasketCreateResponse, AxiosError, BasketRequest>(
    (basketData) => axios.post(`${HookConfig.orderUrl}/baskets/`, basketData,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};


type UpdateBasketStatusPayload = { basketId: string, orderStatusName: string, orderSubStatusName: string }

export const useUpdateBasketStatus = () => {
  return useMutation<{ id: string }, AxiosError, UpdateBasketStatusPayload>(
    (params: UpdateBasketStatusPayload) =>
      axios.put(
        `${HookConfig.orderUrl}/baskets/${params.basketId}/status`,
        {
          ...params
        },
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        }
      ).then((response) => {
        return response.data;
      })
  );
}

type UpdatePlaceOrderPayload = { basketId: string, paymentMethodName: string, purchaseOrderNumber?: string, comments?: string }

export const useUpdatePlaceOrder = () => {
  return useMutation<{ id: string }, AxiosError, UpdatePlaceOrderPayload>(
    (payload: UpdatePlaceOrderPayload) =>
      axios.put(
        `${HookConfig.orderUrl}/baskets/${payload.basketId}`,
        payload,
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        }
      ).then((response) => {
        return response.data;
      })
  );
}

type PlaceOrderPayload = {
  basketId: string;
  trackingData: string;
}

type PlaceOrderResponse = {
  id: string
  hasErrors: boolean
  errors: any[]
  expiryUtcDate: any
  orderStatusName: string
  orderStatusLabel: string
  orderSubStatusName: string
  orderSubStatusLabel: string
}

export const usePlaceOrder = () => {
  return useMutation<PlaceOrderResponse, AxiosError, PlaceOrderPayload>(
    (payload) => axios.post(`${HookConfig.orderUrl}/baskets/${payload.basketId}/order`, payload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};
