import { MultiInputTimeRangeField, MultiInputTimeRangeFieldProps } from "@mui/x-date-pickers-pro";
import { colors } from "../../../main/Theme";
import { Dayjs } from "dayjs";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Or any other icon you prefer
import { Label, LabelType } from "./Label";

interface CustomProps extends MultiInputTimeRangeFieldProps<Dayjs | null | Date> {
  startLabel: LabelType;
  endLabel: LabelType;
}

export const CustomMultiInputTimeRangeField = ({
  startLabel,
  endLabel,
  disabled,
  ...rest
}: CustomProps) => {
  return (
    <MultiInputTimeRangeField
      slotProps={{
        textField: ({ position }) => ({
          InputLabelProps: {
            component: 'div',
          },
          label: position === "start" ? <Label {...startLabel} /> : <Label {...endLabel} />,
          sx: (theme) => {
            return {
              width: '100%',
              '& .MuiFormLabel-root': {
                position: 'relative',
                transform: 'none',
                color: colors.white,
                '&.Mui-focused': {
                  color: colors.white,
                }
              },
              '& .MuiInputBase-root': {
                backgroundColor: colors.grey[100],
              },
              '& fieldset, & .MuiInputBase-root': {
                borderColor: 'transparent !important',
                borderRadius: '0 !important',
              },
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: colors.grey[100],
                padding: '.375rem .75rem',
                fontSize: '1rem',
                lineHeight: '2',
                display: 'block',
                height: 'auto',
                width: '100%',
                border: '2px solid',
                borderColor: 'transparent',
                boxShadow: 'none',
                transition: theme.transitions.create([
                  'border-color',
                  'background-color',
                  'box-shadow',
                ]),
                '&:focus': {
                  borderColor: colors.grey[200],
                },
              },
              '& .Mui-error .MuiInputBase-input': {
                borderColor: colors.red[100],
                color: colors.red[100],
              },
            }
          }
        }),
        root: {
          sx: {
            alignItems: 'flex-end',
            zIndex: 0,
            position: 'relative',
            opacity: disabled ? 0.5 : 1,
          }
        },
        separator: {
          children: <ArrowForwardIcon sx={{
            color: colors.white,
            mx: 0
          }} />,
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48px',
            width: '20px',
          }
        },
      }}
      disabled={disabled}
      {...rest}
    />
  );
};