import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { formatPrice } from "../../../functions/utils/fiscal";
import { formaOrderLineAvailability } from "../../../functions/utils/helper";
import i18n from "../../../i18n";
import { colors } from "../../../main/Theme";
import { CustomConfirmDialog } from "../../Common/UI/CustomConfirmDialog";
import { useOrderContext } from "./OrderProvider";
import VisibilityIcon from '@mui/icons-material/Visibility';

export const OrderItemSummary = () => {
  const {
    orderLines,
    handleEditOrderLine,
    deleteConfirmation,
    setDeleteConfirmation,
    currentBasket,
    handleDeleteOrderLine,
    readOnlyMode
  } = useOrderContext()

  if (!(orderLines && orderLines.length > 0)) {
    return <></>
  }

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 2, marginTop: 2, position: 'relative', zIndex: 0 }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow sx={{ backgroundColor: colors.primary }}>
            <TableCell>{i18n.t("ORDERLINE_LABEL_DESCRIPTION")}</TableCell>
            <TableCell align="right">{i18n.t("ORDERLINE_LABEL_PERIOD")}</TableCell>
            <TableCell align="right">{i18n.t("ORDERLINE_LABEL_PRICE")}</TableCell>
            <TableCell align="right">{i18n.t("ORDERLINE_LABEL_UNIT")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderLines.map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {item.description}
              </TableCell>
              <TableCell align="right">
                {formaOrderLineAvailability(item.fromDate, item.toDate)}
              </TableCell>
              <TableCell align="right">
                      {formatPrice(item.currencyCode, item.priceExTax)}
              </TableCell>
                  <TableCell align="right">
                      {item.unitName}
              </TableCell>
              <TableCell align="right">
                <Tooltip title={i18n.t("COMMON_TOOLTIP_DETAILS")}>
                  <IconButton
                    className="fa-info"
                    onClick={() =>
                      handleEditOrderLine(item.id!)
                    }
                  >
                    {
                      readOnlyMode ? <VisibilityIcon sx={{ color: colors.primary }} /> : <EditRoundedIcon sx={{ color: colors.primary }} />
                    }

                  </IconButton>
                </Tooltip>
                {!readOnlyMode && (
                  <Tooltip
                    title={i18n.t("COMMON_TOOLTIP_DELETE", {
                      param0: item.description,
                    })}
                  >
                    <IconButton
                      onClick={(e) => setDeleteConfirmation(true)}
                    >
                      <DeleteRoundedIcon color={"error"} />
                    </IconButton>
                  </Tooltip>
                )}

                <CustomConfirmDialog
                  isOpen={deleteConfirmation}
                  onClose={() => setDeleteConfirmation(false)}
                  title={i18n.t(`INVENTORY_LABEL_DELETEALLOCATION`)}
                  content={i18n.t(`COMMON_BUTTON_DELETETEXT`, { name: item.description })}
                  confirmButtonColor={'error'}
                  confirmButtonTitle={i18n.t(`COMMON_BUTTON_DELETE`)}
                  handleConfirm={() => { handleDeleteOrderLine(item.id!); }}
                ></CustomConfirmDialog>
              </TableCell>
            </TableRow>
          ))}
          {currentBasket &&
            <>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={2} align="right">
                  {i18n.t("ORDER_LABEL_SUBTOTAL")}
                </TableCell>
                <TableCell align="right">
                  {formatPrice("GBP", currentBasket.totalPriceExTax)}
                </TableCell>
                <TableCell align="right">
                  {i18n.t("ORDER_LABEL_EXVAT")}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" colSpan={2} align="right">
                  {i18n.t("ORDER_LABEL_TOTAL")}
                </TableCell>
                <TableCell align="right">
                  {formatPrice("GBP", currentBasket.totalPriceIncTax)}
                </TableCell>
                <TableCell align="right">
                  {i18n.t("ORDER_LABEL_INCVAT")}
                </TableCell>
                <TableCell />
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}