import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { colors } from '../../../main/Theme';
import { Label, LabelType } from './Label';
import { ValidationError } from './ValidationError';
import { ControllerRenderProps } from 'react-hook-form';
import { Box, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';


interface CustomProps extends InputBaseProps {
  labelProps?: LabelType;
  validationError?: {
    message?: string;
  };
  field?: ControllerRenderProps<any, any>;
  loading?: boolean;  
  multiline?: boolean;     
  rightIcon?: ReactNode;
  
}

export const InputStyle = styled(InputBase)(({ theme, error, disabled }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: colors.grey[100],
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '2',
    display: 'block',
    height: 'auto',
    width: '100%',
    border: '2px solid',
    borderColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: colors.grey[200],
    },
    ...(error && {
      borderColor: alpha(colors.red[100], 0.5),
    }),
  },
}));

export const Input = (props: CustomProps) => {
  const { validationError, labelProps, field, disabled, multiline, rows, loading, rightIcon, ...rest } = props;
  let disabledStyle = disabled ? { opacity: 0.5 } : {}

  return (
    <Box sx={{ ...disabledStyle, position: 'relative' }}>
      {labelProps && <Label {...labelProps} error={!!validationError} />}
      <Box sx={{ position: 'relative' }}>
        <InputStyle
          error={!!validationError}
          disabled={disabled}
          {...field}
          {...rest}
          multiline={multiline}
          rows={multiline ? rows : undefined} 
        />
        {rightIcon && <Box sx={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)'
        }}>{rightIcon}</Box>}
      </Box>
      {validationError && <ValidationError message={validationError?.message} />}
      {loading &&
        <CircularProgress
          color="success"
          size={20}
          sx={{
            position: 'absolute',
            top: '2.8rem',
            right: '1rem'
          }} />
      }
    </Box>
  );
};
