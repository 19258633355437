import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { getLabelsByOperatorType, useFetchMemberById, useFetchMemberSummaryById } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import { useSource } from "../../providers/SourceProvider";

type SourceType = SourceEnum.customers | SourceEnum.operators | SourceEnum.legalEntity | SourceEnum.companies | SourceEnum.individuals | SourceEnum.contacts

type Args = {
    userLogged: ApplicationUser
}

const HostSummary = ({ userLogged }: Args) => {

    const { source: _source } = useSource()
    const source = _source as SourceType

    const nav = useNavigate();

    const { id, parentId, summaryName } = useParams()

    if (!id) throw Error(`${source} id needed`);

    const titleLabel = getSummaryLabel(summaryName!);

    const { data: memberData, status: memberStatus, isSuccess: memberIsSuccess } = useFetchMemberById(id!)
    const { data, status, isSuccess } = useFetchMemberSummaryById(id!, summaryName!);
    const [country, setCountry] = useState<string>('');

    useEffect(() => {
        if (memberData)
            setCountry(memberData.countryIso3Code);


    }, [memberData]);

    if ((!isSuccess || !memberIsSuccess) && (status !== 'success') && (memberStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    const linkCallBackCollection = (item: SummaryItemDto) => {
        const navHandle: Record<string, string> = {
            "MemberSummary": `/${source}/${id}/companydetails`,
            "ContactManagementSummary": `/${source}/${id}/contacts`,
            "ContactSummary": `/${source}/${id}/contacts`,
            "KycSummary": `/${source}/${id}/kyc-details/${country}`,
            "FiscalSummary": `/${source}/${id}/fiscal-details/${country}`,
            "NotificationSummary": `/${source}/${id}/contact-management/notifications`,
            "OperatorLocationSummary": `/${source}/${id}/locations`,
            "CommunicationSummary": `/${source}/${id}/summary/communications`,
            "PhoneSummary": `/${source}/${id}/communications/phone`,
            "EmailSummary": `/${source}/${id}/communications/email`,
            "SocialSummary": `/${source}/${id}/communications/social`,
            "WebSummary": `/${source}/${id}/communications/web`,
            "LegalEntitySummary": `/${source}/${id}/legal-entities`,
            "DomainSummary": `/${source}/${id}/domains`,
            "PaymentMethods": `/${source}/${id}/payment-methods`,
            "CompanySummary": `/${source}/${item.entityKey}/summary`,
            "ContactDetails": `/${source}/${parentId}/contacts/${id}`,
            "IndividualDetails": `/${source}/${id}`,
            "Orders": `/${source}?${id}`,
            "ImagesSummary": `/${source}/${id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`,
        }
        const path = navHandle[item.name]
        if (path) {
            nav(path);
        } else {
            alert(`Item ${item.name} does not have a link yet`);
        }
    }

    const homeCallBack = () => {
        switch (source) {
            case "customers":
            case "individuals":
            case "companies":
                nav(`/customers`);
                break;
            case "operators":
                nav(`/admin`);
                break;
            case "legal-entities":
                nav(`/legal-entities`);
                break;
            default:
                nav(`/`);
        }
    };

    let ctaText: Record<SourceType, string> = {
        "legal-entities": i18n.t("COMMON_LABEL_LEGALENTITIES"),
        companies: i18n.t("COMMON_LABEL_CUSTOMERS"),
        contacts: i18n.t("COMMON_LABEL_CUSTOMERS"),
        customers: i18n.t("COMMON_LABEL_CUSTOMERS"),
        individuals: i18n.t("COMMON_LABEL_CUSTOMERS"),
        operators: i18n.t("COMMON_LABEL_ADMIN"),
    };

    let ctaTextWithSummary: Record<string, string> = {
        companies: i18n.t("COMMON_SUMMARY_COMPANYSUMMARY"),
        operators: i18n.t("MEMBER_SUMMARY_OPERATORMAINPAGE"),
    };


    if (!data || !memberData) return <ApiStatus status={status} />;

    const labels = getLabelsByOperatorType(memberData);

    const linkCallBack = () => {
        nav(`/${source}/${id}/summary`);
    }

    return (
        <>

            {!summaryName &&
                <SectionHead linkCallBack={homeCallBack}
                    ctaText={ctaText[source as SourceType]}
                    name={`${memberData.fullName} (${memberData.externalReference})`}
                    title={i18n.t(labels!.title)}
                    description={i18n.t(labels!.label)} />
            }

            {summaryName &&
                <SectionHead
                    linkCallBack={linkCallBack}
                    ctaText={ctaTextWithSummary[source as SourceType]}
                    name={`${memberData.fullName} (${memberData.externalReference})`}
                    title={i18n.t(titleLabel!)}
                    description={i18n.t(labels!.label)}
                />
            }


            <div className={`row d-flex justify-content-center `}>
                <div className="col-md-7 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { HostSummary };
