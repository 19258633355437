import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDebounceValue } from "usehooks-ts"
import { useCreateOrderBusinessRule, useDeleteOrderBusinessRule, useUpdateOrderBusinessRule } from "../../../hooks/Order/orderBusinessRules"
import { BusinessRuleResponseDto } from "../../../types/Product/BusinessRuleResponseDto"
import { BusinessRules } from "../../Product/Configuration/BusinessRules"
import { useOrderContext } from "./OrderProvider"
import { Box } from "@mui/material"

export const OrderBusinessRules = () => {
  const { basketId } = useParams()
  const { currentBasket, refetchBasket, refetchingBasket, readOnlyMode } = useOrderContext()
  const { orderBusinessRules } = currentBasket || {}

  const [localBusinessRules, setLocalBusinessRules] = useState<BusinessRuleResponseDto[]>(orderBusinessRules || [])
  const [debouncedValue, setDebouncedValue] = useDebounceValue(orderBusinessRules, 500)

  const { mutateAsync: updateBusinessRuleAsync, isLoading: updatingBusinessRuleAsync } = useUpdateOrderBusinessRule()
  const { mutate: deleteBusinessRule, isLoading: deletingBusinessRule } = useDeleteOrderBusinessRule()
  const { mutate: createBusinessRule, isLoading: creatingBusinessRule } = useCreateOrderBusinessRule()

  const onSuccess = () => {
    refetchBasket().then((item) => {
      console.log(item)
    })
  }

  const handleToggle = (item: BusinessRuleResponseDto) => {
    if (readOnlyMode) return
    if (!basketId) {
      throw new Error("BasketId is required")
    }
    if (item.isDelete) {
      deleteBusinessRule({
        basketId: basketId,
        id: item.id
      }, {
        onSuccess: onSuccess
      })
    } else {
      createBusinessRule({
        basketId: basketId,
        ...item
      }, {
        onSuccess: onSuccess
      })
    }
  }

  const handleItemChanged = (items: BusinessRuleResponseDto[]) => {
    setDebouncedValue(items)
  }


  useEffect(() => {
    setLocalBusinessRules(orderBusinessRules || [])
  }, [orderBusinessRules])

  useEffect(() => {
    setDebouncedValue(localBusinessRules)
  }, [localBusinessRules])

  useEffect(() => {
    if(readOnlyMode) return
    if (!basketId || !debouncedValue || !debouncedValue.length) return

    const runUpdates = async () => {
      const updatePromises = debouncedValue
        .filter((x) => x.isChanged)
        .map((x) => updateBusinessRuleAsync({ ...x, basketId }));

      try {
        // Wait for all updates to complete
        await Promise.all(updatePromises);
        onSuccess();
      } catch (error) {
        console.error('Error in updates:', error);
      }
    };

    runUpdates();

  }, [debouncedValue])

  const disableClick = creatingBusinessRule || updatingBusinessRuleAsync || deletingBusinessRule || refetchingBasket

  if (!orderBusinessRules || orderBusinessRules?.length <= 0) {
    return null
  }

  return (
    <Box sx={{ 'pointerEvents': disableClick ? 'none' : 'auto' }}>
      <BusinessRules
        itemsChangedCallBack={handleItemChanged}
        mutationSwitchCallBack={() => { }}
        handleDirty={() => { }}
        type={'ORDER'}
        items={localBusinessRules}
        isOrderPage={true}
        handleToggle={handleToggle}
        forceRefresh
      />
    </Box>
  )
}