import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  IconButton
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { CustomSelect } from "../../Common/formComponents/CustomSelect";
import { Input } from "../../Common/formComponents/Input";
import { SectionHead } from "../../Common/Help/SectionHead";
import { CustomButton } from "../../Common/UI/CustomButton";
import { DialogMemberSelector } from "../../Member/DialogMemberSelector";
import { Addendum } from "./Addendum";
import { OrderBusinessRules } from "./OrderBusinessRules";
import "./OrderForm.css";
import { OrderItemSummary } from "./OrderItemSummary";
import { DialogOrderLine } from "./orderline/DialogOrderLine";
import { OrderMember } from "./OrderMember";
import { useOrderContext } from "./OrderProvider";

const OrderForm = () => {
  const nav = useNavigate();
  const { basketId } = useParams()
  const {
    control,
    customerOpen,
    currentBasket,
    formattedOrderStatus,
    formattedOrderSubStatus,
    handleCancelMemberSelector,
    handleCreateNewOrderLine,
    handleCustomerConfirm,
    isPlaceOrderLoading,
    isPlacingOrder,
    isValid,
    onOrderStatusChange,
    onOrderSubStatusChange,
    orderEditMode,
    orderLines,
    paymentMethods,
    placeOrder,
    readOnlyMode,
    setCustomerOpen,
    userLogged,
  } = useOrderContext()

  const disableClientSelection = Boolean(basketId)

  const goBack = () => {
    nav('/orders');
  };

  let title = i18n.t(orderEditMode ? "ORDER_LABEL_EDIT_ORDER" : "ORDER_LABEL_CREATE_ORDER").toString()

  if (readOnlyMode) {
    title = i18n.t("ORDER_LABEL_VIEW_ORDER").toString()
  }

  return (
    <>
      <SectionHead
        linkCallBack={goBack}
        ctaText={i18n.t("COMMON_SUMMARY_BOOKINGS")!}
        name={title}
        title={title}
        description={
          i18n.t("ORDER_LABEL_ORDER_DESCRIPTION").toString()
        }
      />
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <form noValidate onSubmit={placeOrder}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="locationName"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_LOCATION").toString(),
                        mandatory: true,
                      }}
                      placeholder={i18n.t("ORDER_LABEL_LOCATION").toString()}
                      value={field.value}
                      disabled
                      validationError={fieldState.error}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <DialogMemberSelector
                  userLogged={userLogged}
                  open={customerOpen}
                  handleConfirm={handleCustomerConfirm}
                  handleCancel={handleCancelMemberSelector}
                  memberTypeName={"CompanyContact"}
                ></DialogMemberSelector>

                <Controller
                  name="memberName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Box position="relative">
                      <Input
                        labelProps={{
                          message: i18n.t("ORDER_LABEL_CLIENT").toString(),
                          mandatory: true,
                        }}
                        placeholder={i18n.t("ORDER_LABEL_CLIENT").toString()}
                        sx={{ input: { paddingRight: "3rem !important" } }}
                        validationError={fieldState.error}
                        readOnly
                        value={field.value}
                        onClick={() => setCustomerOpen(true)}
                        disabled={disableClientSelection || readOnlyMode}
                        rightIcon={
                          <IconButton
                            type="button"
                            onClick={() => setCustomerOpen(true)}
                            disabled={disableClientSelection || readOnlyMode}
                          >
                            <SearchIcon />
                          </IconButton>
                        }
                      ></Input>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="orderStatusName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_STATUS",
                          mandatory: true,
                        }}
                        options={formattedOrderStatus}
                        placeholder={i18n.t("ORDER_LABEL_STATUS")}
                        field={field}
                        onChange={(e) => {
                          onOrderStatusChange(e);
                          field.onChange(e);
                        }}
                        validationError={fieldState.error}
                        isDisabled={readOnlyMode}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="orderSubStatusName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_SUBSTATUS",
                          mandatory: true,
                        }}
                        options={formattedOrderSubStatus}
                        placeholder={i18n.t("ORDER_LABEL_SUBSTATUS")}
                        field={field}
                        value={field.value || null}
                        onChange={(e) => {
                          onOrderSubStatusChange(e);
                          field.onChange(e);
                        }}
                        validationError={fieldState.error}
                        isDisabled={readOnlyMode}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="paymentMethodName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_PAYMENTMETHOD",
                          mandatory: true,
                        }}
                        options={paymentMethods !== undefined ? paymentMethods : []}
                        placeholder={i18n.t("ORDER_LABEL_PAYMENTMETHOD")}
                        field={field}
                        validationError={fieldState.error}
                        getOptionLabel={(option: any) => option.pspName}
                        getOptionValue={(option: any) => option.pspName}
                        isDisabled={readOnlyMode}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="purchaseOrderNumber"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_PURCHASEORDER").toString(),
                        mandatory: false,
                      }}
                      placeholder={i18n.t("ORDER_LABEL_PURCHASEORDER").toString()}
                      field={field}
                      validationError={fieldState.error}
                      disabled={readOnlyMode}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="comments"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_COMMENT").toString(),
                        mandatory: false,
                      }}
                      multiline={true}
                      rows={3}
                      field={field}
                      validationError={fieldState.error}
                      disabled={readOnlyMode}
                    />
                  )}
                />
              </Grid>
              {
                !readOnlyMode && (
                  <Grid item xs={1} md={4} mx={"auto"} mt={3}>
                    <CustomButton
                      variant="contained"
                      fullWidth
                      disabled={!isValid}
                      type="button"
                      onClick={handleCreateNewOrderLine}
                    >
                      {i18n.t("ORDER_BUTTON_NEWORDERLINE")}
                    </CustomButton>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <OrderItemSummary></OrderItemSummary>
                <DialogOrderLine />
              </Grid>

              {
                orderLines.length > 0 && (
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12}>
                      <OrderBusinessRules />
                    </Grid>
                    {
                      currentBasket?.showOrderMembers && (
                        <Grid item xs={12}>
                          <OrderMember />
                        </Grid>
                      )
                    }
                    {
                      currentBasket?.showAddendums && (
                        <Grid item xs={12}>
                          <Addendum />
                        </Grid>
                      )
                    }
                    {!readOnlyMode && (
                      <Grid item xs={1} md={4} mx={"auto"} mt={3}>
                        <CustomButton
                          variant="contained"
                          fullWidth
                          disabled={!isValid || isPlaceOrderLoading}
                          type="submit"
                          loading={isPlaceOrderLoading || isPlacingOrder}
                        >
                          {i18n.t("COMMON_LABEL_SAVE")}
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                )
              }
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export { OrderForm };

