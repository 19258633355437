import i18n from "../../i18n";

const validateIBAN = (iban: string): boolean => {
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;

  // Remove whitespace and convert to uppercase
  const formattedIBAN = iban.replace(/\s+/g, '').toUpperCase();

  // Check if the formatted IBAN matches the regex pattern
  if (!ibanRegex.test(formattedIBAN)) {
    return false;
  }

  // Perform IBAN checksum validation
  const ibanWithoutChecksum = formattedIBAN.substring(4) + formattedIBAN.substring(0, 2);
  let ibanNumeric = '';

  for (let i = 0; i < ibanWithoutChecksum.length; i++) {
    const char = ibanWithoutChecksum.charAt(i);
    if (char >= 'A' && char <= 'Z') {
      ibanNumeric += (char.charCodeAt(0) - 55).toString();
    } else {
      ibanNumeric += char;
    }
  }

  const remainder = ibanNumeric.split('').reduce((prev, curr) => {
    const chunk = prev + curr;
    const chunkValue = parseInt(chunk, 10);
    const remainder = chunkValue % 97;
    return remainder;
  }, 0);

  return remainder === 1;
};



const formatPrice = (currencyCode: string, value: number) => {

  const numberFormatter = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: currencyCode,
  });

  return numberFormatter.format(value)

} 



export { 
  validateIBAN,
  formatPrice
 };
