import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartOptions,
  ChartData,
} from 'chart.js';

import { Box, Paper } from '@mui/material';
import { Doughnut } from 'react-chartjs-2'; // Use Doughnut instead of Pie
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { formatCurrency } from '../../../functions/utils/helper';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface ProductPieChartProps {
  userLogged: ApplicationUser;
  title?: string;
  width?: string | number;
  height?: string | number;
}

export const ProductPieChart: React.FC<ProductPieChartProps> = ({
  userLogged,
  title = 'Revenue by Product',
  width = '100%',
  height = 400,
}) => {
  const chartDataConfig: ChartData<'doughnut'> = {
    labels: ['Office','Coworking Desk' , 'Locker', 'Internet',],
    datasets: [
      {
        data: [187009, 7800, 105,  645],
        backgroundColor: [
          'rgba(54, 162, 235, 0.8)',
          'rgba(255, 206, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          padding: 20,
          usePointStyle: true,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw as number;
            const percentage = (
              (value / chartDataConfig.datasets[0].data.reduce((a, b) => a + b, 0)) *
              100
            ).toFixed(2);
            return `${context.label}: ${formatCurrency(value, '£')} (${percentage}%)`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
      },
    },
    cutout: '50%', // Creates the doughnut hole
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }} className="chart-paper">
      <h4>{title}</h4>
      <Box sx={{ width, height }}>
        <Doughnut data={chartDataConfig} options={options} />
      </Box>
    </Paper>
  );
};