import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SearchInput } from '../Common/SearchInput';
import DateNavigation from '../Common/DateNavigation/DateNavigation';
import ActivityFeed from './ActivityFeed';
import { IconButton, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { LR } from "../Common/Help/LR";
import { debounce } from 'lodash';

type Args = {
  userLogged: ApplicationUser;
}

const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
  const today = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
  return { from: today, to: today };
};

const formatDateToLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

const Activities = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchQuery);

  const now = new Date();
  const defaultDates = calculateDates(now);
  const defaultFromDate = formatDateToLocalString(defaultDates.from);
  const defaultToDate = formatDateToLocalString(defaultDates.to);

  const [fromDate, setFromDate] = useState<string>(defaultFromDate);
  const [toDate, setToDate] = useState<string>(defaultToDate);

  const handleSearchChange = (query: string) => {
    console.log('Search query changed')
    setSearchQuery(query);
  };

  const handleDebouncedSearchChange = useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term);
    }, 500), // 1 second delay
    []
  );

  useEffect(() => {
    handleDebouncedSearchChange(searchQuery);
  }, [searchQuery]);

  const addNewItem = () => {
    nav(`/`);
  };

  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setFromDate(formatDateToLocalString(startDate));
    setToDate(formatDateToLocalString(endDate));
  };

  return (
    <div className="mainpage-container-layout">
      <div className='col-md-10 col-11 mt-2 justify-content-center'>
        <div className='activity-container-child'>
          <div className='activity-container-date'>
            <DateNavigation onDateSelect={handleDateRangeChange} fromDate={new Date(fromDate)} toDate={new Date(toDate)} />
          </div>
          <div className='activity-container-search-wrapper'>
            <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={'SEARCH_BY_ACTIVITY'} />
          </div>
        </div>
        <ActivityFeed userLogged={userLogged} searchQuery={debouncedSearchTerm} fromDate={fromDate} toDate={toDate} />
      </div>
    </div>
  );
}

export { Activities };
