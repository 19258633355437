import { ProductConfig } from "../../types/Product/ProductConfigDto";

export const pontPaymentMethod = [
  { label: "ORDER_LABEL_PAYMENTSTRIPE", value: "Credit Card" },
  { label: "ORDER_LABEL_PAYMENTONACCOUNT", value: "On Account" },
  { label: "ORDER_LABEL_PAYMENTDIRECTDEBIT", value: "Direct Debit" },
];

export const pontOrderStatus = [
  { label: "ORDER_LABEL_ORDERSTATUSDRAFT", value: "Draft" },
  { label: "ORDER_LABEL_ORDERSTATUSCONFIRMED", value: "Confirmed" },
  { label: "ORDER_LABEL_ORDERSTATUSPROVISIONAL", value: "Provisional" },
];



export const pontOrderSubStatus = [
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSDRAFT",
    value: "Draft",
    orderStatus: "Draft",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSCONFIRMEDAWAITINGPAYMENT",
    value: "ConfirmedAwaitingPayment",
    orderStatus: "Confirmed",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSCONFIRMEDPAID",
    value: "ConfirmedPaid",
    orderStatus: "Confirmed",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSPROVISIONALAWAITINGPAYMENT",
    value: "ProvisionalAwaitingPayment",
    orderStatus: "Provisional",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSAWAITINGLOCATIONCONFIRMATION",
    value: "AwaitingLocationConfirmation",
    orderStatus: "Provisional",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSCONFIRMEDUNPAID",
    value: "ConfirmedUnpaid",
    orderStatus: "Confirmed",
  },
  {
    label: "ORDER_LABEL_ORDERSUBSTATUSCONFIRMEDONACCOUNT",
    value: "ConfirmedOnAccount",
    orderStatus: "Confirmed",
  }
];

export const pontProductsConfig: ProductConfig[] = [
  {
    "productTypeName": "Admin",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_ITEM",
    "unitLabelOverride": null,
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "BusinessLounge",
    "hasImages": true,
    "hasImportantDocuments": true,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": true,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": false,
    "requiresStartTime": true,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PEOPLE",
    "unitLabelOverride": "LABEL_UNIT_ADMISSION",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Catering",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": true,
    "requiresEndTime": true,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_ITEM",
    "unitLabelOverride": "LABEL_UNIT_UNIT",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "CoworkingDesk",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": true,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_DESK",
    "unitLabelOverride": "LABEL_UNIT_DESK",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "DayPass",
    "hasImages": true,
    "hasImportantDocuments": true,
    "hasFeatures": true,
    "hasQuantityLimitPerOrder": true,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": false,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PASS",
    "unitLabelOverride": "LABEL_UNIT_DAYPASS",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Deposit",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": false,
    "quantityLabelOverride": null,
    "unitLabelOverride": "LABEL_UNIT_MONEY",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Event",
    "hasImages": true,
    "hasImportantDocuments": true,
    "hasFeatures": true,
    "hasQuantityLimitPerOrder": true,
    "hasAddress": true,
    "hasFixedDate": true,
    "showSpecialRequests": true,
    "showCancellationPolicy": true,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": false,
    "requiresEndDate": false,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_TICKET",
    "unitLabelOverride": "LABEL_UNIT_ADMISSION",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "EventSpace",
    "hasImages": false,
    "hasImportantDocuments": true,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": true,
    "showCheckAvailability": true,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": true,
    "requiresEndTime": true,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PEOPLE",
    "unitLabelOverride": null,
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Internet",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_ITEM",
    "unitLabelOverride": null,
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Locker",
    "hasImages": true,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": true,
    "showCheckAvailability": true,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": false,
    "unitLabelOverride": null,
    "quantityLabelOverride": "LABEL_QUANTITY_LOCKER",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "MeetingRoom",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": false,
    "showCheckAvailability": true,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": true,
    "requiresStartDate": true,
    "requiresEndDate": false,
    "requiresStartTime": true,
    "requiresEndTime": true,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PEOPLE",
    "unitLabelOverride": "LABEL_UNIT_PEOPLE",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Office",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": true,
    "showCheckAvailability": true,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PEOPLE",
    "unitLabelOverride": "LABEL_UNIT_PEOPLE",
    "allowOccupancyAdjustment": true
  },
  {
    "productTypeName": "Parking",
    "hasImages": true,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": true,
    "showCheckAvailability": true,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": true,
    "requiresEndTime": true,
    "requiresQuantity": true,
    "quantityLabelOverride": null,
    "unitLabelOverride": "UNIT_LABEL_PARKING",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "PhoneBooth",
    "hasImages": true,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": true,
    "hasAddress": true,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": true,
    "showCheckAvailability": true,
    "hasOpeningTimes": true,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": true,
    "requiresEndTime": true,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_PEOPLE",
    "unitLabelOverride": "LABEL_UNIT_PEOPLE",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Subscription",
    "hasImages": true,
    "hasImportantDocuments": true,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": false,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": false,
    "quantityLabelOverride": null,
    "unitLabelOverride": "LABEL_UNIT_SUBSCRIPTION",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "Telephone",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": false,
    "showTermsAndConditions": false,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": true,
    "quantityLabelOverride": "LABEL_QUANTITY_ITEM",
    "unitLabelOverride": "LABEL_UNIT_ITEM",
    "allowOccupancyAdjustment": false
  },
  {
    "productTypeName": "VirtualOffice",
    "hasImages": false,
    "hasImportantDocuments": false,
    "hasFeatures": false,
    "hasQuantityLimitPerOrder": false,
    "hasAddress": false,
    "hasFixedDate": false,
    "showSpecialRequests": false,
    "showCancellationPolicy": true,
    "showTermsAndConditions": true,
    "showCheckAvailability": false,
    "hasOpeningTimes": false,
    "requiresCheckAvailability": false,
    "requiresStartDate": true,
    "requiresEndDate": true,
    "requiresStartTime": false,
    "requiresEndTime": false,
    "requiresQuantity": false,
    "quantityLabelOverride": null,
    "unitLabelOverride": null,
    "allowOccupancyAdjustment": false
  }
]