import { ReportResponseDto } from "../../types/Report/ReportResponseDto";
import { RevenueData } from "../../types/Report/RevenueData";
import { OccupancyData } from "../../types/Report/OccupancyData";
import { ContractMetricsData } from "../../types/Report/ContractMetricData";
import { RetentionData } from "../../types/Report/RetentionData";


export const transformRevenueData = (jsonData: any): RevenueData => {
    const maxRevenue = jsonData.reportItems[0].metrics
      .find((m: any) => m.label === "LABEL_KEYMETRIC_MAXIMUMACHIEVEABLEREVENUE")
      .values.map(Number);
      
    const actualRevenue = jsonData.reportItems[0].metrics
      .find((m: any) => m.label === "LABEL_KEYMETRIC_ACTUALREVENUE")
      .values.map(Number);
  
    return {
      maxRevenue,
      actualRevenue,
      periods: jsonData.periods
    };
  };

  export const transformOccupancyData = (data: ReportResponseDto, type : string): OccupancyData => {
    const reportItem = data.reportItems[0];
    
    // Find metrics
    const availableMetric = reportItem.metrics.find(
      m => m.label === (type === 'office' ? 'LABEL_KEYMETRIC_TOTAL' : 'LABEL_KEYMETRIC_TOTALWORKSTATION')
    );
    const occupiedMetric = reportItem.metrics.find(
      m => m.label === (type === 'office' ? 'LABEL_KEYMETRIC_TOTALOCCUPIED' : 'LABEL_KEYMETRIC_TOTALOCCUPIEDWORKSTATION')
    );
  
    if (!availableMetric || !occupiedMetric) {
      throw new Error('Required metrics not found in data');
    }
  
    // Calculate occupancy percentages
    const occupancyPercentage = availableMetric.values.map((available, index) => {
      const availableNum = Number(available);
      const occupiedNum = Number(occupiedMetric.values[index]);
      return availableNum > 0 ? (occupiedNum / availableNum) * 100 : 0;
    });
  
    // Calculate trend line using simple moving average
    const trendData = occupancyPercentage.map((_, index) => {
      const windowSize = 3; // 3-month moving average
      const start = Math.max(0, index - windowSize + 1);
      const window = occupancyPercentage.slice(start, index + 1);
      const average = window.reduce((sum, val) => sum + val, 0) / window.length;
      return Number(average.toFixed(2));
    });
  
    return {
      periods: data.periods,
      occupancyPercentage: occupancyPercentage.map(val => Number(val.toFixed(2))),
      trendData,
    };
  };


  export const transformContractMetricsData = (data: ReportResponseDto): ContractMetricsData => {
    const reportItem = data.reportItems[0];
    
    const contractValueMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_AVERAGECONTRACTVALUE'
    );
    const contractDurationMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_AVERAGECONTRACTDURATION'
    );
    const workstationsMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_AVERAGEWORKSTATIONS'
    );
  
    if (!contractValueMetric || !contractDurationMetric || !workstationsMetric) {
      throw new Error('Required metrics not found in data');
    }
  
    return {
      periods: data.periods,
      contractValue: contractValueMetric.values,
      contractDuration: contractDurationMetric.values,
      workstations: workstationsMetric.values
    };
  };

  export const transformRetentionData = (data: ReportResponseDto): RetentionData => {
    const reportItem = data.reportItems[0];
    
    const startingMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_RETENTIONSTARTING'
    );
    const endingMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_RETENTIONENDING'
    );
    const renewedMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_RETENTIONRENEWED'
    );
    const churnedMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_RETENTIONCHURNED'
    );
    const undecidedMetric = reportItem.metrics.find(
      m => m.label === 'LABEL_KEYMETRIC_RETENTIONUNDECIDED'
    );
  
    if (!startingMetric || !endingMetric || !renewedMetric || !churnedMetric || !undecidedMetric) {
      throw new Error('Required metrics not found in data');
    }
  
    return {
      periods: data.periods,
      starting: startingMetric.values,
      ending: endingMetric.values,
      renewed: renewedMetric.values,
      churned: churnedMetric.values,
      undecided: undecidedMetric.values
    };
  };