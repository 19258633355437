import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { Alert, Grid, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { useDeleteInventory, useFetchInventorySummary, useManageInventory } from "../../hooks/Inventory/inventory";
import i18n from "../../i18n";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { Input } from "../Common/formComponents/Input";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { CustomButton } from "../Common/UI/CustomButton";
import { CustomConfirmDialog } from "../Common/UI/CustomConfirmDialog";
import { showSnackbar } from "../Common/UI/CustomSnackbar";
import { InventoryAllocation } from "./InventoryAllocation";
import { InventoryUnavailabilities } from "./InventoryUnavailabilities";

type Args = {
    isEdit: boolean,
    inventory: InventoryDto,
    setDirty: () => void,
    location: LocationResponseDto,
    handleDirtyAction: (cb: () => void) => void
    unsetDirty: () => void
};

const InventoryForm = ({ inventory, isEdit, setDirty, location, handleDirtyAction, unsetDirty }: Args) => {
    const nav = useNavigate();
    const { id, inventoryId } = useParams();
    const [inventoryState, setInventoryState] = useState(inventory);
    const [dialogConfirmation, setDialogConfirmation] = useState<boolean>(false);
    const [dialogName, setDialogName] = useState<string>('');
    const [dialogId, setDialogId] = useState<string>('');
    const [dialogAction, setDialogAction] = useState<string>('');

    const { mutate: submitData, isLoading: submitting, } = useManageInventory(isEdit);
    const { mutate: deleteInventory } = useDeleteInventory();
    const { data: inventorySummaryData } = useFetchInventorySummary({
        id: id,
        options: {
            enabled: !!id && isEdit
        }
    })

    const handleSubmitData = (data: InventoryDto) => {
        submitData(data, {
            onError: (error) => {
                showSnackbar(error.response?.data, {
                    persist: true,
                    variant: 'error'
                });
            },
            onSuccess: (data) => {
                showSnackbar(i18n.t('COMMON_LABEL_SAVE_SUCCESS').toString());
                unsetDirty()
                if (!isEdit) {
                    nav(`/locations/${inventoryState.locationId}/inventories/${data.id}`)
                }
            }
        })
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmitData(inventoryState);
    }

    const handleAction = (type: "DELETE" | "DISABLE" | "ENABLE") => {
        const id = inventoryState.id!.toString();
        const name = inventoryState.name;
        setDialogAction(type)
        setDialogName(name);
        setDialogId(id);
        setDialogConfirmation(true);
    }


    const confirmDialog = async (id: string) => {
        switch (dialogAction.toUpperCase()) {
            case "DELETE":
                deleteInventory(id, {
                    onSuccess: () => {
                        nav(`/locations/${inventory.locationId}/inventories`)
                    },
                    onError: (error) => {
                        showSnackbar(error.response?.data, {
                            persist: true,
                            variant: 'error'
                        });
                    }
                })
                break;
            case "DISABLE":
            case "ENABLE":
                setInventoryState({ ...inventoryState, isDisabled: !inventoryState.isDisabled })
                handleSubmitData({ ...inventoryState, isDisabled: !inventoryState.isDisabled })
                break;
        }
        setDialogConfirmation(false);
    }

    const generateUniqueCode = (value: string) => {
        if (inventoryState.externalReference === '') {
            const formattedCode = GenerateExternalReference(value);
            setInventoryState({ ...inventoryState, externalReference: formattedCode });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDirty();
        setInventoryState({ ...inventoryState, [e.target.name]: e.target.value });
    }

    const handleBackLinkCall = () => {
        let url = `/locations/${id}/inventories/`
        handleDirtyAction(() => nav(url))
    }

    const linkCallBackAction = (item: SummaryItemDto) => {
        const basePath = `/locations/${id}/inventories/${inventoryId}`
        const digitalType = item.uri.split("group=")[1]

        const path: Record<string, string> = {
            "SpaceDocsSummary": `${basePath}/digital-assets/${digitalType}`,
            "SpaceImagesSummary": `${basePath}/digital-assets/${digitalType}`,
            "SpaceDescriptionSummary": `${basePath}/description`,
        }

        const url = path[item.name]
        if (!url) {
            alert(`Item ${item.name} does not have a link yet`);
            return;
        }

        handleDirtyAction(() => nav(url))
    };

    return (
        <>
            <SectionHead
                linkCallBack={handleBackLinkCall}
                ctaText={i18n.t("LOCATION_LABEL_INVENTORIES")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(
                    isEdit ? 'INVENTORY_LABEL_EDITINVENTORY' : 'INVENTORY_LABEL_ADDINVENTORY'
                )}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}
            />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <form onSubmit={onFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("INVENTORY_LABEL_NAME").toString()}
                                    value={inventoryState.name || ""}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={e => generateUniqueCode(e.target.value)}
                                    required
                                    labelProps={{
                                        message: 'INVENTORY_LABEL_NAME',
                                        mandatory: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("INVENTORY_LABEL_AREASQM").toString()}
                                    value={inventoryState.areaInSqm || ""}
                                    name="areaInSqm"
                                    onChange={handleChange}
                                    required
                                    labelProps={{
                                        message: 'INVENTORY_LABEL_AREASQM',
                                        mandatory: true,
                                    }}
                                    inputProps={{
                                        type: "number",
                                        min: 0,
                                        max: 100000,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("INVENTORY_LABEL_DESCRIPTION").toString()}
                                    value={inventoryState.shortDescription || ""}
                                    name="shortDescription"
                                    onChange={handleChange}
                                    labelProps={{
                                        message: 'INVENTORY_LABEL_DESCRIPTION',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("INVENTORY_LABEL_INTERNALIDENTIFIER").toString()}
                                    value={inventoryState.internalIdentifier || ""}
                                    name="internalIdentifier"
                                    onChange={handleChange}
                                    labelProps={{
                                        message: 'INVENTORY_LABEL_INTERNALIDENTIFIER',
                                    }}
                                />
                            </Grid>
                            {
                                isEdit &&
                                <>
                                    <Grid item xs={12}>
                                        <InventoryAllocation handleDirtyAction={handleDirtyAction} items={inventoryState.allocations!}></InventoryAllocation>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InventoryUnavailabilities handleDirtyAction={handleDirtyAction} items={inventoryState.unavailability!}></InventoryUnavailabilities>
                                        {
                                            inventorySummaryData?.length > 0 &&
                                            <SummaryItemCollection linkCallBack={linkCallBackAction} items={inventorySummaryData} />
                                        }
                                    </Grid>
                                </>
                            }

                            {
                                inventoryState.isDisabled &&
                                <Grid item xs={12}>
                                    <Alert variant="filled" severity="error">
                                        <LR localResource="INVENTORY_LABEL_INVENTORYDISABLED"></LR>
                                    </Alert>
                                </Grid>
                            }

                            <Grid container item xs={6} spacing={2} sx={{ mx: 'auto' }}>
                                <Grid item xs={12}>
                                    <CustomButton
                                        disabled={submitting || inventoryState.name.length < 2}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        loading={submitting}
                                    >
                                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                                    </CustomButton>
                                </Grid>
                                {isEdit && <>
                                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                        <Tooltip
                                            title={i18n.t(inventoryState.isDisabled ? `COMMON_BUTTON_ENABLE` : `COMMON_BUTTON_DISABLE`, { param0: inventoryState.name })}
                                        >
                                            <CustomButton
                                                onClick={(e) => handleAction(inventoryState.isDisabled ? "ENABLE" : "DISABLE")}
                                                sx={{ textTransform: 'none' }}
                                            >
                                                {!inventoryState.isDisabled && <VisibilityOffSharpIcon color={'warning'} />}
                                                {inventoryState.isDisabled && <VisibilityTwoToneIcon color={'success'} />}
                                                <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
                                                    <LR localResource={inventoryState.isDisabled ? `COMMON_BUTTON_ENABLE` : `COMMON_BUTTON_DISABLE`}></LR>
                                                </Typography>
                                            </CustomButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: inventoryState.name })}>
                                            <CustomButton onClick={(e) => handleAction("DELETE")} sx={{ textTransform: 'none' }}>
                                                <DeleteRoundedIcon color={'error'} />
                                                <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
                                                    <LR localResource="COMMON_BUTTON_DELETE"></LR>
                                                </Typography>
                                            </CustomButton>
                                        </Tooltip>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>

            <CustomConfirmDialog
                isOpen={dialogConfirmation}
                onClose={() => setDialogConfirmation(false)}
                title={i18n.t(`INVENTORY_TITLE_${dialogAction}`)}
                content={i18n.t(`INVENTORY_LABEL_${dialogAction}`, { name: dialogName })}
                confirmButtonColor={
                    dialogAction.toUpperCase() === 'DELETE' ? 'error' :
                        inventoryState.isDisabled ? 'success' : 'error'
                }
                confirmButtonTitle={i18n.t(`COMMON_BUTTON_${dialogAction}`)}
                handleConfirm={() => { confirmDialog(dialogId); }}
            ></CustomConfirmDialog>
        </>
    );
}


export { InventoryForm };

