import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddOrderAddendum, useDeleteOrderAddendum, useUpdateOrderAddendum } from '../../../hooks/Order/addendums';
import { colors } from "../../../main/Theme";
import { OrderAddendumType } from '../../../types/Order/BasketResponse';
import { LR } from '../../Common/Help/LR';
import { CondensedAccordion, CondensedAccordionDetails, CondensedAccordionSummary } from "../../Common/UI/CondensedAccordion";
import { CustomButton } from "../../Common/UI/CustomButton";
import { CustomDialog } from '../../Common/UI/CustomDialog';
import { Input } from '../../Common/formComponents/Input';
import { useOrderContext } from "./OrderProvider";

type OrderAddendumPayload = {
  text?: string,
  id?: string
};

export const Addendum = () => {
  const { getLatestBasketDetail, currentBasket, readOnlyMode } = useOrderContext();
  const { basketId } = useParams()
  const [expand, setExpand] = useState(false);

  const [orderAddendumState, setOrderAddendumState] = useState<OrderAddendumPayload | null>(null)

  const [showAddAddendumModal, setShowAddAddendumModal] = useState(false)

  const { mutate: addAddendum, isLoading: addingAddendum } = useAddOrderAddendum();
  const { mutate: updateAddendum, isLoading: updatingAddendum } = useUpdateOrderAddendum();
  const { mutate: deleteAddendum } = useDeleteOrderAddendum();

  const handleEditAddendum = (Addendum: OrderAddendumType) => {
    setOrderAddendumState({
      ...Addendum,
      id: Addendum.id
    })
    setShowAddAddendumModal(true)
  }

  const { orderAddendums } = currentBasket || {};

  const handleCloseAddAddendumModal = () => {
    setShowAddAddendumModal(false)
    setOrderAddendumState(null)
  }

  const handleTextChange = (e: any) => {
    setOrderAddendumState(prev => ({
      ...prev,
      text: e?.target?.value || ""
    }));
  }

  const handelSave = () => {
    if (!basketId || !orderAddendumState) return

    const { id, text } = orderAddendumState;

    const onSuccess = () => {
      getLatestBasketDetail();
      handleCloseAddAddendumModal();
    };

    if (id) {
      updateAddendum({ basketId, id, text: text ?? '' }, { onSuccess });
    } else {
      addAddendum({ basketId, text: text ?? '' }, { onSuccess });
    }
  }

  const handleDeleteAddendum = (Addendum: OrderAddendumType) => {
    if (!basketId || !Addendum.id) return
    deleteAddendum({
      basketId,
      id: Addendum.id
    }, {
      onSuccess: () => {
        getLatestBasketDetail()
        setOrderAddendumState(null)
      }
    })
  }

  if (!basketId) return null;

  const handleAddAddendum = () => {
    if (!expand) {
      setExpand(true)
    }
    setShowAddAddendumModal(true)
  }

  const handleToggle = () => {
    if (!orderAddendums || orderAddendums.length <= 0) {
      setExpand(false)
    }

    if (orderAddendums && orderAddendums?.length > 0) {
      setExpand(!expand)
    }
  }

  if (!basketId) return null;

  return (
    <>
      <CondensedAccordion expanded={expand} elevation={2}>
        <CondensedAccordionSummary
          onClick={e => e.preventDefault()}
          expandIcon={<ArrowForwardIosRoundedIcon onClick={handleToggle} />}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              <LR localResource="ORDER_LABEL_ADDENDUMS" /> ({orderAddendums?.length || 0})
            </Grid>
            <Grid item xs={9} sx={{ textAlign: "right", pr: 4 }}>
              {!readOnlyMode && (
                <CustomButton color='secondary' onClick={handleAddAddendum} sx={{ textTransform: 'none' }}>
                  <AddCircleRoundedIcon fontSize="medium" />
                  <Box component={"span"} ml={1}><LR localResource="ORDER_LABEL_ADD_ADDENDUM" /></Box>
                </CustomButton>
              )}
            </Grid>
          </Grid>
        </CondensedAccordionSummary>
        <CondensedAccordionDetails sx={{ padding: 0 }}>
          {
            orderAddendums?.map((addendum, index) => (
              <Grid container spacing={0} sx={{ borderBottom: 2, borderColor: colors.grey[100], "&:last-child": { borderBottom: 0 } }} key={`addendum-${index}`}>
                <Grid item xs={3} display='flex' alignItems='center' padding={1} sx={{ backgroundColor: colors.grey[200] }}>
                  <LR localResource="LABEL_ADDENDUM_LINE" params={{ lineNumber: index + 1 }} />
                </Grid>
                <Grid item xs={7} display='flex' alignItems='center' padding={1} sx={{ backgroundColor: colors.grey[300] }}>
                  {addendum.text || '-'}
                </Grid>
                <Grid item xs={2} padding={1} sx={{ backgroundColor: colors.grey[300] }}>
                  {!readOnlyMode && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton color='secondary' onClick={() => handleEditAddendum(addendum)}>
                        <EditIcon fontSize="medium" />
                      </IconButton>
                      <IconButton color='error' onClick={() => handleDeleteAddendum(addendum)}>
                        <DeleteIcon fontSize="medium" />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            ))
          }
        </CondensedAccordionDetails>
      </CondensedAccordion>
      <CustomDialog open={showAddAddendumModal} onClose={handleCloseAddAddendumModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          <LR localResource="ORDER_LABEL_ADD_ADDENDUM" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                labelProps={{
                  message: 'COMMON_LABEL_TEXT'
                }}
                value={orderAddendumState?.text ?? ""}
                onChange={handleTextChange}
              />
            </Grid>
            <Grid item xs={6} mx={"auto"}>
              <CustomButton
                variant="contained"
                type='button'
                disabled={!orderAddendumState}
                fullWidth
                onClick={() => handelSave()}
                loading={addingAddendum || updatingAddendum}
              >
                <LR localResource='COMMON_LABEL_SAVE' />
              </CustomButton>
            </Grid>
          </Grid>
        </DialogContent>
      </CustomDialog>
    </>
  );
}