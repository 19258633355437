import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { darken, styled } from '@mui/material/styles';
import { colors } from '../../../main/Theme';


export const CondensedAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  borderRadius: '0 !important',
  marginBottom: '2px',
  overflow: 'hidden',
  border: 'none',
  '&::before': {
    display: 'none',
  },
}));

export const CondensedAccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: colors.grey[100],
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper ': {
    color: colors.primary,
    position: 'absolute',
    right: 10,
    transform: 'rotate(0)',
    '&.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
  },

}));

export const CondensedAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: 'none',
  backgroundColor: darken(colors.grey[300], 0.1),
}));
