import axios, { AxiosError } from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { OrderSearchResponseDto } from "../../types/Order/OrderSearchResponseDto";
import { OrderSearchV2ResponseDto } from "../../types/Order/OrderSearchV2ResponseDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";

const orders = require('../../data/order/order.json') as OrderSearchResponseDto[];

const useFetchOrders = (
  locationId: string,
  searchRequirement: string,
  pageNumber: number,
  pageSize: number,
  fromDate: string,
  toDate: string
) => {
  return useQuery<OrderSearchV2ResponseDto, AxiosError>(
    ['orders', locationId, searchRequirement, pageNumber, pageSize, fromDate, toDate],
    () =>
      axios
        .post(
          `${HookConfig.orderUrl}/search/orders`,
          {
            locationId: locationId,
            searchRequirement: searchRequirement,
            pageNumber: pageNumber,
            pageSize: pageSize,
            fromDate: fromDate,
            toDate: toDate,
          },
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        )
        .then((response) => response.data),
    {
      enabled: !!locationId,
    }
  );
};

const useFetchOrdersByMemberId = (memberId: string) => {
  return useQuery(['orders', memberId], async () => {
    return orders.filter(order => order.memberId === memberId);
  });
}

const useFetchOrderById = (id: string) => {
  return useQuery(['order', id], async () => {
    const order = orders.find(order => order.id === id);
    if (!order) throw new Error('Order not found');
    return order;
  });
}

// Create a new order
const useCreateOrder = (callback: (orderId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: OrderSearchResponseDto }, AxiosError, OrderSearchResponseDto>(
    (newOrder) => {
      orders.push(newOrder);
      return Promise.resolve({ data: newOrder });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["orders-summary"]);
        callback(response.data.id);
      }
    }
  );
}

// Update an existing order
const useUpdateOrder = (orderId: string, callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: OrderSearchResponseDto }, AxiosError, OrderSearchResponseDto>(
    (updatedOrder) => {
      const index = orders.findIndex(order => order.id === orderId);
      if (index !== -1) {
        orders[index] = updatedOrder;
      }
      return Promise.resolve({ data: updatedOrder });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orders-summary", orderId]);
        callback();
      }
    }
  );
}

// Export the hooks
export {
  useFetchOrders,
  useFetchOrdersByMemberId,
  useFetchOrderById,
  useCreateOrder,
  useUpdateOrder
};
