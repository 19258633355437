import { Card, Dialog, dialogClasses, DialogProps, IconButton, Slide, styled } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { colors } from "../../../main/Theme";
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogArgs extends DialogProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode,
  hideCloseButton?: boolean
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const CustomDialogStyle = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    border: 'none',
    borderRadius: 10,
    backgroundColor: 'transparent'
  },
  [`& .${dialogClasses.container}`]: {
    backdropFilter: 'blur(5px)'
  },
}));

export const CustomDialog = ({ open, onClose, children, hideCloseButton, ...rest }: CustomDialogArgs) => {
  return (
    <CustomDialogStyle
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      {...rest}
    >
      {!hideCloseButton &&
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 2, top: 2 }} color="error">
          <CloseIcon />
        </IconButton>
      }
      <Card sx={{
        background: colors.grey[500],
        padding: 3
      }}>
        {children}
      </Card>
    </CustomDialogStyle>
  )
}