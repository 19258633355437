import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useFetchPaymentMethods } from "../../hooks/Order/orderConfiguration";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomDataGrid } from "../Common/UI/CustomDataGrid";
import { CustomButton } from '../Common/UI/CustomButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
type Args =
    {
        userLogged: ApplicationUser
    }

const PaymentMethods = ({ userLogged }: Args) => {
    const { data } = useFetchPaymentMethods();
    const nav = useNavigate()

    const columns: GridColDef[] = [
        {
            field: 'pspName',
            headerName: i18n.t('COMMON_LABEL_NAME').toString(),
            sortable: false,
            flex: 1
        },
        {
            field: 'isDefault',
            headerName: i18n.t('COMMON_LABEL_IS_DEFAULT_PAYMENT_METHOD').toString(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params: any) => params.row.isDefault ? <CheckIcon color='secondary' /> : <></>
        },
        {
            field: 'action',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1,
            align: "right",
            headerAlign: "right",
            renderCell: () => (
                <IconButton color="secondary">
                    <EditIcon></EditIcon>
                </IconButton>
            )
        }
    ];

    const rows = data?.records || [];
    const handleRowClick = (params: any) => {
        nav(`/system/payment-methods/${params.row.id}`)
    }

    return (
        <>
            <SectionHead linkCallBack={() => nav('/system/summary')}
                ctaText={i18n.t('COMMON_SUMMARY_SYSTEM')!}
                name={''}
                title={i18n.t('COMMON_LABEL_PAYMENT_METHODS')}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <CustomButton
                        color='secondary'
                        sx={{
                            textTransform: "none",
                            marginBottom: 2
                        }}
                        onClick={() => nav('/system/payment-methods/add')}
                    ><AddCircleIcon /> {i18n.t('COMMON_LABEL_ADD_PAYMENT_METHOD').toString()}</CustomButton>
                    <CustomDataGrid
                        rows={rows}
                        columns={columns}
                        onRowClick={handleRowClick}
                        slots={{
                            noRowsOverlay: () => <></>
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                    />
                </div>
            </div>
        </>
    );
};


export { PaymentMethods };

