import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Paper } from '@mui/material';
import { OccupancyData } from '../../../types/Report/OccupancyData';
import { transformOccupancyData } from '../../../hooks/Report/DataTransformation';
import { useGetReports } from '../../../hooks/Report/ReportHook';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface OccupancyChartProps {
  userLogged: ApplicationUser;
  type:string;
  title?: string;
  width?: string | number;
  height?: string | number;
}

export const OccupancyChart: React.FC<OccupancyChartProps> = ({
  userLogged,
  type,
  title = 'Office Occupancy',
  width = '100%',
  height = 400,
}) => {
  const { getReports, data, isLoading, error } = useGetReports();
  const [chartData, setChartData] = useState<OccupancyData | null>(null);

  useEffect(() => {
    const fetchMyReport = async () => {
      await getReports({
        operatorMemberIds: [userLogged.location?.operatorMemberId!],
        locationIds: [userLogged.location?.id!],
        metricGroupName: (type === "office" ? "Occupancy" : "WorkstationOccupancy"),
        productTypeName: "Office",
        fromDate: '2024-07-25T16:05:50.095Z',
        toDate: '2025-06-25T16:05:50.095Z'
      });
    };

    fetchMyReport();
  }, []);

  useEffect(() => {
    if (data) {
      const transformedData = transformOccupancyData(data, type);
      setChartData(transformedData);
    }
  }, [data]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!chartData) return <div>No data available</div>;

  const chartDataConfig: ChartData<'line'> = {
    labels: chartData.periods,
    datasets: [
      {
        label: 'Occupancy',
        data: chartData.occupancyPercentage,
        borderColor: type === 'office' ? 'rgb(65, 105, 225)' : 'rgb(182, 133, 49)',
        backgroundColor: type === 'office' ? 'rgba(65, 105, 225, 0.1)' : 'rgb(182, 133, 49, 0.1)',
        pointBackgroundColor: type === 'office' ? 'rgb(65, 105, 225)': 'rgb(182, 133, 49)'  ,
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
      {
        label: 'Trend',
        data: chartData.trendData,
        borderColor: 'rgba(65, 105, 225, 0.5)',
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          padding: 20,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        text: title,
        font: {
          size: 16,
        },
        padding: {
          bottom: 20,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y.toFixed(1)}%`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        // x axis settings if needed
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }} className='chart-paper'>
      <h4>{title}</h4>
      <Box sx={{ width, height }}>
        <Line options={options} data={chartDataConfig} />
      </Box>
    </Paper>
  );
};