import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { HookConfig } from "../../config/HookConfig";
import { LocationRequestDto } from "../../types/Location/LocationRequestDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { LocationOperationalDetailGetResponseDto, LocationOperationalDetailUpdateRequestDto, openingTimeGroupResponseDto } from "../../types/Location/LocationOperationalDetailGetResponseDto";
import { ProductTypeDto } from "../../types/Product/ProductTypeDto";


const useFetchLocations = (memberId: string) => {
  return useQuery<LocationResponseDto[], AxiosError>(["locations", memberId], () =>
    axios.get(`${HookConfig.locationUrl}?memberId=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchLocationSummaryById = (id: string, summaryName: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["location-summary", id, summaryName], () =>
    axios.get(
      (summaryName ?
        `${HookConfig.locationUrl}/${id}/${summaryName}/summary` :
        `${HookConfig.locationUrl}/${id}/summary`),
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

const useFetchLocationById = (id?: string, options?: UseQueryOptions<LocationResponseDto, AxiosError>) => {
  return useQuery<LocationResponseDto, AxiosError>(["location", id], () =>
    axios.get(`${HookConfig.locationUrl}/${id}`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((response) => {
        var location: LocationResponseDto = response.data;
        return location;
      }), {
    ...options
  });
};

const useAddLocation = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, LocationRequestDto>(
    (locationRequest) => axios.post(HookConfig.locationUrl, locationRequest,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, locationRequest) => {
        queryClient.invalidateQueries(["location", _.data.id]);
        nav(`/locations/${_.data.id}/locationdetails`);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useUpdateLocation = (callback: () => void) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, LocationResponseDto>(
    (locationResponse) => axios.put(`${HookConfig.locationUrl}/${locationResponse.id}`, locationResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, location) => {
        queryClient.invalidateQueries(["location", location.id]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useUpdateLocationDescription = (callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, LocationResponseDto>(
    (location) => axios.put(`${HookConfig.locationUrl}/${location.id}/descriptions/long`,
      {
        id: location.id,
        descriptivefieldname: 'long',
        description: location.longDescription
      },
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, membership) => {
        queryClient.invalidateQueries(["location", membership.id]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


export const useFetchOperationalDetails = (id: string) => {
  return useQuery<LocationOperationalDetailGetResponseDto, AxiosError>(["operational-details", id], () =>
    axios.get(`${HookConfig.locationUrl}/${id}/operational-detail`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useUpdateOperationalDetails = (id: string) => {
  return useMutation<AxiosResponse, AxiosError, LocationOperationalDetailUpdateRequestDto>(
    (operationalDetail) => axios.put(`${HookConfig.locationUrl}/${id}/operational-detail`, operationalDetail,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, location) => {
        console.log(location);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
}


export const useFetchOperatorOpeningTimeGroups = (operatorMemberId: string) => {
  return useQuery<openingTimeGroupResponseDto[], AxiosError>(["opening-time-groups", operatorMemberId], () =>
    axios.get(`${HookConfig.locationBaseUrl}/operators/${operatorMemberId}/opening-time-groups`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchProductTypesByLocation = (locationId: string, options?: UseQueryOptions<ProductTypeDto[], AxiosError>) => {
  return useQuery<ProductTypeDto[], AxiosError>(["product-types", locationId], () =>
    axios.get(`${HookConfig.locationUrl}/${locationId}/product-types`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data),
    {
      ...options
    }
  );
}

export const updateOperationalDetails = (id: string) => { }
export const usePortfolioMemberId = (operatormemberid: string) => { }
export const useOperatorOpeningTime = (operatormemberid: string) => { }

export {
  useAddLocation,
  useFetchLocationById,
  useFetchLocationSummaryById,
  useUpdateLocation,
  useFetchLocations,
  useUpdateLocationDescription,
  useFetchProductTypesByLocation
}



