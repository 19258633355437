import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { pontConstants } from "../../data/pontConstants";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { FiscalDetailBody, FiscalDetailDto } from "../../types/Member/FiscalDetailDto";
import Problem from "../../types/Problem";



const useManageMemberFiscalDetails = () => {
  const queryClient = useQueryClient();
  const manageFiscalDetails = async (
    [id, items]: [string, FiscalDetailDto[]]
  ): Promise<AxiosResponse<any, any>[]> => {
    const promises = items
      .filter(item => {
        if (item.value.trim() === '' && item.id === pontConstants.emptyGuid) {
          return false
        }
        return true
      })
      .map(async (item) => {
        // We will assume that the user is creating a new item
        let method: Method = "post";

        if (item.value === "" && item.id !== pontConstants.emptyGuid) {
          // If user removes value from field we will delete
          method = "delete";
        }

        if (item.value !== "" && item.id !== pontConstants.emptyGuid) {
          // If value is not empty will update
          method = "put";
        }

        const url = {
          "post": `${HookConfig.memberUrl}/${id}/fiscal-details`,
          "delete": `${HookConfig.memberUrl}/${id}/fiscal-details/${item.id}`,
          "put": `${HookConfig.memberUrl}/${id}/fiscal-details/${item.id}`,
        }

        let body: FiscalDetailBody | undefined = {
          value: item.value,
          memberId: id,
          fiscalDetailTypeName: item.fiscalDetailTypeName,
          countryIso3Code: item.countryIso3Code
        }

        if (method === "put") {
          body.id = item.id
        }

        let response: AxiosResponse<any, any>;

        if (method === "delete") {
          // Delete don't need body
          response = await axios[method](
            url[method],
            {
              withCredentials: true,
              headers: operatorDefaultHeaders(),
            }
          );
        } else {
          response = await axios[method](
            url[method], body,
            {
              withCredentials: true,
              headers: operatorDefaultHeaders(),
            }
          );
        }

        return response;
      });
    const responses = await Promise.all(promises);
    return responses as any;
  };

  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [string, FiscalDetailDto[]]>(manageFiscalDetails, {
      onSuccess: (responses, [id, comunicationType]) => {
        queryClient.invalidateQueries(["member-fiscaldetails", id]);
      },
    });

  return mutation;
};

const useFetchMemberFiscalDetails = (id: string, country: string) => {
  return useQuery<FiscalDetailDto[], AxiosError>(["member-fiscaldetails", id], () =>
    axios.get(`${HookConfig.memberUrl}/${id}/fiscal-details?countryIso3Code=${country}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

export {
  useFetchMemberFiscalDetails, useManageMemberFiscalDetails
};

