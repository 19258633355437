import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { LR } from "../Common/Help/LR";
import { CustomButton } from '../Common/UI/CustomButton';
import CustomerFeed from './CustomerFeed';

type Args = {
  userLogged: ApplicationUser;
  headers?: string[];
  handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
  compact?: boolean;
};

const Customers = ({ userLogged, headers, handleArrowClick, compact }: Args) => {
  const navigate = useNavigate();
  const handleAddNewCustomer = () => {
    navigate('/customers/add');
  }

  const _headers = [
    'Account Reference',
    'First Name',
    'Last Name',
    'Company',
    'Relationship',
    'Type',
  ]

  return (
    <div className='mainpage-container-layout'>
      <div className='col-md-10 col-11 justify-content-center'>
        <Box sx={{ mb: 2 }}>
          <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWCUSTOMER')}>
            <CustomButton onClick={handleAddNewCustomer} color='secondary' sx={{ textTransform: 'none' }}>
              <AddCircleRoundedIcon />
              <Box sx={{ ml: 1 }}>
                <LR localResource="ACTIVITY_LABEL_NEWCUSTOMER" />
              </Box>
            </CustomButton>
          </Tooltip>
        </Box>

        <CustomerFeed
          userLogged={userLogged}
          headers={_headers}
          handleArrowClick={handleArrowClick}
          compact={compact}
          searchPlaceHolder='SEARCH_BY_NAME_OR_REFERENCE'
        />
      </div>
    </div>
  );
};

export { Customers };

