import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { OrderSearchV2ItemResponseDto } from '../../../types/Order/OrderSearchV2ItemResponseDto';
import DataRow from '../../Common/DataRow/DataRow';
import { IconDisplayer } from '../../Common/Help/IconDisplayer';
import './OrderTable.css';
import { useBasketCreate } from '../../../hooks/Order/basket';

type OrderTableProps = {
    headers: string[];
    orders: OrderSearchV2ItemResponseDto[];
    onActionClick: (order: OrderSearchV2ItemResponseDto) => void;
    userLogged: ApplicationUser;
};

const OrderTable: React.FC<OrderTableProps> = ({ headers, orders, onActionClick, userLogged }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [openOrderId, setOpenOrderId] = useState<string | null>(null);
    const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 768);

    const { mutate: createBasketFromOrder } = useBasketCreate();

    const toggleDetails = (orderId: string) => {
        setOpenOrderId(openOrderId === orderId ? null : orderId);
    };

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleViewClick = (order: OrderSearchV2ItemResponseDto) => {
        // navigate(`/orders/${orderId}/view-order`);
        if (!userLogged.location) return
        createBasketFromOrder({
            locationId: userLogged.location.id,
            memberId: order.memberId,
            orderId: order.orderId,
        }, {
            onSuccess: (basket) => {
                navigate(`/orders/basket/${basket.id}/view`)
            }
        })
    };

    const handleEditClick = (order: OrderSearchV2ItemResponseDto) => {
        if (!userLogged.location) return
        createBasketFromOrder({
            locationId: userLogged.location.id,
            memberId: order.memberId,
            orderId: order.orderId,
        }, {
            onSuccess: (basket) => {
                navigate(`/orders/basket/${basket.id}`)
            }
        })
    };

    const handleInvoiceClick = (orderId: string) => {
        navigate(`/orders/${orderId}/invoice`);
    };

    const handleDeleteClick = (orderId: string) => {
        navigate(`/orders/${orderId}/cancel`);
    };

    return (
        <div>
            <div className="order-header">
                {headers.map((header, idx) => (
                    <div key={idx} className="order-header-item">
                        {header}
                    </div>
                ))}
            </div>
            {orders.map((order, index) => {
                const isExpanded = openOrderId === order.orderId;

                const dataToShow = isMobileView && !isExpanded
                    ? [
                        { content: order.firstName },
                        { content: order.lastName || '' },
                    ]
                    : [
                        { content: order.orderExternalReference || `ORD-${order.orderId}` },
                        { content: `${order.firstName} ${order.lastName || ''}` },
                        { content: order.companyName || "" },
                        { content: new Date(order.fromDate || '').toLocaleString() },
                        { content: new Date(order.toDate || '').toLocaleString() },
                        { content: order.productTypeName || "" },
                        { content: order.inventoryName || "" },
                    ];

                return (
                    <DataRow
                        key={index}
                        data={dataToShow}
                        className='order-data-row-container'
                        actionIcon={
                            <div className='order-container-icons'>
                                <div className='order-container-highlight' onClick={() => handleViewClick(order)}>
                                    <IconDisplayer type={'COMMON_LABEL_VIEW'} />
                                </div>
                                <div className='order-container-highlight' onClick={() => handleEditClick(order)}>
                                    <IconDisplayer type={'COMMON_LABEL_EDITPEN'} />
                                </div>
                                <div className='order-container-highlight' onClick={() => handleInvoiceClick(order.orderId)}>
                                    <IconDisplayer type={'COMMON_LABEL_INVOICE'} />
                                </div>
                                <div className='order-container-delete' onClick={() => handleDeleteClick(order.orderId)}>
                                    <IconDisplayer type={'COMMON_LABEL_DELETE'} />
                                </div>
                            </div>
                        }
                        isExpanded={isExpanded}
                    // onActionClick={() => !isMobileView && onActionClick(order)}
                    />
                );
            })}
        </div>
    );
};

export default OrderTable;
