import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,  
  PointElement, 
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
 
} from 'chart.js';
import { Chart, Bar } from 'react-chartjs-2';
import { Box, Paper } from '@mui/material';
import { RevenueData } from '../../../types/Report/RevenueData';
import { transformRevenueData } from '../../../hooks/Report/DataTransformation';
import { useGetReports } from '../../../hooks/Report/ReportHook';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';

ChartJS.register(
   
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,  
  PointElement,  
  Title,
  Tooltip,
  Legend
);

interface RevenueChartProps {
  userLogged: ApplicationUser;
  title?: string;
  width?: string | number;
  height?: string | number;
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value);
};

type ChartDataset = {
  type: 'bar' | 'line';
  label: string;
  data: number[];
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  borderDash?: number[];
  stack?: string;
  pointRadius?: number;
  tension?: number;
  fill?: boolean;
  order?: number;
};

export const RevenueChart: React.FC<RevenueChartProps> = ({
  userLogged,
  title = 'Revenue Comparison',
  width = '100%',
  height = 400,
}) => {
  const { getReports, data, isLoading, error } = useGetReports();
  const [chartData, setChartData] = useState<RevenueData | null>(null);

  useEffect(() => {
    const fetchMyReport = async () => {
      await getReports({
        operatorMemberIds: [userLogged.location?.operatorMemberId!],
        locationIds: [userLogged.location?.id!],
        metricGroupName: "Revenue",
        productTypeName: "Office",
        fromDate: '2024-07-25T16:05:50.095Z',
        toDate: '2025-06-25T16:05:50.095Z'
      });
    };

    fetchMyReport();
  }, []);

  useEffect(() => {
    if (data) {
      const transformedData = transformRevenueData(data);
      setChartData(transformedData);
    }
  }, [data]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!chartData) return <div>No data available</div>;

  const chartDataConfig: ChartData<'bar' | 'line'> = {
    labels: chartData.periods,
    datasets: [
      {
        type: 'bar',
        label: 'Actual Revenue',
        data: chartData.actualRevenue,
        backgroundColor: 'rgba(85, 187, 151, 0.8)',
        borderWidth: 0,
        stack: 'Stack 0',
      },
      {
        type: 'bar',
        label: 'Potential Additional Revenue',
        data: chartData.maxRevenue.map((max, index) => max - chartData.actualRevenue[index]),
        backgroundColor: 'rgba(186, 188, 208, 0.8)',
        borderWidth: 0,
        stack: 'Stack 0',
      },
      {
        type: 'line',
        label: 'Break-Even Point',
        data: Array(chartData.periods.length).fill(40000),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 0,
        tension: 0,
        fill: false,
        order: 1
      }
    ] as ChartDataset[]
  };

  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
          padding: 20,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const value = context.raw as number;
            return `${context.dataset.label}: ${formatCurrency(value)}`;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          font: {
            size: 11,
          },
          padding: 8,
        },
        border: {
          display: true,
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: (context) => {
            if (context.tick.value === 80000) {
              return 'rgba(255, 99, 132, 0.2)';
            }
            return 'rgba(0, 0, 0, 0.1)';
          }
        },
        ticks: {
          font: {
            size: 11,
          },
          padding: 8,
          callback: function(value) {
            return formatCurrency(Number(value));
          },
        },
        border: {
          display: true,
        },
      },
    },
  };
  

  return (
    <Paper elevation={3} sx={{ p: 2 }} className='chart-paper'>
    <h4>{title}</h4>
    <Box sx={{ width, height }}>
      <Chart type='bar' options={options} data={chartDataConfig} />
    </Box>
  </Paper>
  );
};