import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

type OrderAddendumPayload = {
  basketId: string;
  text: string;
};

export const useAddOrderAddendum = () => {
  return useMutation<{ id: string }, AxiosError, OrderAddendumPayload>(
    (payload) =>
      axios.post(`${HookConfig.orderUrl}/baskets/${payload.basketId}/addendums`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};


type DeleteOrderAddendumPayload = {
  basketId: string;
  id: string;
};

export const useDeleteOrderAddendum = () => {
  return useMutation<{ id: string }, AxiosError, DeleteOrderAddendumPayload>(
    (payload) =>
      axios.delete(`${HookConfig.orderUrl}/baskets/${payload.basketId}/addendums/${payload.id}`, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};

type UpdateOrderAddendumPayload = {
  basketId: string;
  id: string;
  text: string;
};

export const useUpdateOrderAddendum = () => {
  return useMutation<{ id: string }, AxiosError, UpdateOrderAddendumPayload>(
    (payload) =>
      axios.put(`${HookConfig.orderUrl}/baskets/${payload.basketId}/addendums/${payload.id}`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};