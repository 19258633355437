import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Box, Checkbox, Grid, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { pontConstants } from "../../data/pontConstants";
import { useCheckEmailAlreadyRegistered, useCreateContact } from "../../hooks/Customer/useCustomer";
import { useRegisterDelegatedEmailV2 } from "../../hooks/Login/login";
import { useFetchMemberDomain } from "../../hooks/Member/domains";
import { useFetchMemberById } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { colors } from "../../main/Theme";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { ContactCreateRequestDto } from "../../types/Customer/CustomerDto";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import CustomCountryDropDown from "../Common/formComponents/CustomCountryDropDown";
import { Input } from "../Common/formComponents/Input";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import { DialogCompanySelector } from "./DialogCompanySelector";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";
type Args = {
    userLogged: ApplicationUser
}

type UpdatedContactCreateRequestDto = {
    companyName: string
} & ContactCreateRequestDto

const emailRegex = pontConstants.emailRegex;

const ContactForm = ({ userLogged }: Args) => {
    const { parentId } = useParams()
    const [openCompanySelectorModal, setOpenCompanySelectorModal] = useState(false);
    const [isEmailExist, setIsEmailExist] = useState(false);
    const [registeredUserId, setRegisteredUserId] = useState<null | string>(null);
    const [isDomainAllowed, setIsDomainAllowed] = useState(true);
    const [isLinkedToSameCompany, setIsLinkedToSameCompany] = useState(false);
    const [isLinkedToOtherCompany, setIsLinkedToOtherCompany] = useState(false);
    const [disableCompanySelector, setDisableCompanySelector] = useState(false);
    const [snackbarContent, setSnackbarContent] = useState<any>();

    useFetchMemberById(parentId, {
        enabled: !!parentId,
        onSuccess: (data) => {
            setValue('companyId', data.id);
            setValue('companyName', data.fullName);
            setDisableCompanySelector(true)
        }
    });

    const [localEmail, setLocalEmail] = useState('');

    const schema = yup.object({
        companyName: yup.string().required(i18n.t('VALIDATION_COMPANYNAME_REQUIRED').toString()),
        companyId: yup.string().required(),
        firstName: yup.string().required(i18n.t('VALIDATION_FIRSTNAME_REQUIRED').toString()),
        lastName: yup.string().required(i18n.t('VALIDATION_LASTNAME_REQUIRED').toString()),
        email: yup
            .string()
            .required(i18n.t('VALIDATION_EMAIL_REQUIRED').toString())
            .matches(emailRegex, i18n.t('VALIDATION_EMAIL_INVALID').toString()),
        countryIso3Code: yup.string().required(i18n.t('VALIDATION_COUNTRY_REQUIRED').toString()),
        linkToCompany: yup.boolean().required(),
        languageCode: yup.string().nullable(),
        allowDomain: yup.boolean().required(),
    })

    type FormType = yup.InferType<typeof schema>

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        clearErrors
    } = useForm<FormType>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        defaultValues: {
            companyId: '',
            email: '',
            linkToCompany: false,
            allowDomain: false,
            companyName: '',
            countryIso3Code: '',
            languageCode: i18n.language,
            firstName: '',
            lastName: '',
        }
    })

    const email = watch('email')
    const companyId = watch('companyId')

    const {
        refetch: checkEmail,
        isLoading: checkingEmail,
    } = useCheckEmailAlreadyRegistered({ email }, {
        staleTime: 0,
        ...pontConstants.disableQuerySettings,
        onSuccess: (response) => {
            setIsEmailExist(true);
            setRegisteredUserId(response[0].memberId);
            setLocalEmail(email)

            let isLinkedToOtherCompany = false;
            let isLinkedToSameCompany = false;

            if (response.length > 0) {
                isLinkedToOtherCompany = true
            }
            if (response.filter((item) => item.companyId === companyId).length > 0) {
                isLinkedToSameCompany = true
            }
            if (isLinkedToSameCompany) {
                isLinkedToOtherCompany = false
            }
            setIsLinkedToOtherCompany(isLinkedToOtherCompany);
            setIsLinkedToSameCompany(isLinkedToSameCompany);
        },
        onError: (error) => {
            if (error.status === 404) {
                setIsEmailExist(false);
                setRegisteredUserId(null);
                setIsLinkedToSameCompany(false);
                setIsLinkedToOtherCompany(false)
                setLocalEmail('')
            }
        }
    })

    const {
        isLoading: loadingDomain,
        refetch: refetchDomain,
    } = useFetchMemberDomain(companyId, { domain: email.split('@')[1] }, {
        staleTime: 0,
        ...pontConstants.disableQuerySettings,
        onSuccess: (response) => {
            setIsDomainAllowed(true);
        },
        onError: (error) => {
            if (error.status === 404) {
                setIsDomainAllowed(false);
            }
        }
    })

    const { mutate: registerDelegatedEmail, isLoading: registeringEmail } = useRegisterDelegatedEmailV2();

    const { mutate: createContact, isLoading: loadingCreateContact } = useCreateContact({ companyId: companyId });

    const nav = useNavigate();

    const homeCallBack = () => {
        nav(-1);
    }

    const handleCreateContact = (data: UpdatedContactCreateRequestDto) => {
        createContact({
            ...data,
            registeredUserId: data.registeredUserId,
            companyId: companyId
        }, {
            onError: (data) => {
                setSnackbarContent(data.response)
            },
        })

        if (parentId) {
            nav(`/companies/${parentId}/contacts`)
        } else {
            nav(`/customers`)
        }
    }
    const onSubmit: SubmitHandler<FormType> = (data) => {
        if (!isEmailExist) {
            registerDelegatedEmail({
                email: data.email!,
                firstName: data.firstName!,
                lastName: data.lastName!,
                countryISOCode: data.countryIso3Code,
                confirmPassword: '',
                password: '',
                authorUserId: userLogged.id!
            }, {
                onSuccess: (response) => {
                    handleCreateContact({
                        ...data,
                        registeredUserId: response.data.id
                    })
                }
            })
        }

        if (isEmailExist && registeredUserId) {
            handleCreateContact({
                ...data,
                registeredUserId
            })
        }
    }

    const handleEmailOnBlur = () => {
        if (email) {
            refetchDomain();
            checkEmail();
        }
    }

    const handleCompanyConfirm = (selectedCompany: CustomerSearchItemResponseDto) => {
        setValue('companyId', selectedCompany.memberId);
        setValue('companyName', selectedCompany.companyName as string);
        setOpenCompanySelectorModal(false);
        clearErrors("companyId")
        clearErrors("companyName")

    }
    const handleCancel = () => {
        setOpenCompanySelectorModal(false);
    }


    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_CUSTOMERS')!}
                name={i18n.t('LABEL_ADDCUSTOMER_CONTACT_PAGE_DESCRIPTION')}
                title={i18n.t('LABEL_ADDCUSTOMER_CONTACT_PAGE_TITLE')}
                description={i18n.t('COMMON_SUMMARY_CUSTOMERMANAGEMENT')} />
            <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent} severity="error" />
            <DialogCompanySelector
                userLogged={userLogged}
                open={openCompanySelectorModal}
                handleConfirm={handleCompanyConfirm}
                handleCancel={handleCancel}
                headers={[
                    'Account Reference',
                    'Company',
                ]}
            ></DialogCompanySelector>
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="companyName"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Box position="relative">
                                            <Input
                                                placeholder={i18n.t("LABEL_ADDCUSTOMER_COMPANYNAME").toString()}
                                                labelProps={{ message: 'LABEL_ADDCUSTOMER_COMPANYNAME', mandatory: true }}
                                                sx={{ input: { paddingRight: '3rem !important' } }}
                                                validationError={fieldState.error}
                                                readOnly
                                                value={field.value}
                                                onClick={() => setOpenCompanySelectorModal(true)}
                                                disabled={disableCompanySelector}
                                                rightIcon={
                                                    <IconButton
                                                        type="button"
                                                        onClick={() => setOpenCompanySelectorModal(true)}
                                                        disabled={disableCompanySelector}
                                                    ><SearchIcon />
                                                    </IconButton>
                                                }
                                            ></Input>

                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                                            labelProps={{ message: 'COMMON_LABEL_FIRSTNAME', mandatory: true }}
                                            validationError={fieldState.error}
                                            disabled={!companyId}
                                            field={field}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                                            labelProps={{ message: 'COMMON_LABEL_LASTNAME', mandatory: true }}
                                            validationError={fieldState.error}
                                            field={field}
                                            disabled={!companyId}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Input
                                            placeholder={i18n.t("COMMON_LABEL_EMAIL").toString()}
                                            labelProps={{ message: 'COMMON_LABEL_EMAIL', mandatory: true }}
                                            validationError={fieldState.error}
                                            field={field}
                                            disabled={!companyId}
                                            onBlur={() => {
                                                handleEmailOnBlur();
                                                field.onBlur();
                                            }}
                                            loading={checkingEmail}
                                        ></Input>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="countryIso3Code"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <CustomCountryDropDown
                                            valueChanged={(value) => { field.onChange(value) }}
                                            inputCountry={field.value}
                                            labelProps={{
                                                message: 'COMMON_LABEL_COUNTRY',
                                                mandatory: true
                                            }}
                                            validationError={fieldState.error}
                                            disabled={!companyId}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack gap={2}>
                                    {
                                        isLinkedToSameCompany &&
                                        <Alert severity="error" >
                                            <LR localResource="EMAIL_IS_ALREADY_REGISTERED_TO_THIS_COMPANY" params={{ provider: localEmail }} />
                                        </Alert>
                                    }
                                    {
                                        !isLinkedToSameCompany && isLinkedToOtherCompany &&
                                        <Box>
                                            <Alert severity="error" sx={{
                                                borderRadius: '0.5rem 0.5rem 0 0',
                                            }}>
                                                {i18n.t('EMAIL_IS_ALREADY_REGISTERED_TO_ANOTHER_COMPANY').toString()}
                                            </Alert>
                                            <Box sx={{
                                                borderRadius: '0 0 0.5rem 0.5rem',
                                                backgroundColor: colors.grey[100],
                                                border: 'solid 2px ' + colors.red[100],
                                                borderTop: 'none',
                                                p: 1.5
                                            }}>
                                                <Controller
                                                    name="linkToCompany"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <label style={{ cursor: 'pointer' }}>
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={field.value}
                                                                onChange={field.onChange} />
                                                            {i18n.t('LABEL_LINK_TO_COMPANY').toString()}
                                                        </label>
                                                    )}
                                                />

                                            </Box>
                                        </Box>
                                    }
                                    {
                                        !isLinkedToSameCompany && !isDomainAllowed &&
                                        <Box>
                                            <Alert severity="error" sx={{
                                                borderRadius: '0.5rem 0.5rem 0 0',
                                            }}>
                                                {i18n.t('DOMAIN_NOT_RECOGNIZED_BY_COMPANY').toString()}
                                            </Alert>
                                            <Box sx={{
                                                borderRadius: '0 0 0.5rem 0.5rem',
                                                backgroundColor: colors.grey[100],
                                                border: 'solid 2px ' + colors.red[100],
                                                borderTop: 'none',
                                                p: 1.5
                                            }}>
                                                <Controller
                                                    name="allowDomain"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <label style={{ cursor: 'pointer' }}>
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={field.value}
                                                                onChange={field.onChange} />
                                                            {i18n.t('ACCEPT_EMAIL_ADDRESS').toString()}
                                                        </label>
                                                    )}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                                <CustomButton
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={isLinkedToSameCompany || loadingDomain || registeringEmail || checkingEmail}
                                    loading={loadingCreateContact}
                                >
                                    {i18n.t('COMMON_LABEL_SAVE')}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
}

export { ContactForm };

