import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { pontOrderStatus, pontOrderSubStatus } from '../../../data/order/OrderData';
import { pontConstants } from '../../../data/pontConstants';
import yup from '../../../functions/utils/yup';
import { useBasketOrderLineDelete, useGetBasketById, usePaymentsMethodByUser, usePlaceOrder, useUpdateBasketStatus, useUpdatePlaceOrder } from '../../../hooks/Order/basket';
import i18n from '../../../i18n';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { CustomerSearchItemResponseDto } from '../../../types/Customer/CustomerSearchItemResponseDto';
import { BasketResponse } from '../../../types/Order/BasketResponse';
import { OrderLineRequest } from '../../../types/Order/OrderLineRequest';
import { showSnackbar } from '../../Common/UI/CustomSnackbar';


// Define the type of the context value
interface OrderContextType {
  isBasketPage?: boolean;
  userLogged: ApplicationUser;
  control: Control<any>,
  customerOpen: boolean,
  handleCustomerConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
  handleCancelMemberSelector: () => void,
  setCustomerOpen: (customerOpen: boolean) => void,
  formattedOrderStatus: any,
  formattedOrderSubStatus: any,
  paymentMethods: any,
  isValid: boolean,
  setSelectedOrderLine: (selectedOrderLine: OrderLineRequest | undefined) => void,
  setOrderLineOpen: (orderLineOpen: boolean) => void,
  orderLines: OrderLineRequest[],
  handleEditOrderLine: (id: string) => void,
  deleteConfirmation: boolean,
  setDeleteConfirmation: (deleteConfirmation: boolean) => void,
  currentBasket: BasketResponse | null | undefined,
  orderLineEditMode: boolean,
  memberId: string,
  parentMemberId: string | undefined,
  selectedOrderLine: OrderLineRequest | undefined,
  getLatestBasketDetail: () => void,
  orderLineOpen: boolean,
  handleDeleteOrderLine: (id: string) => void,
  onOrderSubStatusChange: (value: any) => void,
  onOrderStatusChange: (value: any) => void,
  refetchBasket: () => Promise<QueryObserverResult<BasketResponse, AxiosError<unknown, any>>>,
  placeOrder: () => void,
  handleCreateNewOrderLine: () => void,
  isPlaceOrderLoading: boolean,
  isPlacingOrder: boolean,
  refetchingBasket: boolean,
  orderEditMode: boolean,
  readOnlyMode: boolean | undefined
}

// Create the context
const OrderContext = createContext<OrderContextType | undefined>(undefined);

// Provider component
interface OrderProviderProps {
  children: ReactNode;
  userLogged: ApplicationUser;
  isBasketPage?: boolean;
  readOnly?: boolean;
}

const getFormattedOrderSubStatus = (orderSubStatusName: string) => {
  const newFormattedOrderSubStatus = pontOrderSubStatus
    .filter((x) => x.orderStatus === orderSubStatusName)
    .map((item) => ({
      label: i18n.t(item.label),
      value: item.value,
      name: item.value,
    }));
  return newFormattedOrderSubStatus;
}

export const OrderProvider: FC<OrderProviderProps> = (props) => {
  const { children, userLogged, isBasketPage, readOnly } = props;
  const { basketId } = useParams()
  const nav = useNavigate()

  const readOnlyMode = Boolean(readOnly)

  const [customerOpen, setCustomerOpen] = useState(false);
  const [orderLineOpen, setOrderLineOpen] = useState(false);
  const [orderLineEditMode, setOrderLineEditMode] = useState(false);
  const [orderLines, setOrderLines] = useState<OrderLineRequest[]>([]);
  const [selectedOrderLine, setSelectedOrderLine] = useState<OrderLineRequest>();
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [currentBasket, setCurrentBasket] = useState<BasketResponse>();
  const [paymentMethods, setPaymentMethods] = useState<{ label: string, value: string }[]>();
  const [orderId, setOrderId] = useState<string | null>(null)

  const [formattedOrderSubStatus, setFormattedOrderSubStatus] = useState(
    getFormattedOrderSubStatus("Draft")
  );

  const orderEditMode = Boolean(currentBasket?.orderId)

  const formattedOrderStatus = useMemo(() => pontOrderStatus.map((item) => ({
    label: i18n.t(item.label),
    value: item.value,
    name: item.value,
  })), [pontOrderStatus]);

  const defaultOrderStatusName = {
    label: i18n.t("ORDER_LABEL_ORDERSTATUSDRAFT").toString(),
    value: "Draft",
    name: "Draft",
  }
  const defaultOrderSubStatusName = {
    label: i18n.t("ORDER_LABEL_ORDERSUBSTATUSDRAFT").toString(),
    value: "Draft",
    name: "Draft",
  }

  const { refetch: refetchBasket, isLoading: refetchingBasket } = useGetBasketById({
    basketId: basketId,
    options: {
      enabled: !!basketId,
      onSuccess: (basketDetail) => {
        setCurrentBasket(basketDetail);

        setValue("memberId", basketDetail.memberId);
        setValue("parentMemberId", basketDetail.parentMemberId ?? undefined);
        setValue("memberName", basketDetail.memberFullName)
        setValue("purchaseOrderNumber", basketDetail.purchaseOrderNumber ?? undefined)
        setValue("comments", basketDetail.comments ?? undefined)
        setOrderId(basketDetail.orderId)

        const _selectedOrderStatus = formattedOrderStatus.find((x) => x.value === basketDetail.orderStatusName);

        const orderSubStatusList = getFormattedOrderSubStatus(basketDetail.orderStatusName)
        setFormattedOrderSubStatus(orderSubStatusList)

        const _selectedOrderSubStatus = orderSubStatusList.find((x) => x.value === basketDetail.orderSubStatusName);

        if (_selectedOrderStatus) {
          setValue('orderStatusName', _selectedOrderStatus)
        }
        if (_selectedOrderSubStatus) {
          setValue('orderSubStatusName', _selectedOrderSubStatus)
        }

        if (basketDetail?.orderLines) {
          setOrderLines(basketDetail.orderLines);
        }
      }
    }
  })
  const { mutate: deleteOrderLine } = useBasketOrderLineDelete()
  const { mutate: updateBasketStatus } = useUpdateBasketStatus()
  const { mutateAsync: updateOrder, isLoading: isPlaceOrderLoading } = useUpdatePlaceOrder()
  const { mutateAsync: placeOrderMutation, isLoading: isPlacingOrder } = usePlaceOrder()


  const orderSchema = yup.object().shape({
    id: yup.string(),
    locationId: yup.string().required("LocationId is required"),
    memberId: yup.string().required("MemberId is required"),
    memberName: yup.string().optional(),
    locationName: yup.string().optional(),
    parentMemberId: yup.string().optional(),
    //invoiceMemberId: yup.string().required("InvoiceMemberId is required"),
    orderStatusName: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
        name: yup.string().required(),
      })
      .required("The Order Status is required"),
    orderSubStatusName: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
        name: yup.string().required(),
      })
      .required("The Order Sub Status is required"),
    purchaseOrderNumber: yup.string(),
    comments: yup.string(),
    paymentMethodName: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.string().optional(),
        name: yup.string().optional(),
      })
      .optional(),
  });

  const {
    control,
    formState: { isValid },
    watch,
    setValue,
    trigger,
    handleSubmit,
    getFieldState
  } = useForm({
    resolver: yupResolver(orderSchema),
    defaultValues: {
      id: basketId,
      locationId: userLogged.location?.id,
      locationName: userLogged.location?.name,
      orderSubStatusName: undefined,
    },
  });

  type OrderPayload = yup.InferType<typeof orderSchema>

  const orderStatusName = watch("orderStatusName");
  const orderSubStatusName = watch("orderSubStatusName");
  const memberId = watch("memberId");
  const parentMemberId = watch("parentMemberId");
  const paymentMethod = watch("paymentMethodName");

  usePaymentsMethodByUser({
    memberId: memberId,
    companyId: parentMemberId,
    options: {
      enabled: !!memberId,
      onSuccess(paymentMethods) {
        if (!paymentMethods?.length) return

        const formattedPaymentMethods = paymentMethods.map((item) => ({
          label: `${i18n.t(item.pspName)}${item.isShared ? ` (Shared)` : ''}`,
          value: item.pspName,
          ...item
        }));

        setPaymentMethods(formattedPaymentMethods);

        // Auto payment method selection
        let shouldAutoSelectPaymentMethod = !paymentMethod || !formattedPaymentMethods.some(x => x.pspName === paymentMethod?.name);

        if (shouldAutoSelectPaymentMethod) {
          setValue("paymentMethodName", formattedPaymentMethods.find((x) => x.isDefault) || formattedPaymentMethods[0])
        }
        trigger();
      },
    }
  })

  const getLatestBasketDetail = () => {
    if (!basketId) {
      throw new Error("BasketId is required")
    }
    refetchBasket()
  };

  const handleCustomerConfirm = async (selectedCustomer: CustomerSearchItemResponseDto) => {
    setValue("memberName", `${selectedCustomer.firstName} ${selectedCustomer.lastName}`);

    if (selectedCustomer.companyId) {
      setValue("parentMemberId", selectedCustomer.companyId);
    }

    setValue("memberId", selectedCustomer.memberId, { shouldValidate: true, });
    setCustomerOpen(false);
  };

  const handleCancelMemberSelector = () => {
    setCustomerOpen(false);
  };

  const handleEditOrderLine = (id: string) => {
    const selectedOrderLine = orderLines.find((x) => x.id === id);
    if (selectedOrderLine) {
      setOrderLineOpen(true);
      setOrderLineEditMode(true);
      setSelectedOrderLine(selectedOrderLine);
    }
  };

  const handleDeleteOrderLine = (id: string) => {
    deleteOrderLine({ params: { basketId: basketId!, orderLineId: id } }, {
      onSuccess: (response) => {
        setOrderLines((prevItems) => {
          return prevItems.filter((item) => item.id !== id);
        });
      },
      onError: (error) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      },
    });
    setDeleteConfirmation(false);
  };

  const handleBasketStatusChange = (_orderStatusName?: string, _orderSubStatusName?: string) => {
    // No status change
    if (_orderSubStatusName === orderSubStatusName?.value) return
    if (!_orderStatusName || !_orderSubStatusName || !basketId) return

    updateBasketStatus({
      basketId: basketId,
      orderStatusName: _orderStatusName,
      orderSubStatusName: _orderSubStatusName
    }, {
      onSuccess: () => {
        showSnackbar('Status updated successfully!')
      }
    })
  }

  const onOrderStatusChange = (status: any) => {
    if (!status) return

    const newFormattedOrderSubStatus = pontOrderSubStatus
      .filter((x) => x.orderStatus === status.value)
      .map((item) => ({
        label: i18n.t(item.label),
        value: item.value,
        name: item.value,
      }));

    setFormattedOrderSubStatus(newFormattedOrderSubStatus);

    if (newFormattedOrderSubStatus.length > 1) {
      // If there are more than one order sub status, we will set this as undefined
      setValue('orderSubStatusName', undefined as any)
    }

    if (newFormattedOrderSubStatus.length === 1) {
      setValue('orderSubStatusName', newFormattedOrderSubStatus[0])

      // If there is only one subs-status, we will change that on to the server
      handleBasketStatusChange(status.value, newFormattedOrderSubStatus[0].value)
    }
  }

  const onOrderSubStatusChange = (value: any) => {
    // We will update status on server via api, when user change sub-status
    handleBasketStatusChange(orderStatusName.value, value.value)
  }

  const submitOrder = async (data: OrderPayload) => {
    try {
      if (!basketId || !data.paymentMethodName?.value) {
        throw new Error("BasketId or payment method is required")
      }
      const commentFieldState = getFieldState('comments')
      const purchaseOrderNumberFieldState = getFieldState('purchaseOrderNumber')

      let hasError = false

      if (commentFieldState.isDirty || purchaseOrderNumberFieldState.isDirty) {
        await updateOrder({
          basketId: basketId,
          paymentMethodName: data.paymentMethodName?.value,
          purchaseOrderNumber: data.purchaseOrderNumber,
          comments: data.comments
        }, {
          onSuccess: () => {
            showSnackbar('Order placed successfully!')
          },
          onError: (error) => {
            showSnackbar(error.response?.data, {
              persist: true,
              variant: 'error'
            })
          }
        })
      }

      if (!orderId || orderId === pontConstants.emptyGuid) {
        await placeOrderMutation({
          basketId: basketId,
          trackingData: ""
        }, {
          onSuccess: (data) => {
            if (data.errors.length > 0) {
              hasError = true
            }
            data.errors.map((item, index) => {
              showSnackbar(`Line ${item.lineNumber} : ${item.errorLabel}`, {
                persist: true,
                variant: 'error'
              })
            })
          }
        })
      }
      if (!hasError) {
        nav(`/orders`)
      }
    } catch (error: any) {
      console.error(error)
      showSnackbar(error?.message, {
        persist: true,
        variant: 'error'
      })
    }
  }

  const placeOrder = handleSubmit(submitOrder)

  const handleCreateNewOrderLine = () => {
    setSelectedOrderLine(undefined);
    setOrderLineOpen(true);
    setOrderLineEditMode(false);
  }

  useEffect(() => {
    // set order status and order sub status first time when new order is being placed
    if (!isBasketPage) {
      setValue('orderStatusName', defaultOrderStatusName)
      setValue('orderSubStatusName', defaultOrderSubStatusName)
    }
  }, [])

  return (
    <OrderContext.Provider value={{
      control,
      currentBasket,
      customerOpen,
      deleteConfirmation,
      formattedOrderStatus,
      formattedOrderSubStatus,
      getLatestBasketDetail,
      handleCancelMemberSelector,
      handleCreateNewOrderLine,
      handleCustomerConfirm,
      handleDeleteOrderLine,
      handleEditOrderLine,
      isBasketPage,
      isPlaceOrderLoading,
      isPlacingOrder,
      isValid,
      memberId,
      onOrderStatusChange,
      onOrderSubStatusChange,
      orderEditMode,
      orderLineEditMode,
      orderLineOpen,
      orderLines,
      parentMemberId,
      paymentMethods,
      placeOrder,
      readOnlyMode,
      refetchBasket,
      refetchingBasket,
      selectedOrderLine,
      setCustomerOpen,
      setDeleteConfirmation,
      setOrderLineOpen,
      setSelectedOrderLine,
      userLogged,
    }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrderContext must be used within a OrderProvider');
  }
  return context;
};