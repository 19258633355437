import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useFetchMembershipById } from "../../hooks/Memberships/membership";
import { useFetchMembershipFeatureHierarchy, useManageMembershipFeatures } from "../../hooks/Memberships/membershipFeatures";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { FeaturesDto } from "../../types/Common/FeaturesDto";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import '../Common/Features/FeatureGroup.css';
import { FeaturesTreeview } from "../Common/Features/FeaturesTreeview";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";


type Args =
    {
        userLogged: ApplicationUser
    }

const MembershipFeatureGroup = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const [selectedFeatures, setSelectedFeatures] = useState<FeaturesDto[]>([]);
    const [description, setDescription] = useState<string>('');
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const queryStringParams = useParams();
    const { mutate } = useManageMembershipFeatures();
    if (!queryStringParams.id) throw Error("Membership id needed");
    const { data: membership, status: membershipStatus, isSuccess: membershipIsSuccess } = useFetchMembershipById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchMembershipFeatureHierarchy(queryStringParams.id!);


    const linkCallBack = () => {
        let url = `/memberships/${queryStringParams.id}/summary`;
        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        }
        else {
            nav(url);
        }
    }

    const callBack = (features: FeaturesDto[]) => {
        setSelectedFeatures(features);
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setShowConfirmation(false);
            unsetDirty();
            selectedFeatures.push({ description: description, value: data.value });

            mutate([queryStringParams.id!, queryStringParams.featuregroup!, selectedFeatures]);
            e.preventDefault();

        } catch (err: any) {
            console.error(err);
        }
    }

    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    useEffect(() => {
        if (data) {
            setDescription(data.description);
        }

    }, [data]);

    if ((!isSuccess || !membershipIsSuccess) && (status !== 'success') && (membershipStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !membership) return <ApiStatus status={status} />;

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("COMMON_SUMMARY_FEATURES")!}
                name={`${membership.name} (${membership.externalReference})`}
                title={i18n.t(data.label)}
                description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-10 justify-content-center">
                    <FeaturesTreeview handleDirty={handleDirty} items={data} callback={callBack}></FeaturesTreeview>
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-10 justify-content-center mt-1">
                    <h5>Description</h5>
                    <textarea rows={5} className="featureTextArea"
                        value={description}
                        placeholder={i18n.t("EVENT_LABEL_FEATUREDESCRIPTION", { featureName: data.label }).toString()}
                        onChange={(e) => { setDescription(e.target.value); handleDirty(); }}
                    >{description}</textarea>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={!isDirty}>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
        </form>
    );
};

export { MembershipFeatureGroup };
