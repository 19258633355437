import { Alert, Box, Grid, Switch } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { Controller } from "react-hook-form";

import i18n from "../../../../i18n";
import { CustomDatePicker } from "../../../Common/formComponents/CustomDatepicker";
import { CustomMultiInputTimeRangeField } from "../../../Common/formComponents/CustomMultiInputTimeRangeField";
import { CustomSelect } from "../../../Common/formComponents/CustomSelect";
import { Input } from "../../../Common/formComponents/Input";
import { CustomButton } from "../../../Common/UI/CustomButton";
import "../OrderForm.css";

import { SellableItemAvailability } from "../../../../types/Order/SellableItemAvailabilityResponse";
import { Loader } from "../../../Common/UI/Loader";
import { useOrderContext } from "../OrderProvider";
import { OrderLinePreview } from "./OrderLinePreview";
import { useOrderLineContext } from "./OrderLineProvider";

export const OrderLineForm = () => {

  const {
    availabilities,
    checkOrderLineAvailability,
    checkingAvailability,
    confirmOrderLine,
    control,
    creatingBasket,
    creatingOrderLine,
    currentOrderLine,
    fetchingProductTypes,
    fromDate,
    handleAvailabilityChange,
    handleChangeProduct,
    handleCustomOrderLine,
    handleFromDateChange,
    handleQuantityChange,
    isOrderLineCustom,
    isValid,
    orderLineDescription,
    orderLineError,
    productConfig,
    productTypes,
    setCurrentOrderLine,
    showCheckAvailability,
    showNoAvailabilityMessage,
    updatingOrderLine,
  } = useOrderLineContext();

  const { orderLineEditMode, readOnlyMode } = useOrderContext();

  const { requiresQuantity, requiresStartDate, requiresEndDate, requiresStartTime, requiresEndTime, showCheckAvailability: _showCheckAvailability } = productConfig || {};

  const showFromDate = requiresStartDate;
  const showToDate = requiresEndDate;
  const showTimeRange = requiresStartTime || requiresEndTime;
  const showQuantity = requiresQuantity;

  const showAvailabilityButton = !orderLineEditMode && !!productConfig && !!_showCheckAvailability

  if (fetchingProductTypes) return (
    <Box sx={{
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Loader message='Loading...'
      />
    </Box>
  )

  return (
    <>
      <form noValidate>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="product"
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    labelProps={{
                      message: "ORDER_LABEL_PRODUCT",
                      mandatory: true,
                    }}
                    options={productTypes}
                    placeholder={i18n.t("ORDER_LABEL_PRODUCT")}
                    field={field}
                    onChange={(e) => {
                      const value = e;
                      handleChangeProduct(value);
                      field.onChange(value);
                    }}
                    validationError={fieldState.error}
                    isDisabled={orderLineEditMode}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        {
          productConfig && (
            <Grid
              container
              spacing={2}
              alignItems="center"
              mb={2}
            >
              {showQuantity && (
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="quantity"
                    render={({ field, fieldState }) => (
                      <Input
                        type="number"
                        labelProps={{
                          message: i18n.t(productConfig.quantityLabelOverride!),
                        }}
                        placeholder={i18n
                          .t(productConfig.quantityLabelOverride!)
                          .toString()}
                        field={field}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                          handleQuantityChange(e.target.value)
                        }}
                        validationError={fieldState.error}
                        disabled={orderLineEditMode}
                      ></Input>
                    )}
                  />
                </Grid>
              )}
              {showFromDate && (
                <Grid item xs={4}>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field, fieldState, ...rest }) => (
                      <CustomDatePicker
                        value={dayjs(field.value)}
                        onChange={(e) => {
                          const _date = dayjs(e)
                          field.onChange(_date.toDate())
                          handleFromDateChange(_date)
                        }}
                        labelProps={{
                          message: i18n.t("ORDER_LABEL_FROM").toString(),
                          mandatory: productConfig?.requiresStartDate
                        }}
                        validationError={fieldState.error}
                        disabled={orderLineEditMode}
                        {...rest}
                      />
                    )}
                  />
                </Grid>
              )}
              {showToDate && (
                <Grid item xs={4}>
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field, fieldState, ...rest }) => (
                      <CustomDatePicker
                        labelProps={{
                          message: i18n.t("ORDER_LABEL_TO").toString(),
                          mandatory: productConfig?.requiresEndDate
                        }}
                        minDate={dayjs(fromDate)}
                        value={dayjs(field.value)}
                        onChange={(e) => field.onChange(dayjs(e).toDate())}
                        disabled={!fromDate || orderLineEditMode}
                        validationError={fieldState.error}
                        {...rest}
                      />
                    )}
                  />
                </Grid>
              )}
              {showTimeRange && (
                <Grid item xs={4}>
                  <Controller
                    name="timeRange"
                    control={control}
                    render={({ field, fieldState }) => {
                      // Ensure value is a tuple with exactly two elements
                      const timeRangeValue =
                        field.value && field.value.length === 2
                          ? field.value
                          : [null, null]; // Default to [null, null] if the value is invalid

                      return (
                        <CustomMultiInputTimeRangeField
                          value={
                            timeRangeValue as [
                              Dayjs | null,
                              Dayjs | null
                            ]
                          }
                          onChange={(newValue) => field.onChange((newValue && newValue.length === 2) ? newValue : [null, null])}
                          startLabel={{
                            message: i18n.t("ORDER_LABEL_TIMEFROM").toString(),
                            mandatory: productConfig?.requiresStartTime
                          }}
                          endLabel={{
                            message: i18n.t("ORDER_LABEL_TIMETO").toString(),
                            mandatory: productConfig?.requiresEndTime
                          }}
                          disabled={orderLineEditMode}
                          referenceDate={dayjs(fromDate)}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
              {showAvailabilityButton && (
                <Grid item container spacing={2}>
                  <Grid item xs={1} md={4} mx={"auto"}>
                    <CustomButton
                      variant="contained"
                      fullWidth
                      disabled={!showCheckAvailability || !isValid}
                      type="button"
                      onClick={checkOrderLineAvailability}
                      loading={checkingAvailability}
                    >
                      {i18n.t("ORDER_BUTTON_CHECKAVAILABILITY")}
                    </CustomButton>
                  </Grid>
                </Grid>
              )}

              {checkingAvailability && (
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "center", opacity: 0.5, fontStyle: "italic" }}>
                    {i18n.t("ORDER_LABEL_FETCHING_AVAILABILITIES")}
                  </Box>
                </Grid>
              )}

              {showNoAvailabilityMessage &&
                <Grid item xs={12}>
                  <Box sx={{ textAlign: "center", opacity: 0.5, fontStyle: "italic" }}>
                    {i18n.t("ORDER_LABEL_NO_AVAILABILITIES")}
                  </Box>
                </Grid>}
              {availabilities && availabilities.length > 0 && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="availability"
                    render={({ field, fieldState }) => {
                      return (
                        <CustomSelect
                          labelProps={{
                            message: "ORDER_LABEL_AVAILABLEITEM",
                            mandatory: true,
                          }}
                          options={availabilities}
                          placeholder={i18n.t("ORDER_LABEL_AVAILABLEITEM")}
                          field={field}
                          onChange={(e) => {
                            const value = e;
                            field.onChange(value);
                            handleAvailabilityChange(value as SellableItemAvailability)
                          }}
                          validationError={fieldState.error}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )
        }

        {currentOrderLine && (
          <>
            <Grid container sx={{ marginBottom: 2, }} alignContent={'center'}>
              <Grid item xs={8} sx={{ textAlign: 'left', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                <Box component={"span"}>{orderLineDescription}</Box>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                {i18n.t("LABEL_COMMON_CUSTOMIZEORDERLINE")} :
                <Switch
                  color="secondary"
                  checked={isOrderLineCustom}
                  onChange={handleCustomOrderLine}
                  disabled={readOnlyMode}
                />
              </Grid>
            </Grid>
            <OrderLinePreview></OrderLinePreview>

            {currentOrderLine.hasError && orderLineError && (
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: 2
                }}
              >
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <Alert severity="error" sx={{ width: '100%' }}>
                    {orderLineError}
                  </Alert>
                </Grid>

                {currentOrderLine.canIgnoreBusinessRuleValidation && (
                  <Grid item xs={4}>
                    <Switch
                      checked={
                        currentOrderLine.ignoreBusinessRuleValidation
                      }
                      onChange={() =>
                        setCurrentOrderLine({
                          ...currentOrderLine,
                          ignoreBusinessRuleValidation:
                            !currentOrderLine.ignoreBusinessRuleValidation,
                        })
                      }
                      color="secondary"
                    />{" "}
                    {i18n.t("ORDER_LABEL_OVERRIDEBUSINESSRULES")}
                  </Grid>
                )}
              </Grid>
            )}

            {!readOnlyMode && (
              <Grid container spacing={2}>
                <Grid item xs={1} md={4} mx={"auto"}>
                  <CustomButton
                    variant="contained"
                    fullWidth
                    disabled={
                      !isValid ||
                      (currentOrderLine.hasError &&
                        !currentOrderLine.ignoreBusinessRuleValidation)
                    }
                    type="button"
                    onClick={confirmOrderLine}
                    loading={creatingOrderLine || updatingOrderLine || creatingBasket}
                  >
                    {i18n.t("ORDER_BUTTON_CONFIRM")}
                  </CustomButton>
                </Grid>
              </Grid>
            )}

          </>
        )}
      </form>
    </>
  );
};