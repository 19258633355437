import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { OrderForm } from './OrderForm';
import './OrderForm.css';
import { OrderProvider } from './OrderProvider';

type NewOrderProps = {
  userLogged: ApplicationUser;
  onClick?: () => void;
  isBasketPage?: boolean;
  readOnly?: boolean
};

const NewOrder = ({ userLogged, isBasketPage, readOnly }: NewOrderProps) => {
  return (
    <OrderProvider userLogged={userLogged} isBasketPage={isBasketPage} readOnly={readOnly}>
      <OrderForm></OrderForm>
    </OrderProvider>
  )
}

export { NewOrder };

