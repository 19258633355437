import { Box, Grid } from '@mui/material';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  ActionEventArgs,
  EventRenderedArgs,
  EventSettingsModel,
  Inject,
  NavigatingEventArgs,
  PopupOpenEventArgs,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  SelectEventArgs,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective
} from '@syncfusion/ej2-react-schedule';
import { format, } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DateShim } from '../../functions/utils/helper';
import { getCalendarData } from '../../hooks/Calendar/calendar';
import i18n from '../../i18n';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CalendarSearchDetailResponseDto } from '../../types/Calendar/CalendarSearchDetailResponseDto';
import { CalendarSearchRequestDto } from '../../types/Calendar/CalendarSearchRequestDto';
import { CalendarSearchResponseDto } from '../../types/Calendar/CalendarSearchResponseDto';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { LR } from '../Common/Help/LR';
import { CustomConfirmDialogWithContent } from '../Common/UI/CustomConfirmDialogWithContent';
import './Calendar.css';
import { useNavigate } from 'react-router-dom';

registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bd0djX35ec3ZQR2Fd");

type CalendarFeedProps = {
  userLogged: ApplicationUser;
};

const resourceHeaderTemplate = (props: any) => {
  return (
    <div className="resource-header-template">
      <div className="resource-column">
        <div className="resource-room">{props.resourceData.text}</div>
      </div>
      <div className="resource-column">
        <div className="resource-type">{props.resourceData.type}</div>
      </div>
    </div>
  );
};

const headerTemplate = (props: { [key: string]: string }): JSX.Element => {
  return (
    <>
      {props.Subject &&
        <div className="quick-info-header calendar-popup-header">
          <div className="e-header-icon-wrapper">
            {/* <button className="e-edit e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Edit"><span className="e-btn-icon e-icons e-edit-icon"></span>
           </button> */}
          </div>
          <div className="quick-info-header-content">
            <div className="quick-info-title"><h3>{props.MemberName ? props.MemberName : props.Description}</h3></div>
            <div className="duration-text"><p>Start: <strong>{format(props.StartTime, 'dd/MM/yyyy HH:mm')}</strong>  End <strong>{format(props.EndTime, 'dd/MM/yyyy HH:mm')}</strong></p></div>
          </div>
        </div>
      }
    </>
  );
}

const contentTemplate = (props: { [key: string]: string }): JSX.Element => {
  return (
    <>
      <div className="quick-info-content calendar-popup-content">
        <div className="event-content">
          {
            props.ExternalReference &&
            <div className="meeting-subject-wrap">
              <span>Booking n° {props.ExternalReference}</span>
            </div>
          }
          {
            props.Reason &&
            <div className="meeting-subject-wrap">
              <span>{props.Reason}</span>
            </div>
          }
        </div>
      </div>
    </>
  );
}

const footerTemplate = (props: { [key: string]: Object }): JSX.Element => {
  return (
    <></>
  );
}

export const CalendarFeed = ({ userLogged }: CalendarFeedProps) => {
  const nav = useNavigate()
  const scheduleObj = useRef<ScheduleComponent>(null);
  const [selectedInventoryId, setSelectedInventoryId] = useState<string>('');
  const [events, setEvents] = useState<EventSettingsModel>({ dataSource: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date());
  const [calendarData, setCalendarData] = useState<CalendarSearchResponseDto[]>();
  const [ownerData, setOwnerData] = useState<any>([]);
  const [openOrderBookingDialog, setOpenOrderBookingDialog] = useState(false);
  const [orderBookingData, setOrderBookingData] = useState<{
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    sellableItemName: string,
    sellableItemId: string,
  } | null>(null);

  const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
    const firstDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
    return {
      from: firstDayOfMonth,
      to: lastDayOfMonth
    };
  };

  const handleInventoryChange = (value: string) => {
    setSelectedInventoryId(value);
  };

  const handleNavigating = (args: NavigatingEventArgs): void => {
  };

  const onActionComplete = (args: ActionEventArgs): void => {
    if (args.requestType === 'dateNavigate' && scheduleObj.current) {
      const newDate = scheduleObj.current.getCurrentViewDates()[0]; // Get the new date selected
      if (newDate.getTime() !== selectedDateFrom.getTime()) {
        setSelectedDateFrom(newDate); // Update the state with the new date
      }
    }
  };

  const onEventRendered = (args: EventRenderedArgs): void => {
    args.element.classList.add(args.data.CustomStyle);
  }

  const onActionBegin = (args: ActionEventArgs): void => {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      if (data) {
        args.cancel = !scheduleObj.current!.isSlotAvailable(data);
      }
    }
  };

  const inventoryOptions = calendarData ? [...calendarData] : [];
  inventoryOptions.unshift({ sellableItemName: "All Inventories", sellableItemId: "" } as any)

  const onSelect = (args: SelectEventArgs): void => {
    const { requestType, data } = args;
    if (requestType === "cellSelect" && data) {
      const { StartTime, EndTime, RoomId } = data as any
      const roomId = inventoryOptions.find(x => x.sellableItemId === RoomId)
      setOpenOrderBookingDialog(true);
      setOrderBookingData({
        startDate: dayjs(StartTime).format('DD/MM/YYYY'),
        endDate: dayjs(EndTime).format('DD/MM/YYYY'),
        startTime: dayjs(StartTime).format('HH:mm'),
        endTime: dayjs(EndTime).format('HH:mm'),
        sellableItemName: roomId?.sellableItemName || '',
        sellableItemId: roomId?.sellableItemId || ''
      });
    }
  }


  const onPopupOpen = (args: PopupOpenEventArgs): void => {
    args.cancel = true;
  };

  const quickInfoTemplates = {
    header: headerTemplate,
    content: contentTemplate,
    footer: footerTemplate
  };

  const cancelOrderBooking = () => {
    setOpenOrderBookingDialog(false);
    setOrderBookingData(null)
  }

  const confirmOrderBooking = () => {
    const path = "/orders/new-order";
    const searchParams = new URLSearchParams({ ...orderBookingData });

    // Navigate to the URL with search parameters
    nav(`${path}?${searchParams.toString()}`);
  }

  useEffect(() => {
    const fetchCalendarData = async () => {
      const dates = calculateDates(selectedDateFrom);
      const params: CalendarSearchRequestDto = {
        fromDate: DateShim(dates.from).toISOString().split('T')[0],
        toDate: DateShim(dates.to).toISOString().split('T')[0],
        operatorMemberId: userLogged.operator?.id!,
        locationId: userLogged.location!.id
      }

      const data = await getCalendarData(params)
      if (data)
        setCalendarData(data);
    }

    fetchCalendarData();
  }, [selectedDateFrom]);

  useEffect(() => {
    if (calendarData) {
      if (selectedInventoryId) {
        setOwnerData(calendarData?.filter(item => item.sellableItemId === selectedInventoryId).map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
          type: 'Conference'
        })));
      } else {
        setOwnerData(calendarData?.map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
        })));
      }

      const filteredData = selectedInventoryId ? calendarData.filter(item => item.sellableItemId === selectedInventoryId) : calendarData;

      const timeZone = 'Europe/London';
      const newEvents = filteredData.flatMap((data: CalendarSearchResponseDto) =>
        data.unavailabilites?.map((unavailability: CalendarSearchDetailResponseDto) => {
          // Parse the date string without the "Z"
          const startDateLocalString = new Date(unavailability.fromDate.replace("Z", "+01:00")); //TODO=> remove the hardcoded for UK locations.
          const endDateLocalString = new Date(unavailability.toDate.replace("Z", "+01:00"));

          // Convert to UTC first, then to Europe/London time zone
          const startDateLocal = formatInTimeZone(startDateLocalString, timeZone, "yyyy-MM-dd HH:mm:ssXXX");
          const endDateLocal = formatInTimeZone(endDateLocalString, timeZone, "yyyy-MM-dd HH:mm:ssXXX")

          return {
            Id: unavailability.orderId || uuidv4(),
            Subject: `${unavailability.memberFullName ? `${unavailability.memberFullName}, ` : ''} ${unavailability.unavailabilityTypeName}${unavailability.orderExternalReference ? ` n° ${unavailability.orderExternalReference}` : ''}`,
            StartTime: startDateLocal,
            Reason: unavailability.reason,
            EndTime: endDateLocal,
            ExternalReference: unavailability.orderExternalReference,
            OrderId: unavailability.orderId,
            MemberName: unavailability.memberFullName,
            Description: unavailability.unavailabilityTypeName || '--',
            RoomId: data.sellableItemId,
            CustomStyle: unavailability.orderExternalReference ? 'custom-event-color' : 'custom-event-unavailable',
          };
        }) || []
      );
      setEvents({ dataSource: newEvents });
      setLoading(false);
    }
  }, [calendarData, selectedInventoryId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className='schedule-control-section'>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <Box sx={{ mb: 1 }}>
              <CustomSelect
                options={inventoryOptions}
                getOptionLabel={(option: any) => { return option.sellableItemName }}
                getOptionValue={(option: any) => { return option.sellableItemId }}
                value={calendarData?.find(x => x.sellableItemId === selectedInventoryId) || inventoryOptions[0]}
                onChange={(e: any) => handleInventoryChange(e.sellableItemId)}
                placeholder="Select Inventory"
                labelProps={{
                  message: 'Select Inventory',
                }}
              ></CustomSelect>
            </Box>

            <ScheduleComponent
              cssClass='timeline-resource'
              ref={scheduleObj}
              key={selectedDateFrom.toString()}
              width='100%'
              height='750px'
              selectedDate={selectedDateFrom}
              navigating={handleNavigating}
              timezone={"Europe/London"}
              eventRendered={onEventRendered}
              actionComplete={onActionComplete}
              resourceHeaderTemplate={resourceHeaderTemplate}
              eventSettings={events}
              quickInfoTemplates={quickInfoTemplates} // Replacing all templates with custom one
              actionBegin={onActionBegin}
              eventDoubleClick={onPopupOpen}
              group={{ enableCompactView: false, resources: ['MeetingRoom'] }}
              select={onSelect}
            >
              <ViewsDirective>
                <ViewDirective option='TimelineDay' startHour='07:00' endHour='20:00' />
                <ViewDirective option='TimelineWeek' startHour='07:00' endHour='20:00' firstDayOfWeek={1} />
                <ViewDirective option='TimelineMonth' startHour='07:00' endHour='20:00' />
              </ViewsDirective>

              <ResourcesDirective>
                <ResourceDirective
                  field='RoomId'
                  title='Room Type'
                  name='MeetingRoom'
                  allowMultiple={true}
                  dataSource={ownerData}
                  textField='text'
                  idField='id'
                  colorField='color'
                />
              </ResourcesDirective>

              <Inject services={[TimelineViews, TimelineMonth]} />
            </ScheduleComponent>
          </div>
        </div>
      </div>

      <CustomConfirmDialogWithContent
        isOpen={openOrderBookingDialog}
        confirmButtonColor='success'
        confirmButtonTitle='Yes'
        handleConfirm={confirmOrderBooking}
        onClose={cancelOrderBooking}
        title={i18n.t('ACTIVITY_LABEL_NEWORDER')}
      >
        <Box sx={{ width: '100%' }}>
          {
            orderBookingData &&
            <Grid container>
              <Grid item xs={12}>
                <LR localResource="COMMON_LABEL_FROM"></LR> {orderBookingData.startDate} {orderBookingData.startTime}
              </Grid>
              <Grid item xs={12}>
                <LR localResource="COMMON_LABEL_TO"></LR> {orderBookingData.endDate} {orderBookingData.endTime}
              </Grid>
              <Grid item xs={12}>
                <LR localResource="COMMON_LABEL_FOR"></LR> {orderBookingData.sellableItemName}
              </Grid>
            </Grid>
          }
        </Box>
      </CustomConfirmDialogWithContent>
    </>
  );
};