import { Box } from '@mui/material';
import i18n from '../../i18n';
import { IconDisplayer } from './Help/IconDisplayer';
import { Input } from './formComponents/Input';

type SearchInputProps = {
  onSearchChange: (query: string) => void;
  searchQuery: string;
  suggestions?: string[];
  placeholder?: string;
};

const SearchInput = ({ onSearchChange, searchQuery, suggestions = [], placeholder = '' }: SearchInputProps) => {
  return (
    <div className="search-container">
      <Input
        id="outlined-basic"
        fullWidth
        inputProps={{
          placeholder: i18n.t(placeholder)
        }}
        rightIcon={<Box sx={{ pr: 2 }}><IconDisplayer type={'COMMON_LABEL_SEARCH'} /></Box>}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
};


export { SearchInput };

