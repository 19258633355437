import axios, { AxiosError } from "axios";
import { useMutation } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { BusinessRuleResponseDto } from "../../types/Product/BusinessRuleResponseDto";
// import { OrderBusinessRule } from "../../types/Order/BasketResponse";


type CreateOrderBusinessRulePayload = BusinessRuleResponseDto & {
  basketId: string;
};

export const useCreateOrderBusinessRule = () => {
  return useMutation<{ id: string }, AxiosError, CreateOrderBusinessRulePayload>(
    (payload) =>
      axios.post(`${HookConfig.orderUrl}/baskets/${payload.basketId}/business-rules/${payload.businessRuleId}`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};

type UpdateOrderBusinessRulePayload = BusinessRuleResponseDto & {
  basketId: string;
  id: string;
};

export const useUpdateOrderBusinessRule = () => {
  return useMutation<{ id: string }, AxiosError, UpdateOrderBusinessRulePayload>(
    (payload) =>
      axios.put(`${HookConfig.orderUrl}/baskets/${payload.basketId}/business-rules/${payload.id}`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};

type DeleteOrderBusinessRulePayload = {
  basketId: string;
  id: string;
};

export const useDeleteOrderBusinessRule = () => {
  return useMutation<{ id: string }, AxiosError, DeleteOrderBusinessRulePayload>(
    (payload) =>
      axios.delete(`${HookConfig.orderUrl}/baskets/${payload.basketId}/business-rules/${payload.id}`, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};
