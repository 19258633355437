import { Box, Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrderData } from '../../hooks/Order/getOrderData';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { OrderSearchV2ItemResponseDto } from '../../types/Order/OrderSearchV2ItemResponseDto';
import OrderTable from './common/OrderTable';
import './common/OrderTable.css';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
interface OrderFeedProps {
  userLogged: ApplicationUser;
  searchQuery?: string;
  fromDate: string;
  toDate: string;
}

const OrderFeed: React.FC<OrderFeedProps> = ({ userLogged, searchQuery = "", fromDate, toDate }) => {
  const navigate = useNavigate();

  const defaultItemPerPage = 10;

  const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage)

  const [page, setPage] = useState(1);
  const [allOrders, setAllOrders] = useState<OrderSearchV2ItemResponseDto[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const shouldFetchOrders = searchQuery.length >= 3;
  const effectiveSearchQuery = shouldFetchOrders ? searchQuery.trim() : '';

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(false);
      try {
        const params = {
          locationId: userLogged.location!.id,
          searchRequirement: effectiveSearchQuery,
          pageNumber: 0,
          pageSize: 0,
          fromDate,
          toDate,
        };

        const data = await getOrderData(params);

        if (data?.records) {
          setAllOrders(data.records);
          setPage(1);
          setTotalRecords(data.totalRecords || 0);
        } else {
          setAllOrders([]);
          setTotalRecords(0);
        }
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [fromDate, toDate, effectiveSearchQuery, userLogged.location!.id]);

  const paginatedOrders = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    return allOrders.slice(start, start + itemsPerPage);
  }, [page, allOrders, itemsPerPage]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleOrderClick = (order: OrderSearchV2ItemResponseDto) => {
    navigate(`/orders/${order.orderId}/view-order`);
  };

  const headers = [
    'Order Number',
    'Customer',
    'Company',
    'From Date',
    'To Date',
    'Product',
    'Space',
    'Action'
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading orders</div>;

  const itemPerPageList = [{
    label: 5,
    value: 5
  }, {
    label: 10,
    value: 10
  }, {
    label: 20,
    value: 20
  }]

  return (
    <div className="order-feed">
      {allOrders.length === 0 ? (
        <div>No bookings found</div>
      ) : (
        <>
          <OrderTable
            orders={paginatedOrders}
            headers={headers}
            onActionClick={handleOrderClick}
            userLogged={userLogged}
          />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <Pagination
                  count={Math.ceil(totalRecords / itemsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  color="primary"
                  className="pagination-bar-order"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CustomSelect
                  options={itemPerPageList}
                  value={itemPerPageList.find(x => x.value === itemsPerPage)}
                  onChange={(e) => setItemsPerPage(e?.value || defaultItemPerPage)}
                ></CustomSelect>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export { OrderFeed };


