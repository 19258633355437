import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { OccupancyAdjustment, OrderLineRequest, PriceAdjustment } from '../../../../types/Order/OrderLineRequest';

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { Control, useForm } from "react-hook-form";
import { SingleValue } from "react-select";
import * as yup from "yup";

import { addMonths, endOfMonth, isBefore, startOfMonth } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { pontProductsConfig } from "../../../../data/order/OrderData";
import { formatPrice } from "../../../../functions/utils/fiscal";
import { cleanValue } from '../../../../functions/utils/helper';
import { useFetchProductTypesByLocation } from "../../../../hooks/Location/location";
import { useGetSellableItemAvailability } from "../../../../hooks/Order/availability";
import { useBasketCreate, useBasketOrderLineCreate, useBasketOrderLineUpdate } from "../../../../hooks/Order/basket";
import i18n from "../../../../i18n";
import { SellableItemAvailability } from "../../../../types/Order/SellableItemAvailabilityResponse";
import { ProductConfig } from "../../../../types/Product/ProductConfigDto";
import { FormattedProductTypeDto } from "../../../../types/Product/ProductTypeDto";
import { showSnackbar } from "../../../Common/UI/CustomSnackbar";
import "../OrderForm.css";
import { useOrderContext } from '../OrderProvider';

const formatDateFromInput = (year: number, month: number, day: number, hour: number, minute: number) => {
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`;
}

const formatDate = (date: Date | string, resetTime = false): string => {
  const _date = dayjs(date);
  const day = _date.date();
  const month = (_date.month() + 1); // Months are 0-based, so add 1
  const year = _date.year();
  const hour = _date.hour();
  const minute = _date.minute();

  if (resetTime) {
    return formatDateFromInput(year, month, day, 0, 0);
  }
  return formatDateFromInput(year, month, day, hour, minute);
};

// Define the type of the context value
interface OrderLineContextType {
  control: Control<any>,
  productTypes: FormattedProductTypeDto[] | undefined,
  handleChangeProduct: (product: SingleValue<FormattedProductTypeDto>) => void,
  productConfig: ProductConfig | undefined,
  fromDate: string | undefined | null,
  toDate: string | undefined | null,
  showCheckAvailability: boolean,
  isValid: boolean,
  checkOrderLineAvailability: () => void,
  confirmOrderLine: () => void,
  handleCheckAvailability: () => void,
  availabilities: SellableItemAvailability[] | undefined,
  currentOrderLine: OrderLineRequest | undefined,
  orderLineError: string | undefined,
  isOrderLineCustom: boolean,
  setIsOrderLineCustom: (isOrderLineCustom: boolean) => void,
  handleConfirmOrderLine: () => void,
  setCurrentOrderLine: (currentOrderLine: OrderLineRequest | undefined) => void,
  orderLineDescription: string,
  handleCustomOrderLine: () => void,
  checkingAvailability: boolean,
  creatingOrderLine: boolean,
  updatingOrderLine: boolean,
  creatingBasket: boolean,
  handleQuantityChange: (value: any) => void,
  handleAvailabilityChange: (value: SellableItemAvailability) => void,
  fetchingProductTypes: boolean,
  handleFromDateChange: (value: Dayjs | undefined) => void,
  showNoAvailabilityMessage: boolean
}
// Create the context
const OrderLineContext = createContext<OrderLineContextType | undefined>(undefined);
// Provider component
interface OrderLineProviderProps {
  children: ReactNode;
}

export const OrderLineProvider: FC<OrderLineProviderProps> = (props) => {
  const { children, } = props;
  const nav = useNavigate()
  const { basketId } = useParams()

  const [searchParams] = useSearchParams()

  const startDateFromUrl = searchParams.get("startDate")
  const startTimeFromUrl = searchParams.get("startTime")
  const endDateFromUrl = searchParams.get("endDate")
  const endTimeFromUrl = searchParams.get("endTime")
  const sellableItemIdFromUrl = searchParams.get("sellableItemId")

  const { orderLineEditMode, selectedOrderLine, userLogged, setOrderLineOpen, isBasketPage, getLatestBasketDetail, orderLines } = useOrderContext()

  const [localBasketId, setLocalBasketId] = useState<string | undefined>(basketId)

  const shouldShowCustomOrderLineView = Boolean(selectedOrderLine?.occupancyAdjustments?.length || selectedOrderLine?.priceAdjustments?.length)

  const [showCheckAvailability, setShowCheckAvailability] = useState<boolean>(false);
  const [productConfig, setProductConfig] = useState<ProductConfig>();
  const [productTypes, setProductTypes] = useState<FormattedProductTypeDto[]>();
  const [availabilities, setAvailabilities] = useState<SellableItemAvailability[]>();
  const [currentOrderLine, setCurrentOrderLine] = useState<OrderLineRequest>();
  const [orderLineError, setOrderLineError] = useState<string>();
  const [isOrderLineCustom, setIsOrderLineCustom] = useState<boolean>(shouldShowCustomOrderLineView || false);
  const [showNoAvailabilityMessage, setShowNoAvailabilityMessage] = useState<boolean>(false);

  const { memberId, parentMemberId } = useOrderContext();

  const { mutateAsync: createOrderLineAsync, isLoading: creatingOrderLine } = useBasketOrderLineCreate();
  const { mutateAsync: updateOrderLineAsync, isLoading: updatingOrderLine } = useBasketOrderLineUpdate({ params: { orderLineId: selectedOrderLine?.id } })
  const { mutate: getSellableItemAvailability, isLoading: checkingAvailability } = useGetSellableItemAvailability();
  const { mutateAsync: createBasketMutationAsync, isLoading: creatingBasket } = useBasketCreate();

  // Fetch product types by location
  const { isLoading: fetchingProductTypes } = useFetchProductTypesByLocation(userLogged.location?.id!, {
    onSuccess: (products) => {
      if (products) {
        const mappedProducts = products.map((item) => ({
          label: i18n.t(item.description),
          value: item.sellableItemId,
          productTypeName: item.productTypeName,
          defaultUnitName: item.defaultUnitName,
          defaultQuantity: item.defaultQuantity,
        })).sort((a, b) => a.label.localeCompare(b.label));

        setProductTypes(mappedProducts);

        if (orderLineEditMode && selectedOrderLine) {
          const productConfig = pontProductsConfig.find(
            (x) => x.productTypeName === selectedOrderLine.product.productTypeName
          );
          if (productConfig) {
            setProductConfig(productConfig);
          }

          selectedOrderLine.basketId = basketId!;
          setCurrentOrderLine(selectedOrderLine);

          const { product, sellableItemId, occupants, fromDate, toDate, } = selectedOrderLine

          if (product) {
            const value = mappedProducts.find(x => x.productTypeName === product.productTypeName)
            setValue("product", value || product);
          }
          if (sellableItemId) {
            setValue("sellableItemId", sellableItemId);
          }
          if (occupants) {
            setValue("quantity", occupants);
          }
          if (fromDate) {
            setValue("fromDate", fromDate);
          }
          if (toDate) {
            setValue("toDate", toDate);
          }
          if (fromDate && toDate) {
            const _fromDate = fromDate.replace('Z', '');
            const _toDate = toDate.replace('Z', '');
            setValue("timeRange", [dayjs(_fromDate), dayjs(_toDate)]);
          }
        } else {
          reset({
            product: products[0],
            sellableItemId: products[0].sellableItemId,
            quantity: products[0].defaultQuantity
              ? products[0].defaultQuantity
              : 1,
            fromDate: dayjs(new Date()).toISOString(),
          });
        }
      }
    }
  });

  const timeRangeSchema = yup.array()
    .of(
      yup.mixed<Dayjs>().test(
        "is-dayjs",
        "Invalid date",
        (value) => value && value.isValid() // Ensure the value is a valid Dayjs object
      )
    )
    .length(2, "Time range must have exactly two dates: [start, end]");

  const availabilitySchema = yup.mixed<SellableItemAvailability>();

  const productSchema = yup.object().shape({
    product: yup.object().shape({
      label: yup.string().required(),
      productTypeName: yup.string().required(),
      defaultQuantity: yup.number().optional().nullable(),
      defaultUnitName: yup.string().optional().nullable(),
      value: yup.string().required(),
    }),
    sellableItemId: yup.string().required("Sellable Item Id is required"),
    noticePeriodUnitName: yup.string(),
    noticePeriodValue: yup.number().min(0),
    fromDate: yup
      .string()
      .nullable()
      .test(
        "is-valid-from-date",
        i18n.t("VALIDATION_FROM_DATE_MUST_BE_A_VALID_DATE") as string,
        (value) => {
          return value ? dayjs(value).isValid() : true;
        }
      ),
    toDate: yup
      .string()
      .nullable()
      .test(
        "is-valid-to-date",
        i18n.t("VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE") as string,
        (value) => {
          return value ? dayjs(value).isValid() : true;
        }
      )
      .test(
        "is-to-date-later",
        i18n.t("VALIDATION_TO_DATE_CANNOT_BE_EARLIER_THAN_FROM_DATE") as string,
        function (value) {
          const { fromDate } = this.parent;
          if (!value || !fromDate) return true;
          return !dayjs(value).isBefore(dayjs(fromDate));
        }
      ),
    timeRange: timeRangeSchema,
    availability: availabilitySchema,
    quantity: yup.number().required("Quantity is required").min(1),
    memberRequirement: yup.string().optional(),
    memberComments: yup.string().optional(),
  });

  type orderLineType = yup.InferType<typeof productSchema>;

  const {
    control,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    watch,
    setValue,
    trigger
  } = useForm<orderLineType>({
    resolver: yupResolver(productSchema),
    mode: 'onChange'
  });

  const product = watch("product");
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const quantity = watch("quantity");
  const timeFrame = watch("timeRange");
  const availability = watch("availability");

  const handleChangeProduct = (
    product: SingleValue<FormattedProductTypeDto>
  ) => {
    if (product) {
      const productConfig = pontProductsConfig.find(
        (x) => x.productTypeName === product.productTypeName
      );

      const { requiresStartDate, requiresEndDate, requiresStartTime, requiresEndTime } = productConfig || {}
      const { occupants: lastItemOccupants, fromDate: lastItemFromDate, toDate: lastItemToDate, product: lastProduct } = orderLines[orderLines.length - 1] || {}

      const lastProductConfig = pontProductsConfig.find(
        (x) => x?.productTypeName === lastProduct?.productTypeName
      );

      setValue("product", product);
      setValue("sellableItemId", product.value);

      const dateFormat = "DD/MM/YYYY"
      const dateTimeFormat = "DD/MM/YYYY HH:mm";

      // From date
      let autoFillFromDate = undefined
      if (requiresStartDate) {
        autoFillFromDate = dayjs(new Date()).toISOString()
      }
      if (lastItemFromDate) {
        autoFillFromDate = lastItemFromDate
      }
      if (startDateFromUrl) {
        autoFillFromDate = dayjs(startDateFromUrl, dateFormat).toISOString()
      }
      if (autoFillFromDate) {
        setValue("fromDate", autoFillFromDate)
      }

      // End date
      let autoFillToDate = undefined
      if (requiresEndDate) {
        autoFillToDate = dayjs(new Date()).toISOString()
      }
      if (lastItemToDate) {
        autoFillToDate = lastItemToDate
      }
      if (endDateFromUrl) {
        autoFillToDate = dayjs(endDateFromUrl, dateFormat).toISOString()
      }
      if (autoFillToDate) {
        setValue("toDate", autoFillToDate)
      }

      // time range
      let autoFillTimeRange: [Dayjs, Dayjs] | undefined = undefined
      if (requiresStartTime && startTimeFromUrl && startDateFromUrl && requiresEndTime && endTimeFromUrl) {
        autoFillTimeRange = [
          dayjs(`${startDateFromUrl} ${startTimeFromUrl}`, dateTimeFormat),
          dayjs(`${startDateFromUrl} ${endTimeFromUrl}`, dateTimeFormat)
        ]
      }
      if (lastItemFromDate && lastItemToDate && lastProductConfig?.requiresEndTime && lastProductConfig?.requiresStartTime) {
        const _lastItemFromDate = lastItemFromDate.replace('Z', '');
        const _lastItemToDate = lastItemToDate.replace('Z', '');
        autoFillTimeRange = [dayjs(_lastItemFromDate), dayjs(_lastItemToDate)];
      }
      setValue('timeRange', autoFillTimeRange)

      // Quantity
      let autoFillQuantity = 1
      if (product.defaultQuantity) {
        autoFillQuantity = product.defaultQuantity
      }
      if (lastItemOccupants) {
        autoFillQuantity = lastItemOccupants
      }
      setValue("quantity", autoFillQuantity)

      if (productConfig) {
        setProductConfig(productConfig);
      }
    }

    setAvailabilities([]);
    setCurrentOrderLine(undefined);
    setShowCheckAvailability(false);
  };

  const orderLineDescription = useMemo(() => {
    if (selectedOrderLine?.description) return selectedOrderLine.description

    let description = "";

    if (userLogged.location?.name) {
      description += userLogged.location.name + "\n";
    }

    if (availability?.name || product?.label) {
      description += ((availability?.name || "") + (product?.label ? ` (${product.label})` : "")).trim() + "\n";
    }

    if (quantity && !isOrderLineCustom) {
      description += quantity + " " + (i18n.t(productConfig?.unitLabelOverride || ""));
    }

    return description.trim();
  }, [availability, product, quantity, productConfig, userLogged.location, isOrderLineCustom, selectedOrderLine?.description]);

  const handleCheckAvailability = () => {
    if (product && productConfig) {
      setValue("availability", undefined);
      setAvailabilities([]);
      var request = {
        locationId: userLogged.location?.id!,
        memberId: memberId,
        sellableItemId: product.value,
        fromDateDay: dayjs(fromDate).date(),
        fromDateMonth: dayjs(fromDate).month() + 1,
        fromDateYear: dayjs(fromDate).year(),
        toDateDay: productConfig.requiresEndDate
          ? dayjs(toDate).date()
          : dayjs(fromDate).date(),
        toDateMonth: productConfig.requiresEndDate
          ? dayjs(toDate).month() + 1
          : dayjs(fromDate).month() + 1,
        toDateYear: productConfig.requiresEndDate
          ? dayjs(toDate).year()
          : dayjs(fromDate).year(),
        fromHours: productConfig.requiresStartTime
          ? dayjs(timeFrame![0]).hour()
          : 0,
        fromMinutes: productConfig.requiresStartTime
          ? dayjs(timeFrame![0]).minute()
          : 0,
        toHours: productConfig.requiresEndTime
          ? dayjs(timeFrame![1]).hour()
          : 0,
        toMinutes: productConfig.requiresEndTime
          ? dayjs(timeFrame![1]).minute()
          : 0,
        quantity: quantity,
      };

      getSellableItemAvailability(request, {
        onSuccess: (response) => {
          if (response && response.length > 0) {
            const updatedItems = response.map((item) => ({
              ...item,
              label: `${item.name} ${item.availableQuantity > 1 ? `- ${i18n.t("ORDER_LABEL_UPTO")} ${item.availableQuantity}` : ''} (${formatPrice(item.currencyCode, item.priceExTax)} ${i18n.t(
                "ORDER_LABEL_EXVAT"
              )}, ${i18n.t(item.unitLabel)})`,
              value: item.sellableItemId,
            }))
            setAvailabilities(updatedItems);
            if (sellableItemIdFromUrl) {
              const preSelectedItem = updatedItems.find(item => item.sellableItemId === sellableItemIdFromUrl)
              if (preSelectedItem) {
                handleAvailabilityChange(preSelectedItem)
                setValue("availability", preSelectedItem)
              }
            }
          } else {
            setShowNoAvailabilityMessage(true)
          }
        },
        onError: (error) => {
          setAvailabilities([]);
          showSnackbar(error.response?.data, {
            persist: true,
            variant: 'error'
          });
        }
      })
    }
  };

  const handleConfirmOrderLine = async () => {
    try {
      // The following mumbo jumbo ensure that basket only created once only
      // When rules are override, we may need to call this function again
      // Which leads to create a new basket, to prevent this we will use local basket id
      let _tempBasketId = localBasketId

      if (!_tempBasketId) {
        if (!memberId) {
          // If user is on basket page, this will not hit
          throw new Error("MemberId is required");
        }

        const { id } = await createBasketMutationAsync({
          parentMemberId: parentMemberId
            ? cleanValue(parentMemberId)
            : undefined,
          memberId: memberId,
          locationId: userLogged.location?.id!,
        })
        setLocalBasketId(id)
        _tempBasketId = id
      }

      if (!currentOrderLine) {
        throw new Error("OrderLine is required");
      }

      const clonedOrderLine = cloneDeep(currentOrderLine)

      // Set the basket id
      clonedOrderLine.basketId = _tempBasketId

      if (!isOrderLineCustom) {
        // we will reset price and occupancy adjustments
        // if the order line is custom
        clonedOrderLine.priceAdjustments = undefined
        clonedOrderLine.occupancyAdjustments = undefined
      }

      if (!productConfig?.allowOccupancyAdjustment) {
        clonedOrderLine.occupancyAdjustments = undefined
      }

      const action = currentOrderLine.id ? updateOrderLineAsync : createOrderLineAsync
      const orderLineResponse = await action(clonedOrderLine)

      if (currentOrderLine) {
        setCurrentOrderLine({
          ...clonedOrderLine,
          id: orderLineResponse.id,
          hasError: orderLineResponse.hasErrors,
          errorLabel: orderLineResponse.errorLabel,
          errorValue: orderLineResponse.errorValue,
          canIgnoreBusinessRuleValidation:
            orderLineResponse.canIgnoreBusinessRuleValidation,
        });
      }

      if (!orderLineResponse.hasErrors) {
        currentOrderLine.id = orderLineResponse.id;

        // Close the order line modal
        setOrderLineOpen(false);

        if (!isBasketPage) {
          // If we are not on basket page, we will redirect to basket page
          nav(`/orders/basket/${_tempBasketId}`)
        } else {
          // We will fetch the latest basket detail
          getLatestBasketDetail()
        }

      } else {
        setOrderLineError(i18n.t(orderLineResponse.errorLabel!)!);
      }
    } catch (error: any) {
      console.error(error)
      showSnackbar(error?.response?.data, {
        persist: true,
        variant: 'error'
      });
    }
  };

  const checkOrderLineAvailability = handleSubmit(handleCheckAvailability)
  const confirmOrderLine = handleSubmit(handleConfirmOrderLine)

  const generateMonthlyRanges = (startDate: Date, endDate: Date) => {
    let result = [];
    let current = startDate;

    while (isBefore(current, endDate) || current.getTime() === endDate.getTime()) {
      let startOfCurrentMonth = current;
      let endOfCurrentMonth = endOfMonth(current);

      // Adjust end date if it's beyond the final date range
      if (isBefore(endDate, endOfCurrentMonth)) {
        endOfCurrentMonth = endDate;
      }

      result.push({
        startDate: startOfCurrentMonth,
        endDate: endOfCurrentMonth
      });

      // Move to the next month
      current = addMonths(startOfMonth(current), 1);
    }

    return result;
  }

  const handleCustomOrderLine = () => {
    if (!currentOrderLine) return

    if (isOrderLineCustom) {
      setIsOrderLineCustom(false)
    } else {
      const { unitName, unitPriceExTax, priceAdjustments, occupancyAdjustments } = currentOrderLine
      const { allowOccupancyAdjustment } = productConfig || {}

      if (priceAdjustments === undefined || occupancyAdjustments === undefined) {
        const unitPrice = unitPriceExTax || 0
        const occupant = quantity || 0

        const initialPriceAdjustment: PriceAdjustment[] = []
        const initialOccupancyAdjustment: OccupancyAdjustment[] = []

        if (unitName === 'Month' && fromDate && toDate) {
          generateMonthlyRanges(new Date(fromDate), new Date(toDate)).forEach(({ startDate, endDate }) => {
            initialPriceAdjustment.push({
              // Ensure the date is rounded to the nearest day, we will remove time from the date
              fromDate: formatDate(startDate, true),
              toDate: formatDate(endDate, true),
              unitName: unitName,
              quantity: 1,
              isAdjustment: true,
              priceExTax: unitPrice,
              type: 'RecurringPrice'
            })
            initialOccupancyAdjustment.push({
              fromDate: formatDate(startDate, true),
              toDate: formatDate(endDate, true),
              quantity: occupant,
            })
          })
        } else if (unitName === 'Hour' && fromDate && timeFrame && timeFrame.length === 2) {
          const date = dayjs(fromDate).date()
          const month = dayjs(fromDate).month()
          const year = dayjs(fromDate).year()

          const startHour = timeFrame[0]?.hour() || 0
          const endHour = timeFrame[1]?.hour() || 0

          const startMinute = timeFrame[0]?.minute() || 0
          const endMinute = timeFrame[1]?.minute() || 0

          const startDate = formatDateFromInput(year, month, date, startHour, startMinute)
          const endDate = formatDateFromInput(year, month, date, endHour, endMinute)

          initialPriceAdjustment.push({
            fromDate: startDate,
            toDate: endDate,
            unitName: currentOrderLine.unitName,
            quantity: quantity,
            isAdjustment: true,
            priceExTax: currentOrderLine.priceExTax,
            type: 'OneOffPrice'
          })

          initialOccupancyAdjustment.push({
            fromDate: startDate,
            toDate: endDate,
            quantity: occupant,
          })
        } else {
          initialOccupancyAdjustment.push({
            fromDate: formatDate(fromDate!, true),
            toDate: formatDate(toDate!, true),
            quantity: quantity,
          })

          initialPriceAdjustment.push({
            fromDate: formatDate(fromDate!, true),
            toDate: formatDate(toDate!, true),
            unitName: currentOrderLine.unitName,
            quantity: quantity,
            isAdjustment: true,
            priceExTax: currentOrderLine.priceExTax,
            type: "OneOffPrice"
          })
        }

        setCurrentOrderLine({
          ...currentOrderLine,
          priceAdjustments: initialPriceAdjustment,
          occupancyAdjustments: allowOccupancyAdjustment ? initialOccupancyAdjustment : []
        })
      }
      setIsOrderLineCustom(true)
    }
  }

  const showOrHandleCheckAvailability = () => {
    if (!productConfig || orderLineEditMode) return

    const { requiresStartDate, requiresEndDate, requiresStartTime, requiresEndTime, requiresQuantity, showCheckAvailability, } = productConfig
    const startTime = Array.isArray(timeFrame) && timeFrame.length > 0 ? timeFrame[0] : undefined;
    const endTime = Array.isArray(timeFrame) && timeFrame.length > 0 ? timeFrame[1] : undefined;

    const configArray: any = [requiresQuantity, requiresStartDate, requiresEndDate, requiresStartTime, requiresEndTime]
    const valueArray: any = [quantity, fromDate, toDate, startTime, endTime]

    const filteredConfigArray = configArray.filter((value: any, index: number) => value === true);
    const filteredValueArray = valueArray.filter((item: any, index: number) => Boolean(item) && configArray[index]);

    if (filteredConfigArray.length === filteredValueArray.length) {
      if (showCheckAvailability) {
        setShowCheckAvailability(true);
      } else {
        handleCheckAvailability();
      }
    }
  }

  const handleQuantityChange = (value: any) => {
    if (value && currentOrderLine) {
      setCurrentOrderLine({ ...currentOrderLine, occupants: value, description: orderLineDescription });
    }
  };

  const handleAvailabilityChange = (value: SellableItemAvailability) => {
    const {
      fromDate,
      inventoryTypeName,
      currencyCode,
      toDate,
      quantity,
      unitName,
      unitPriceExTax,
      priceExTax,
      sellableItemId,
    } = value;

    if (value) {
      const orderLine: OrderLineRequest = {
        basketId: basketId!,
        fromDate: fromDate,
        product: product,
        occupants: quantity,
        inventoryTypeName: inventoryTypeName || "",
        currencyCode: currencyCode!,
        toDate: toDate || undefined,
        description: orderLineDescription,
        quantity: quantity!,
        unitName: unitName!,
        unitPriceExTax: unitPriceExTax!,
        priceExTax: priceExTax!,
        sellableItemId: sellableItemId!,
      };

      setCurrentOrderLine(orderLine);

      // We will reset the order line custom and error, if not in edit mode
      if (!orderLineEditMode) {
        setIsOrderLineCustom(false)
        setOrderLineError(undefined)
      }
    }
  };

  const handleFromDateChange = (value: Dayjs | undefined) => {
    if (value && toDate && productConfig?.requiresEndDate) {
      const _toDate = dayjs(toDate)
      if (value.isAfter(_toDate)) {
        setValue("toDate", value.toISOString())
      }
    }
  }

  useEffect(() => {
    showOrHandleCheckAvailability()
    // reset every thing when value changes, however not when it is in edit mode

    if (!orderLineEditMode) {
      setAvailabilities([])
      setIsOrderLineCustom(false)
      setCurrentOrderLine(undefined)
      setOrderLineError(undefined)
      setShowNoAvailabilityMessage(false)
    }
  }, [quantity, timeFrame, fromDate, toDate]);

  useEffect(() => {
    showOrHandleCheckAvailability()
  }, [productConfig])

  return (
    <OrderLineContext.Provider value={{
      availabilities,
      checkOrderLineAvailability,
      checkingAvailability,
      confirmOrderLine,
      control,
      creatingBasket,
      creatingOrderLine,
      currentOrderLine,
      fetchingProductTypes,
      fromDate,
      handleAvailabilityChange,
      handleChangeProduct,
      handleCheckAvailability,
      handleConfirmOrderLine,
      handleCustomOrderLine,
      handleFromDateChange,
      handleQuantityChange,
      isOrderLineCustom,
      isValid,
      orderLineDescription,
      orderLineError,
      productConfig,
      productTypes,
      setCurrentOrderLine,
      setIsOrderLineCustom,
      showCheckAvailability,
      showNoAvailabilityMessage,
      toDate,
      updatingOrderLine,
    }}>
      {children}
    </OrderLineContext.Provider>
  );
};

// Custom hook to use the context
export const useOrderLineContext = () => {
  const context = useContext(OrderLineContext);
  if (!context) {
    throw new Error('useOrderLineContext must be used within a OrderLineProvider');
  }
  return context;
};