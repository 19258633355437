import React, { useEffect, useState } from 'react'
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { RevenueChart } from './Charts/RevenueChart';
import { getOccupancyReportData, getRetentionData, getRevenueReportData, getWorkstationOccupancyReportData } from '../../hooks/Report/Report';
import { transformOccupancyData, transformRetentionData, transformRevenueData } from '../../hooks/Report/DataTransformation';
import { RevenueData } from '../../types/Report/RevenueData';
import { OccupancyData } from '../../types/Report/OccupancyData';
import { OccupancyChart } from './Charts/OccupancyChart';
import './charts.css';
import { RetentionData } from '../../types/Report/RetentionData';
import { RetentionChart } from './Charts/RetentionChart';
import { RetentionTrendsChart } from './Charts/RetentionTrendsChart';
import { ProductPieChart } from './Charts/ProductsPie';

type Args =
  {
    userLogged: ApplicationUser
  }


const Reports = ({ userLogged }: Args) => {


  return (
    <>
      <div className="row d-flex justify-content-center mt-4">

        <div className="col-5 justify-content-center chart-container">
          <div className=" justify-content-center" >
            <RevenueChart
              userLogged={userLogged}
              title="Office Revenue"
              width="100%"
              height={400}
            />
          </div>
        </div>



        <div className="col-3 justify-content-center chart-container">
          <div className=" justify-content-center" >
            <OccupancyChart
              title='Office Occupancy'
              userLogged={userLogged}
              type='office'
              height={400}
              width="100%"
            />
          </div>
        </div>



        <div className=" col-3 justify-content-center chart-container">
          <div className=" justify-content-center" >
            <OccupancyChart
              title='Workstation Occupancy'
              userLogged={userLogged}
              type='workstation'
              height={400}
              width="100%"
            />
          </div>
        </div>

      </div>

      <div className="row d-flex justify-content-center mt-4">

        
          <div className=" col-5 justify-content-center chart-container">
            <div className=" justify-content-center" >
              <RetentionChart
                title='Agreements'
                userLogged={userLogged}
                height={400}
                width="100%"
              />
            </div>
          </div>
        

        <div className=" col-3 justify-content-center chart-container">
          <RetentionTrendsChart
          title='Starters vs. Leavers Trend'
            userLogged={userLogged}
            height={400}
            width="100%"></RetentionTrendsChart>

        </div>
        <div className=" col-3 justify-content-center chart-container">
        <ProductPieChart 
         userLogged={userLogged}
         height={400}
         width="100%"/>

        </div>
       

      </div>
    </>
  )
}

export { Reports };

