import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LR } from "../Help/LR";
import { CustomButton } from "./CustomButton";
import { CustomDialog } from "./CustomDialog";

type CustomConfirmDialogWithContentType = {
  title: string,
  isOpen: boolean,
  onClose: () => void,
  confirmButtonTitle: string,
  confirmButtonColor: "error" | "success" | "info" | "warning" | "primary",
  handleConfirm: () => void,
  children?: JSX.Element
}
export const CustomConfirmDialogWithContent = ({
  title,
  isOpen,
  onClose,
  confirmButtonColor,
  confirmButtonTitle,
  handleConfirm,
  children
}: CustomConfirmDialogWithContentType) => {
  return (
    <CustomDialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <CustomButton variant="contained" type="button" color="info" onClick={onClose}>
          <LR localResource='COMMON_BUTTON_CANCEL' />
        </CustomButton>
        <CustomButton variant="contained" type="button" color={confirmButtonColor} onClick={handleConfirm} autoFocus>
          {confirmButtonTitle}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  )
}