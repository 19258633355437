import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useFetchMembers } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SectionHead } from "../Common/Help/SectionHead";

type Args = {
    userLogged: ApplicationUser
}

const OperatorList = ({ userLogged }: Args) => {
    const { data: operators } = useFetchMembers(4, "", 2);
    const nav = useNavigate();

    const columns:GridColDef[] = [
        { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_NAME')!, flex: 1 },
        { field: 'countryIso3Code', headerName: i18n.t('COMMON_LABEL_COUNTRY')!, flex: 1 },
        {
            field: 'edit', headerName: '', flex: 0.1, align: "center", filterable: false,
            renderCell: (params) => {
                return (
                    <IconButton color="secondary" onClick={() => nav(`/operators/${params.id}/summary`)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                )
            }
        }
    ]

    if (!operators) {
        return <>Could not find any operators</>
    }

    return (
        <>
            <SectionHead linkCallBack={() => { }}
                ctaText={i18n.t('COMMON_LABEL_ADMIN')!}
                name=""
                title={i18n.t('COMMON_LABEL_OPERATORS'!)}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <DataGrid
                        rows={operators}
                        columns={columns}
                        classes={{
                            toolbarContainer: 'toolbarContainer'
                        }}
                        slots={{
                            toolbar: GridToolbar
                        }}
                        checkboxSelection={false}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                    />
                </div>
            </div>
        </>
    );
};


export { OperatorList };
