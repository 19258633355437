import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchActivities } from '../../hooks/Activity/activity';
import { ActivityDto } from '../../types/Activity/ActivityDto';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import ActivityTable from './ActivityTable';
import './ActivityTable.css';

type ActivityFeedProps = {
  userLogged: ApplicationUser;
  searchQuery: string;
  fromDate: string;
  toDate: string;
};

const ActivityFeed = ({ userLogged, searchQuery, fromDate, toDate }: ActivityFeedProps) => {
  const [page, setPage] = useState(1);

  const defaultItemPerPage = 10;
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemPerPage)

  const { data: activities, status, loading } = useSearchActivities(userLogged.location?.id!, fromDate, toDate, searchQuery, itemsPerPage, page);

  useEffect(() => {
    // Reset to the first page when search query or date range changes
    setPage(1);
  }, [searchQuery, fromDate, toDate]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const nav = useNavigate();

  const navigateActivity = (activity: ActivityDto) => {
    let url = '';
    switch (activity.entityName) {
      case 'Location':
        url = `/locations/${activity.entityKey}/summary`;
        break;
      case 'Ticket':
        url = `/tickets/${activity.entityKey}`;
        break;
      case 'Order':
        url = `/orders/${activity.entityKey}/view-order`;
        break;
      case 'Membership':
        url = `/memberships/${activity.entityKey}/summary`;
        break;
      case 'Event':
        url = `/events/${activity.entityKey}/summary`;
        break;
      case 'Product':
        url = `/products/${activity.entityKey}`;
        break;
    }
    nav(url);
  }

  const paginatedActivities = activities ? activities.records : [];

  const itemPerPageList = [{
    label: 5,
    value: 5
  }, {
    label: 10,
    value: 10
  }, {
    label: 20,
    value: 20
  }]

  if (status !== 'success') {
    return (
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    );
  }

  if (!activities || activities.records?.length === 0) {
    return <MessageAlert message="No activities found." />;
  }

  return (
    <div className="activity-feed">
      <ActivityTable
        activities={paginatedActivities!}
        onActionClick={(activity) => navigateActivity(activity)}
        detailsLabels={[
          { label: 'Name', key: 'entityName' },
          { label: 'Date', key: 'activeDate' },
          { label: 'Activity Type', key: 'activityTypeName' },
          { label: 'Urgency', key: 'urgencyTypeName' },
          { label: 'Active Date', key: 'activeDate' },
          { label: 'Expiry Date', key: 'expiryDate' },
          { label: 'Description', key: 'description' },
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Pagination
            count={Math.ceil((activities.totalRecords || 0) / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            className="pagination-bar-order"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CustomSelect
            options={itemPerPageList}
            value={itemPerPageList.find(x => x.value === itemsPerPage)}
            onChange={(e) => setItemsPerPage(e?.value || defaultItemPerPage)}
          ></CustomSelect>
        </Grid>
      </Grid>
    </div>
  );
};

export default ActivityFeed;
