import React, { useEffect, useState } from 'react';
import { ChartData, ChartOptions } from "chart.js";
import { Box, Paper } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { RetentionData } from '../../../types/Report/RetentionData';
import { transformRetentionData } from '../../../hooks/Report/DataTransformation';
import { useGetReports } from '../../../hooks/Report/ReportHook';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';

interface RetentionChartProps {
  userLogged: ApplicationUser;
  title?: string;
  width?: string | number;
  height?: string | number;
}

export const RetentionChart: React.FC<RetentionChartProps> = ({
  userLogged,
  title = 'Contract Retention Analysis',
  width = '100%',
  height = 400,
}) => {
  const { getReports, data, isLoading, error } = useGetReports();
  const [chartData, setChartData] = useState<RetentionData | null>(null);

  useEffect(() => {
    const fetchMyReport = async () => {
      await getReports({
        operatorMemberIds: [userLogged.location?.operatorMemberId!],
        locationIds: [userLogged.location?.id!],
        metricGroupName: "Retention",
        productTypeName: "Office",
        fromDate: '2024-07-25T16:05:50.095Z',
        toDate: '2025-06-25T16:05:50.095Z'
      });
    };

    fetchMyReport();
  }, []);

  useEffect(() => {
    if (data) {
      const transformedData = transformRetentionData(data);
      setChartData(transformedData);
    }
  }, [data]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!chartData) return <div>No data available</div>;

  const chartDataConfig: ChartData<'bar'> = {
    labels: chartData.periods,
    datasets: [
      {
        label: 'Starting',
        data: chartData.starting,
        backgroundColor: 'rgba(75, 192, 148, 0.8)',
        borderColor: 'rgba(75, 192, 148, 1)',
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: 'Ending',
        data: chartData.ending,
        backgroundColor: 'rgba(65, 105, 225, 0.8)',
        borderColor: 'rgba(65, 105, 225, 1)',
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: 'Renewed',
        data: chartData.renewed,
        backgroundColor: 'rgba(255, 159, 64, 0.8)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: 'Churned',
        data: chartData.churned,
        backgroundColor: 'rgba(255, 99, 132, 0.8)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: 'Undecided',
        data: chartData.undecided,
        backgroundColor: 'rgba(153, 102, 255, 0.8)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.parsed.y} contracts`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
     
        ticks: {
          stepSize: 1,
        },
      },
    },
   
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }} className='chart-paper'>
      <h4>{title}</h4>
      <Box sx={{ width, height }}>
        <Bar data={chartDataConfig} options={options} />
      </Box>
    </Paper>
  );
};