import React, { useState, useEffect } from 'react';
import './ActivityTable.css';
import { ActivityDto } from '../../types/Activity/ActivityDto';
import DataRow from '../Common/DataRow/DataRow';
import { IconDisplayer } from '../Common/Help/IconDisplayer';
import i18n from '../../i18n';
import dayjs from 'dayjs';

type DetailLabel = {
    label: string;
    key: keyof ActivityDto;
};

type ActivityTableProps = {
    activities: ActivityDto[];
    onActionClick: (activity: ActivityDto) => void;
    detailsLabels: DetailLabel[];
};

const ActivityTable: React.FC<ActivityTableProps> = ({ activities, onActionClick, detailsLabels }) => {
    const [openActivityId, setOpenActivityId] = useState<string | null>(null);
    const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 768);

    const toggleDetails = (activityId: string) => {
        setOpenActivityId(openActivityId === activityId ? null : activityId);
    };

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="activity-table">
            <div className="activity-header">
                <div className="col-md-3 activity-header-item">{i18n.t('COMMON_LABEL_DATE')}</div>
                <div className="col-md-3 activity-header-item">{i18n.t('ACTIVITY_LABEL_DESCRIPTION')}</div>
                <div className="col-md-2 activity-header-item">{i18n.t('ACTIVITY_LABEL_TYPE')}</div>
                <div className="col-md-2 activity-header-item">{i18n.t('ACTIVITY_LABEL_URGENCY')}</div>
            </div>

            {activities.map((activity, index) => {
                const isExpanded = openActivityId === activity.entityKey;

                const dataToShow = [
                    { content: dayjs(activity.activeDate).format('DD/MM/YYYY') },
                    { content: activity.description },
                    { content: activity.activityTypeName },
                    { content: activity.urgencyTypeName },
                ];

                return (
                    <div key={index}>
                        <DataRow
                            data={dataToShow}
                            actionIcon={
                                <div
                                    onClick={() => toggleDetails(activity.entityKey)}
                                    style={{
                                        color: isExpanded ? '#00be95' : '#00be95',
                                        cursor: 'pointer',
                                        transition: 'color 0.3s ease',
                                        marginRight: '10px',
                                    }}
                                >
                                    <IconDisplayer
                                        type={isExpanded ? 'COMMON_LABEL_UP_ARROW' : 'COMMON_LABEL_ARROWFORWARD'}
                                    />
                                </div>
                            }
                            isExpanded={isExpanded}
                            onActionClick={() => onActionClick(activity)}
                        />
                        <div
                            className={`activity-details ${isExpanded ? 'expanded' : ''}`}
                        >
                            {detailsLabels.map((detail) => (
                                <div key={detail.key as string}>
                                    <p className="label">{detail.label}:</p>
                                    <p className="value">
                                        {activity[detail.key] !== undefined && activity[detail.key] !== null
                                            ? detail.key === 'activeDate' || detail.key === 'expiryDate'
                                                ? new Date(activity[detail.key] as Date).toLocaleString()
                                                : activity[detail.key]
                                            : 'N/A'}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ActivityTable;
