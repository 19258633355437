import { Card, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { LR } from "../Common/Help/LR";
import CustomerFeed from "../Customer/CustomerFeed";

type Args =
  {
    userLogged: ApplicationUser;
    open: boolean;
    handleConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
    handleCancel: () => void
  }

const DialogCustomerSelector = ({ userLogged, handleCancel, handleConfirm, open }: Args) => {
  const handleRowSelection = (params: any) => {
    handleConfirm(params)
  };

  const headers = [
    'Type',
    'Company',
    'First Name',
    'Last Name',
    'Relationship',
  ]

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <Card>
        <DialogTitle>
          <LR localResource="COMMON_LABEL_SELECTCONTACT"></LR>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <CustomerFeed
              userLogged={userLogged}
              compact={true}
              handleArrowClick={handleRowSelection}
              headers={headers}
            />
          </div>
        </DialogContent>
      </Card>
    </Dialog>
  );
};


export { DialogCustomerSelector };
